import fetch from 'dva/fetch';
import { notification } from 'antd';
// import { routerRedux } from 'dva/router';
import { codeMessage } from '../constants';
import config from '../config';

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
let invervalFunc = null;
let reviceUrl = null;
export default function request(url, options, isHiddenNote) {
  const defaultOptions = {
    credentials: 'same-origin'
    // credentials: 'same-origin'
    // omit: 默认值，忽略cookie的发送
    // same-origin: 表示cookie只能同域发送，不能跨域发送
    // include: cookie既可以同域发送，也可以跨域发送
  };
  const newOptions = { ...defaultOptions, ...options };
  newOptions.headers = newOptions.headers || {};
  // 当文件上传时设置 fetchType="file"
  if ((!newOptions.fetchType || newOptions.fetchType !== 'file') && (newOptions.method === 'POST' || newOptions.method === 'PUT' || newOptions.method === 'DELETE')) {
    newOptions.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
      ...newOptions.headers
    };
    newOptions.body = JSON.stringify(newOptions.body);
  }
  reviceUrl = newOptions;
  newOptions.headers['Accept-Language'] = localStorage.getItem('language');
  const ticket = localStorage.getItem('ticket');
  if (ticket) {
    newOptions.headers.Authorization = `Bearer ${ticket}`;
  }
  // 开发调试时使用
  // if (url.indexOf('object') > 0) {
  //   newOptions.headers.Authorization = 'Bearer 9d7ca2d0a0bc5e66468b754e41f45787';
  // }
  if (newOptions.method !== 'OPTIONS') {
    const result = {}; // 最终返回的结果
    return fetch(url, newOptions)
      .then((response) => {
        // 如果HTTP400以上的返回信息中有message，优先采用返回response的message，如果没有，则采用自定义的status状态信息
        if (response.status >= 400) {
          response.message = response.message || codeMessage[response.status] || response.statusText;
        }
        return response;
      })
      .then((response) => {
        const { status, message } = response;
        result.code = `${status}`;
        result.message = message;

        // delete的时候没有返回信息 code==204 为删除成功
        if ((newOptions.method === 'DELETE' || newOptions.method === 'POST') && status === 204) {
          return {
            code: `${status}`
          };
        }
        // status为404是不可以用response.json(), 统一处理404错误
        // if (status === 404) {
        //   return {
        //     code: `${status}`,
        //     message: '资源未找到'
        //   };
        // }
        // noNext不用处理下一个then
        if (status < 400) {
          result.noNext = true;
        } else {
          result.noNext = false;
        }

        return response.json();
      })
      .then((response) => {
        const res = Object.assign({}, result, response);
        if (result.noNext) {
          return res;
        }
        const { dispatch } = window.APPSTORE;
        if (res.code === '401') {
          if (window !== window.top) {
            window.parent.postMessage('timeout', '*');
          } else {
            dispatch({
              type: 'login/modelLogout'
            });
          }
          return res;
        }
        // 如果返回403，后台正在还原文件，不可继续操作
        if (res.code === '425') {
          const rUrl = `${config.domain2}/api/config/system/restore/status`;
          // 还原状态URL
          if (!invervalFunc) {
            dispatch({
              type: 'global/topLoadingShow',
              payload: {
                loading: true,
                tip: res.message || '系统维护中，请稍候...'
              }
            });
            invervalFunc = setInterval(() => {
              checkBackup(rUrl, newOptions);
            }, 5000);
          }
          checkBackup(rUrl, newOptions);
          return res;
        }
        // 演示版本暂时不出现右上角的错误提示框 zhangshunjin 2018-06-24 11:00  --- 出现 401.1, 401.2 登录时的码 wzd 2018-07-19
        if (Number(res.code) >= 400 && !isHiddenNote) {
          notification.error({
            message: res.code,
            description: res.message || response.error || '请稍候再试'
          });
        }

        return res;
      })
      .catch(() => {

      });
  }
}

window.addEventListener('message', (e) => {
  const { dispatch } = window.APPSTORE;
  if (e.data === 'backup') {
    const rUrl = `${config.domain2}/api/config/system/restore/status`;
    // 还原状态URL
    if (!invervalFunc) {
      dispatch({
        type: 'global/topLoadingShow',
        payload: {
          loading: true,
          tip: '系统维护中，请稍候...'
        }
      });
      invervalFunc = setInterval(() => {
        checkBackup(rUrl, reviceUrl);
      }, 5000);
    }
    checkBackup(rUrl, reviceUrl);
  } else if (e.data === 'timeout') {
    dispatch({
      type: 'login/modelLogout'
    });
  }
});

function checkBackup(url, newOptions) {
  const { dispatch } = window.APPSTORE;
  fetch(url, newOptions)
    .then(res => res.json())
    .then((data) => {
      if (data && [2, 5, 6].includes(data.status)) {
        notification.success({
          message: data.message
        });
        dispatch({
          type: 'global/topLoadingShow',
          payload: { loading: false }
        });
        clearInterval(invervalFunc);
        window.location.reload();
      }
    });
}
