import { filter, map } from 'lodash';
import { routerRedux } from 'dva/router';
import { message } from 'antd';
import commonMessage from 'root/common/messages';

import { getWidgetsGroup,
  getWidgetsByGroup,
  getSingleRuntimeSetting,
  saveRuntimeSetting,
  queryIndustryApplicationLayout,
  queryIndustryApplicationLayouts,
  updateIndustryApplicationLayout,
  uploadIcon } from '../services/api';
import { fetchTemplates } from '../services/workflowApi';
import { storedUrlToAppCreator } from '../utils/utils';

/*
  dva 中数据说明
  widgetsGroup  画布左边的抽屉栏
  editComponent  当前编辑的控件 配置api具体参见components/WidgetStorage 控件库的配置
  sourceOptions 数据源列表 （旧 后期会删）
  ctrls 画布内现存的控件
  layoutNodeConfig  当前区块的配置信息
    type 0-流式布局  1-堆式布局 2-自由布局
    lw 外部区块定义的宽度
    lh 外部区块定义的高度
    opacity 外部区块的背景透明度
  pageConfig 外部页面的配置信息
  workFlowConfig 流程图配置信息
*/
const initChartDrawingConfig = {
  drawingName: ''
};
export default {
  namespace: 'newIndustryWidgets',
  state: {
    isLoading: null,
    widgetsGroup: null,
    requestGroup: null,
    widgets: [],
    editComponent: null,
    editComponents: {},
    widget: 'Canvas',
    isCanvas: false,
    runtimeLayout: {},
    sourceOptions: [],
    ctrls: [],
    layoutNodeConfig: { type: '0' },
    chartDrawingConfig: Object.assign({}, initChartDrawingConfig),
    pageConfig: {},
    workFlowConfig: {},
    // workflow是否需要重新加载json数据
    isNeedLoad: false,
    iconPath: '', // 按钮自定义图标地址
    isChange: false,
    newTemplate: null,
    pageName: '' // 当前编辑页面
  },
  effects: {
    *getWidgetGroup({ callback, payload }, { call, put }) {
      const response = yield call(getWidgetsGroup, payload);
      yield put({
        type: 'gotWidgetGroup',
        widgetsGroup: response
      });
      if (callback) callback(response);
    },

    *getWidgetsByGroup({ groupName, intl }, { call, put }) {
      let response = [];
      if (groupName.groupName === 'temp') {
        response = yield call(fetchTemplates);
        if (response && response.list) {
          response = response.list;
        }
      } else {
        response = yield call(getWidgetsByGroup, groupName, intl);
      }
      yield put({
        type: 'gotWidgetsByGroup',
        requestGroup: groupName,
        widgets: response || []
      });
    },
    *fetchRuntimeLayout({ id }, { call, put }) {
      const res = yield call(getSingleRuntimeSetting, `runtime_${id}`);
      if (!(res && res.code === '200')) return false;
      yield put({
        type: 'queryRuntimeLayout',
        payload: res
      });
    },
    *saveRuntimeLayout({ payload }, { call }) {
      yield call(saveRuntimeSetting, payload);
    },
    *fetchLayout({ id }, { call, put }) {
      const res = yield call(queryIndustryApplicationLayout, id);
      if (!(res && res.parentId)) {
        message.error('数据请求失败');
        return false;
      }
      const parentRes = yield call(queryIndustryApplicationLayouts, res.parentId);
      if (!(parentRes && parentRes.layout)) {
        message.error('数据请求失败');
        return false;
      }
      yield put({
        type: 'fetchPageInfo',
        payload: parentRes
      });
      const { layoutNodes, pageConfig } = JSON.parse(parentRes.layout);
      const [layoutNodeConfig] = filter(layoutNodes, (item) => { return item.i === res.name; });
      yield put({
        type: 'queryLayoutFetch',
        payload: res,
        layoutNodeConfig,
        pageConfig
      });
    },
    *updateLayout({ data, isPreview = false, intl }, { call }) {
      const response = yield call(updateIndustryApplicationLayout, data);
      if (isPreview) return;
      if (response.location) {
        message.success(intl.formatMessage(commonMessage.saveSuccess));
        window.editor.displayView.dirty = false;
      } else {
        message.error(intl.formatMessage(commonMessage.saveFail));
        return false;
      }
    },
    *cancelUpdate({ payload }, { put }) {
      yield put({
        type: 'initialState'
      });
      storedUrlToAppCreator(payload);
      yield put(routerRedux.replace(payload));
    },
    *uploadIcon({ data }, { put, call }) {
      const response = yield call(uploadIcon, data);
      yield put({
        type: 'setUploadIcon',
        filePath: response && response.fileDownloadUrl
      });
    },
    *refleshModalTree({ payload }, { put }) {
      yield put({
        type: 'refleshTree',
        newTemplate: payload.newTemplate
      });
    }
  },
  reducers: {
    initDva(state) {
      return {
        ...state,
        isLoading: null,
        widgetsGroup: null,
        requestGroup: null,
        widgets: [],
        editComponent: null,
        editComponents: {},
        widget: 'Canvas',
        isCanvas: false,
        sourceOptions: [],
        ctrls: [],
        layoutNodeConfig: {},
        chartDrawingConfig: Object.assign({}, initChartDrawingConfig),
        pageConfig: {},
        workFlowConfig: {},
        isChange: false
      };
    },
    gotWidgetGroup(state, { widgetsGroup }) {
      return {
        ...state,
        widgetsGroup
      };
    },
    gotWidgetsByGroup(state, action) {
      return {
        ...state,
        requestGroup: action.requestGroup.groupName,
        widgets: action.widgets
      };
    },
    getEditComponent(state, action) {
      return {
        ...state,
        editComponent: action.component,
        widget: action.widget,
        isCanvas: action.isCanvas,
        isNeedLoad: false,
        editComponents: action.isCanvas ? (action.isMulti ? Object.assign({}, state.editComponents, action._idObj) : action._idObj) : {}
      };
    },
    initCtrlsAllConfig(state) {
      return {
        ...state,
        ctrlsAllConfig: {}
      };
    },
    queryRuntimeLayout(state, { payload }) {
      const runtimeLayout = payload.configInfo ? JSON.parse(payload.configInfo) : {};
      return {
        ...state,
        runtimeLayout
      };
    },
    queryLayoutFetch(state, { payload, layoutNodeConfig, pageConfig }) {
      if (layoutNodeConfig.type === '2' || layoutNodeConfig.type === '0') {
        const { context } = JSON.parse(payload.context);
        return {
          ...state,
          workFlowConfig: context || {},
          isNeedLoad: true,
          layoutNodeConfig,
          pageConfig
        };
      } else {
        let { ctrls } = JSON.parse(payload.context);
        const { ctrlsLayout, drawingConfig } = JSON.parse(payload.context);
        ctrls = ctrls || [];
        if (ctrlsLayout) {
          const { w, h } = ctrlsLayout;
          const { lw, lh } = layoutNodeConfig;
          ctrls = map(ctrls, (ctrlItem) => {
            ctrlItem.pos.x = (ctrlItem.pos.x * (lw / w)).toFixed(1) - 0;
            ctrlItem.pos.y = (ctrlItem.pos.y * (lh / h)).toFixed(1) - 0;
            ctrlItem.size.width = (ctrlItem.size.width * (lw / w)).toFixed(1) - 0;
            ctrlItem.size.height = (ctrlItem.size.height * (lh / h)).toFixed(1) - 0;
            return ctrlItem;
          });
        }
        return {
          ...state,
          ctrls,
          layoutNodeConfig,
          chartDrawingConfig: layoutNodeConfig.type === '0' ? Object.assign({}, drawingConfig) : state.drawingConfig,
          pageConfig,
          isChange: false
        };
      }
    },
    initialState(state) {
      return {
        ...state,
        workFlowConfig: {}
      };
    },
    updateCtrls(state, { ctrls }) {
      return {
        ...state,
        ctrls,
        isChange: true
      };
    },
    updateChartDrawing(state, { chartDrawingConfig }) {
      return {
        ...state,
        chartDrawingConfig,
        isChange: true
      };
    },
    fetchPageInfo(state, { payload }) {
      return {
        ...state,
        pageName: payload.name
      };
    },
    changeDrawingStatus(state, { payload }) {
      return {
        ...state,
        isChange: payload
      };
    },
    setUploadIcon(state, { filePath }) {
      return {
        ...state,
        iconPath: filePath
      };
    },
    refleshTree(state, { newTemplate }) {
      return {
        ...state,
        newTemplate
      };
    }
  }
};
