import React, { Component } from 'react';
import { Link, Redirect, Switch } from 'dva/router';
import DocumentTitle from 'react-document-title';
import logo from 'root/assets/img/icons/logo_index.svg';
import styles from './ErrorBoundary.less';


class ErrorBoundary extends Component {
  state = {
    hasError: false
  }

  componentWillReceiveProps() {
    this.setState({ hasError: false });
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <DocumentTitle title={window.ISAPPCREATOR ? localStorage.appTitle : 'supOS工业操作系统'} >
          <div className={styles.container}>
            <div className={styles.content}>
              <div className={styles.top}>
                <div className={styles.header}>
                  <Link to="/">
                    <img alt="logo" className={styles.logo} src={logo} />
                  </Link>
                </div>
              </div>
              <Switch>
                <Redirect exact from="/user" to="/user/login" />
              </Switch>
            </div>
          </div>
        </DocumentTitle >
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
