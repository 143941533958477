import request from '../utils/request';
import config from '../config';

// 查询待办列表
export async function query(params) {
  return request(`${config.domain11}/api/flow/task?page=${params.page}&size=${params.size}&done=${params.done}`);
}

// 获取待办详情
export async function queryDetail(params) {
  return request(`${config.domain11}/api/flow/task/${params.id}`);
}

// 临时保存待办
export async function tempSaveTask(params) {
  return request(`${config.domain11}/api/flow/task/${params.id}/data`, {
    method: 'POST',
    body: {
      data: params.data
    }
  });
}

// 删除临时保存的待办
export async function resetTask(params) {
  return request(`${config.domain11}/api/flow/task/${params.id}/data`, {
    method: 'DELETE'
  });
}

// 撤回
export async function recallTask(params) {
  return request(`${config.domain11}/api/flow/task/${params.id}/rollback`, {
    method: 'POST'
  });
}

// 完成待办
export async function doneTask(params) {
  return request(`${config.domain11}/api/flow/task/${params.id}`, {
    method: 'POST',
    body: {
      audit: params.audit,
      data: params.data,
      branch: params.branch
    }
  });
}

// 获取流转意见
export async function queryLog(params) {
  return request(`${config.domain11}/api/flow/task/${params.id}/log`);
}

// 设置委托
export async function setDelegation(params) {
  return request(`${config.domain11}/api/flow/task/delegation/delegate`, {
    method: 'POST',
    body: params
  });
}

// 查询委托列表
export async function queryDelegation(params) {
  return request(`${config.domain11}/api/flow/task/delegation/query?page=${params.page}&size=${params.size}&status=${params.done}`);
}

// 取消委托
export async function cancelDelegation(params) {
  return request(`${config.domain11}/api/flow/task/delegation/cancel`, {
    method: 'POST',
    body: {
      id: params.id,
      userFrom: params.userFrom
    }
  });
}
