import { queryEleLabelList } from '../services/api';

export default {
  namespace: 'elementLabels',
  state: {
    labeldata: { list: [] }
  },
  effects: {
    // 获取标签
    *getLabel({ label, callback }, { call, put }) {
      const response = yield call(queryEleLabelList, label);
      yield put({
        type: 'save',
        label: response
      });
      if (callback) callback(response);
    }
  },

  reducers: {
    save(state, action) {
      return {
        ...state,
        labeldata: action.label
      };
    }
  }
};
