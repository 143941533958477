import { getScriptByObjectSelector } from '../services/commonApi';

export default {
  namespace: 'commonModel',

  state: {
    data: {}
  },

  effects: {
    // 生成脚本-对象选择器-脚本编辑器
    *getScriptByObjectSelector({ payload, callback }, { call, put }) {
      const response = yield call(getScriptByObjectSelector, payload);
      yield put({
        type: 'setScriptByObjectSelector',
        payload: response
      });
      if (callback) callback(response);
    }
  },

  reducers: {
    setScriptByObjectSelector(state, action) {
      return {
        ...state,
        data: action.payload
      };
    }
  }
};
