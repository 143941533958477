import request from 'root/utils/request';
import moment from 'moment';
import { message } from 'antd';
import _ from 'lodash';
import { saveAs } from 'file-saver/FileSaver';
import { isArray, isObject } from 'util';
import { isZhizhiBrowser } from './utils';
import sysConfig from '../config';
import * as htmlUtil from './htmlUtil';

const uuidv1 = require('uuid/v1');

// const myEvent = {
//   onLoad: true,
//   onGetWorkFlowData: true
// };
function isFunction(func) {
  return func && typeof func === 'function';
}

function isEmptyObject(obj) {
  return Object.keys(obj).length === 0;
}

/**
 * 获取 url 参数
 */
function getRequestUrl() {
  const { href } = window.location;
  const url = href.split('?')[1]; // 获取url中"?"符后的字串
  const theRequest = {};
  if (url && url.length > 0) {
    url.split('&').forEach((str) => {
      theRequest[str.split('=')[0]] = unescape(str.split('=')[1]);
    });
  }
  return theRequest;
}

/**
 * 拆分列表
 * @param {*} splitKey
 * @param {*} receiveKey
 * @param {*} list
 */
function splitList(splitKey, receiveKey, list = [], remainRes = {}) {
  const arr = [];

  list.forEach((item) => {
    item = { ...item, ...remainRes };
    if (item[splitKey] === 0 || item[splitKey] === '0') {
      item[receiveKey] = 0;
      arr.push(item);
    } else if (item[splitKey] && item[splitKey] <= 1) {
      item[receiveKey] = 1;
      arr.push(item);
    } else {
      for (let i = 0, l = item[splitKey]; i < l; i += 1) {
        item[receiveKey] = 1;
        arr.push(item);
      }
    }
  });
  return arr;
}

// const uuid = null;
/**
 * 注册reacetDom到window.dynamicImportWidget;
 * @param {*} props
 * @param {*} component
 * @param {*} itemskey 流式布局-控件ID
 * @param {*} widgetIndex 自由布局-控件ID
 */
function registerReactDom(component, props) {
  // 将运行时的画布，挂载到window，用于根据子组件id，fiber node 获取组件实例
  const { itemskey, widgetIndex } = props;
  if (!itemskey && !widgetIndex) {
    return false;
  }
  window.dynamicImportWidget = window.dynamicImportWidget || {};
  window.dynamicImportWidget[itemskey || widgetIndex] = component;
  // return window.dynamicImportWidget;
}

function logoutReactDom(props) {
  // 将运行时的画布，挂载到window，用于根据子组件id，fiber node 获取组件实例
  const { itemskey, widgetIndex } = props;
  if (!(window.dynamicImportWidget && window.dynamicImportWidget[itemskey || widgetIndex])) return false;
  delete window.dynamicImportWidget[itemskey || widgetIndex];
}

/**
 * 获取window.dynamicImportWidget;
 * @param {*} dynamicImportWidget
 * @param {*} componentId
 */
function getRegisterReactDom(componentId) {
  return window.dynamicImportWidget[componentId];
}
// 根据组件id, 获取reactDom组件值
function getReactDomValue(ctrlId) {
  const editComponent = getRegisterReactDom(ctrlId);
  if (editComponent && isFunction(editComponent.getValue)) {
    const o = {};
    const key = editComponent.getFormItemId();
    const value = editComponent.getValue();
    o[key] = value;
    return o;
  }
}

/** 各种控件的 setData 方法 by fanzheng */

// 通用方法
function setReactDomValue(ctrlId, value) {
  const editComponent = getRegisterReactDom(ctrlId);
  if (editComponent && isFunction(editComponent.setValue)) {
    return editComponent.setValue(value);
  }
}

function setReactDomValues(ctrlIds) {
  const values = [];
  if (ctrlIds && ctrlIds.length) {
    ctrlIds.forEach((ctrlId) => {
      values.push(setReactDomValue(ctrlId));
    });
  }
  return values;
}
/**
 * 组件 setDate 各种操作
 * @param {string} ctrlId 组件ID
 * @param {*} value 传入的值 针对实际操作传入对应的值 后续可能加入根据 操作类型（函数名？） 控制传入值的类型控制 同时控制调用范围
 * @param {string} funcName 具体操作的函数名
 */
function handleReactDom(ctrlId, value, funcName = 'setValue') {
  const editComponent = getRegisterReactDom(ctrlId);
  if (editComponent && isFunction(editComponent[funcName])) {
    return editComponent[funcName](value);
  }
}
/**
 * 信息提示
 * @param {success/error/warning} type 错误类型
 * @param {*} msg 提示信息
 */
function showMessage(msg = '操作成功', type = 'success') {
  const method = message[type];
  if (method && isFunction(method)) {
    method(msg);
  }
}
/**
 *  脚本 ES6 assign 补丁
 * @param {obj} target
 * @param  {...obj} sources
 */
function assignObj(target = {}, ...sources) {
  return Object.assign(target, ...sources);
}

/**
 * 使用循环的方式判断一个元素是否存在于一个数组中
 * @param {Object} arr 数组
 * @param {Object} value 元素值
 */
function isInArray(arr, value) {
  let inArray = false;
  if (isArray(arr)) {
    arr.forEach((v) => {
      if (value === v) {
        inArray = true;
      }
    });
  }
  return inArray;
}
// 拼接事件参数
function getActionHandle(actions = [], instances) {
  const parent = instances.props.data._parent;
  let eventProp = {};
  if (isArray(actions)) {
    // console.log('actions:', actions, ',instances:', instances);
    actions.forEach((item) => {
      const { action } = item;
      let { script } = item;
      eventProp[action] = (...args) => {
        // 🌲树状选择的事件
        if ((action === 'onSelect') && instances && instances.onSelectTreeNode && instances.props.widgetName === 'TreeCtrl') {
          instances.onSelectTreeNode(args[0], args[1]);
        }
        if ((action === 'onChange') && instances && instances.handleChange) {
          instances.handleChange(args[0], args[1]);
        }
        if ((action === 'onClick') && instances && instances.handleClick) {
          instances.handleClick(args[0], args[1]);
        }
        if (['onGetWorkFlowData', 'onSubmitWorkFlowData', 'onRejectWorkFlowData'].includes(action)) {
          return false;
        }
        // script = script.replace(/\n/g, '');
        if (parent && parent.getAttr('customFun')) {
          script = replaceFun(script, parent);
        }
        setTimeout(() => {
          try {
            if (script.includes('.API.')) {
              script = transforAPI(script, instances);
            }
            new Function(script)();
          } catch (error) {
            console.log(error);
          }
        }, 0);
        return false;
      };
      if (!action) {
        eventProp = {};
      }
    });
  }
  return eventProp;
}

function replaceFun(script, data) {
  const source = data.getAttr('customFunSource') || {};
  let newScript = script;
  data.getAttr('customFun').forEach((e) => {
    if (source[e.id] && source[e.id].funcName === e.funcName) {
      newScript = newScript.replace(`custom.${source[e.id].funcName}()`, source[e.id].script);
    }
  });
  return newScript;
}

// api函数转换
function transforAPI(script, instances) {
  const model = instances.props.data.getDataModel();
  Object.keys(model._dataMap).forEach((e) => {
    const item = model._dataMap[e];
    // eslint-disable-next-line
    if (item.__proto__ === window.ht.Block.prototype && script.includes(`${item._displayName}.API`)) {
      const customAPI = item.getAttr('customAPI');
      if (customAPI.length > 0) {
        customAPI.forEach((it) => {
          const fun = `${item._displayName}.API.${it.funcName}`;
          if (script.includes(fun)) {
            script = script.replace(fun, 'API');
            let scriptStr = '';
            let params = '';
            if (script.match(/API\(([^)]+)\)/g)) {
              scriptStr = script.match(/API\(([^)]+)\)/g);
              params = scriptStr[0].replace(/API\((.+)\)/g, '$1');
            } else {
              scriptStr = script.match(/API\(\)/g);
            }
            script = script.replace(scriptStr, `${it.script};${it.funcName}(${params})`);
          }
        });
      }
    }
  });
  return script;
}
// 获取时区
function getTimeZone() {
  return new Date().getTimezoneOffset() / 60;
}

/**
 * 批量校验 获取最后校验结果
 * @param {Array} ctrlIds 组件ID数组
 */
function isVaild(ctrlIds = []) {
  const validResArr = ctrlIds.map((item) => {
    return getRegisterReactDom(item).getValid();
  });
  let flag = true;
  validResArr.forEach((item) => {
    if (!item) {
      flag = false;
    }
  });
  return flag;
}
/**
 * 注意：
 * 1.下拉框设置下拉栏数据   setImportData(arr = [{ optionValue: value, optionText: 'text' }])
 * 2. 图表库(柱状图) 的数据设置 setValue( object = {
    //   objectSource: {
    //     'xxx.yyy': {
    //       list: [{
    //         attdanceday: 22,
    //         name: 'CCC'
    //       }]
    //     },
    //     dataSource: [{
    //       selectedInstance: {
    //         instanceName: 'xxx'
    //       },
    //       selectedProp: {
    //         propertyDesc: 'yyy'
    //       },
    //       color: '#3BB45E',
    //       chooseX: 'name',
    //       chooseY: 'attdanceday'
    //     }]
    //   }
    // })
 * 3.datePicker 传入的数据必须是格式化后的时间格式 可使用 timestampFormat 处理
 */

/** end */

// 根据组件id, 获取reactDom组件值
function getReactDomValues(ctrlIds) {
  const values = {};
  if (ctrlIds && ctrlIds.length) {
    [...ctrlIds].forEach((ctrlId) => {
      const value = getReactDomValue(ctrlId);
      if (value && value !== 'undefined') {
        const key = Object.keys(value);
        key.forEach((k) => {
          values[k] = value[k];
        });
      }
    });
  }
  return values;
}
// formIds控件名称
// PreviewWrapper.js
function getFormData(formIds) {
  const wrapComponent = getRegisterReactDom('previewWrapper');
  if (formIds && wrapComponent) {
    return wrapComponent.getFormData(formIds);
  }
}

function setFormData(newFormData) {
  const wrapComponent = getRegisterReactDom('previewWrapper');
  if (wrapComponent) {
    wrapComponent.updateForm(newFormData, true);
  }
}

/**
 * 获取具体参数值
 * @param {object} params 来源数据
 * @param {string} formId 控件名称
 * @param {string} prop 需获取的参数
 */
function getProp(params, formId, prop) {
  prop = prop || 'url';
  const param = params[formId] || '';
  const data = [];
  if (Array.isArray(param)) {
    param.forEach((item) => {
      data.push(item[prop]);
    });
  } else {
    data.push(param[prop]);
  }

  params[formId] = data;
  return params;
}

// 关闭当前页面
function closeCurrentPage() {
  const { userAgent } = navigator;
  if (userAgent.indexOf('Firefox') !== -1 || userAgent.indexOf('Chrome') !== -1) {
    window.location.href = 'about:blank';
    window.close();
  } else {
    window.opener = null;
    window.open('', '_self');
    window.close();
  }
}
// 打开页面
function openPage(url, method = '_blank', feature = null, openConfig) {
  const baseFeature = 'toolbar=yes, location=yes, directories=no, status=no, menubar=yes, scrollbars=yes, resizable=yes, copyhistory=yes';
  const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
  if (feature) {
    if (openConfig) {
      const obj = JSON.parse(openConfig);
      feature = `${baseFeature},
      top=${obj.isCenter ? (document.documentElement.clientHeight - (obj.height || 400)) / 2 : obj.offsetTop || 0},
      left=${obj.isCenter ? (document.documentElement.clientWidth - (obj.width || 400)) / 2 : obj.offsetLeft || 0},
      width=${obj.width || 400},
      height=${obj.height || 400}`;
    } else {
      feature = `${baseFeature}, width=400, height=400`;
    }
  }

  // url = url && (url.indexOf('http://') > -1 || url.indexOf('https://') > -1) ?
  //   url : (window.location.origin + url);

  if (method === '_blank' && isIE11) {
    feature = `channelmode=yes, ${baseFeature}`;
  }

  window.open(url, method, feature);
}
// 在父窗口打开页面
function openParentPage(url, method) {
  if (window.opener) {
    window.opener.open(url, method);
  }
}
// 根据组件formItemId,labelContent，生成表头映射
function getFormatterMap(ctrlIds = []) {
  const map = {};
  if (ctrlIds && ctrlIds.length) {
    ctrlIds.forEach((ctrlId) => {
      const component = getRegisterReactDom(ctrlId);
      const { formItemId, labelContent } = component.currentConfig;
      map[formItemId] = labelContent;
    });
  }
  return map;
}
// 解析表格数据
function parseTableMap(data, map, index = '序号') {
  let arr = {};
  if (data && data.length && map) {
    const { hasOwnProperty } = Object.prototype;
    arr = data.map((item, i) => {
      const o = {};
      for (const key in item) {
        if (item && hasOwnProperty.call(item, key)) {
          o[index] = i + 1;
          if (map[key]) {
            o[map[key]] = item[key];
          } else {
            o[key] = item[key];
          }
        }
      }
      return o;
    });
  } else {
    return data;
  }
  return arr;
}
/**
 * 自动计数器
 * 用于画布中计算子组件是否全部加载
 */
// 自动计数器
const Counter = {
  count: 0,
  sum() {
    this.count += 1;
  },
  clear() {
    this.count = 0;
  },
  getCount() {
    return this.count;
  }
};

// 字段排序
function objKeySort(arys, sort) {
  // 先用Object内置类的keys方法获取要排序对象的属性名，再利用Array原型上的sort方法对获取的属性名进行排序，newkey是一个数组
  const newkey = sort || Object.keys(arys).sort();
  // 创建一个新的对象，用于存放排好序的键值对
  const newObj = {};
  for (let i = 0; i < newkey.length; i += 1) {
    // 遍历newkey数组
    newObj[newkey[i]] = arys[newkey[i]];
    // 向新创建的对象中按照排好的顺序依次增加键值对
  }
  // 返回排好序的新对象
  return newObj;
}
// 获取指定字段，并按指定字段顺序排序
function arrObjectKeySort(aryObjs, sort) {
  if (aryObjs && aryObjs.length && sort && sort.length) {
    const newAryObjs = aryObjs.map((item) => {
      return objKeySort(item, sort);
    });
    return newAryObjs;
  }
  return aryObjs;
}

// getFormatterByDataStructName 根据datastruct描述字段，进行表格字段映射
function getFormatterByDataStructName(name, cb) {
  const url = `/api/metadata/datastructs/${name}/fields`;
  const map = {};
  const param = {
    method: 'GET'
  };
  request(url, param).then((res) => {
    if (res && res.list) {
      const { list } = res;
      list.map((item) => {
        map[item.name] = item.description;
        return map;
      });
      cb(map);
    }
  });
}
// 获取localstorage中的uuid
function getUuid() {
  return window.localStorage.getItem('uuid');
}
// 设置localstorage中的uuid
function setUuid() {
  if (!window.localStorage.getItem('uuid')) {
    window.localStorage.setItem('uuid', uuidv1());
  }
}
// 清空localstorage中的uuid
function emptyUuid() {
  window.localStorage.removeItem('uuid');
}
// WorkprocessLayout.js工作流提交前，数据处理
// 解析onSubmitWorkFlowData
// task.js下个工作流启动时，数据处理
// 脚本可以通过拼接参数字符窜或者arguments获取
function parseWorkFlowConfig(pageData) {
  const { layoutsAllConfig = [], action, readOnly, isDone } = pageData;
  let { formData } = pageData;
  let configArr = [];
  let newFormData = null;
  if (!layoutsAllConfig) {
    return formData;
  }

  // 画布工作流获取和提交事件
  if (['onGetWorkFlowData', 'onSubmitWorkFlowData', 'onRejectWorkFlowData'].includes(action)) {
    layoutsAllConfig.forEach((item) => {
      const { context } = item;
      const config = typeof context === 'string' ? JSON.parse(context) : context;
      const canvasActions = _.get(config, 'jsonData.a.actions', []);
      configArr = _.get(config, 'jsonData.d', []);
      configArr.push({ a: { configs: { config: { actions: canvasActions } } } });
    });
  }

  if (configArr && isArray(configArr) && configArr.length) {
    configArr.forEach((v) => {
      const conf = _.get(v, 'a.configs.config', {});
      const { actions = [] } = conf;
      if (isArray(actions)) {
        actions.forEach((itemAction) => {
          const { formItemId, action: componentAction, script: componentScript } = itemAction;
          if (componentScript
            && componentAction === action
            && (['onGetWorkFlowData', 'onSubmitWorkFlowData', 'onRejectWorkFlowData'].includes(componentAction))) {
            if (componentAction === 'onGetWorkFlowData') {
              formData = Object.assign({}, formData, { readOnly, isDone });
            }
            newFormData = new Function(componentScript)(formData, formItemId);
            // return newFormData;
          }
        });
      }
    });
  }

  return newFormData || formData;
}

function excuteScript(action, script, cb, formData, currentConfig) {
  if (['onGetWorkFlowData', 'onSubmitWorkFlowData', 'onRejectWorkFlowData'].includes(action)) {
    return parseWorkFlowConfig(currentConfig);
  }
  if ((action === 'onLoad') && script) {
    new Function(script)();
  }
  if ((action === 'onInit') && script) {
    new Function(script)();
  }
  if ((action === 'onbeforeLoad') && script) {
    window.onbeforeunload = function (e) {
      return new Function(script)(e);
    };
  }
  if ((action === 'onunload') && script) {
    window.onunload = function (e) {
      return new Function(script)(e);
    };
  }
  if ((action === 'onUploadComplete') && script) {
    new Function(script)();
  }
  if (isFunction(cb)) {
    cb();
  }
  return formData;
}

// setAction等在ChartDrawing.js
// 画布事件onLoad-layoutItem.js
// 多交互的时候
function triggerEvent(currentConfig = {}, cb) {
  const { actions = [], formData } = currentConfig;
  const workflowAction = currentConfig.action;
  if (isArray(actions) && actions.length) {
    actions.forEach((config) => {
      const { action, script } = config;
      return excuteScript(action, script, cb, formData, currentConfig);
    });
  }
  if (['onGetWorkFlowData', 'onSubmitWorkFlowData', 'onRejectWorkFlowData', 'onUploadComplete'].includes(workflowAction)) {
    return excuteScript(workflowAction, null, cb, formData, currentConfig);
  }
}

/*
// 单个交互的时候
function triggerEvent(currentConfig = {}, cb) {
  const { action, script, formData } = currentConfig;
  return excuteScript(action, script, cb, formData, currentConfig);
}
*/
/**
 * 时间戳反格式化处理 返回需要的时间戳
 * @param {string} timestamp 已格式化的时间戳
 * @param {number} type 1:默认方式 转毫秒数 其他待扩展
 */
function timestampAntiFormat(timestamp, type = 1) {
  const isTimestampVaild = timestamp;
  if (isTimestampVaild) {
    switch (type) {
      case 1:
        return new Date(timestamp.replace(/-/g, '/')).valueOf();
      // return moment(timestamp, format);
      default:
        return new Date(timestamp.replace(/-/g, '/')).valueOf();
    }
  } else {
    return null;
  }
}

/**
 * 时间戳格式化处理 返回需要的时间戳格式
 * @param {number} time 时间戳
 * @param {string} format 时间戳格式 参照 moment.js 灵活配置
 *
 */
function timestampFormat(time, format = 'YYYY-MM-DD HH:mm:ss') {
  const isTimeVaild = time;
  if (isTimeVaild) {
    // 如果传入time是字符串格式的时间戳，则需转成数字型的时间戳
    let newTime = time;
    if (typeof time === 'string' && time.indexOf('-') === -1) {
      newTime = time - 0;
    }
    return moment(newTime).format(format);
  } else {
    return null;
  }
}
/**
 * value 校验 是否必填 是否符合格式化需求
 * @param {string} value 校验值
 * @param {string} type 格式类型 或者 正则规则
 * @param {string} isRequired 是否必填
 */
function valueCheck(value, type = 'none', isRequired = 'yes') {
  if (value) {
    let reg = null;
    switch (type) {
      case 'none':
        reg = /\S/;
        break;
      case 'mobilePhone':
        reg = /^1(3|4|5|7|8|9)\d{9}$/;
        break;
      case 'telephone':
        reg = /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$/;
        break;
      case 'zipCode':
        reg = /^[1-9][0-9]{5}$/;
        break;
      case 'idCard':
        reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
        break;
      case 'number':
        reg = /^[+-]?\d+(\.\d+)?$/;
        break;
      case 'email':
        reg = /^([a-zA-Z0-9._-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
        break;
      case 'ip':
        reg = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;
        break;
      default:
        reg = new RegExp(type);
    }
    return reg.test(String(value));
  } else {
    return isRequired !== 'yes'; // 未输入有效值时 如果必填则false 反之true
  }
}

// 整合表单操作流程
/**
 * 对象建模持久化数据：dataTable对象/dataTabel类型对象属性
 * @param {*} tableId 表格控件id 必填
 * @param {*} ctrlIds 控件ids 非必填 格式化字段 ['InputCtrl-0', 'SelectCtrl-6'];
 * @param {*} dataStructName 表格数据集合 'MaintenanceObject.record'
 * @param {*} type 请求类型，'property.getData'
 * @param {*} dataSource dataTable对象实例, 'MaintenanceObject.record'
 * @param {*} filters 查询条件  {
      minDate: new Date('2018-07-02'), // 非dataTable必填
    }
 * @param {*} columns 显示的表格字段, ['制单日期', '维修单位', '制单人', '责任人', '维修单号', '创建时间', '开工日期', '完工日期', '停机工时', '总工时']
 */
function queryTableByObjectModel(reqParam = {}) {
  const { tableId, ctrlIds = [], dataStructName, type, dataSource, filters = {}, method = 'POST', columns = [] } = reqParam;
  const Table = getRegisterReactDom(tableId);
  const d = {
    type,
    dataSource,
    filters
  };
  const api = '/api/compose/manage/objectdata/query';
  const param = {
    method,
    body: d
  };
  // 提交数据
  const renderTable1 = function () {
    request(api, param).then((res) => {
      if (res && res.list) {
        const fieldsMap = getFormatterMap(ctrlIds);
        const list = parseTableMap(res.list, fieldsMap);
        Table.setObjectSource({ list: arrObjectKeySort(list, columns) });
      }
    });
  };

  getFormatterByDataStructName(dataStructName, renderTable1);
}

function showModal(config = {}) {
  if (Object.prototype.toString.call(config) === '[object Object]') {
    const { ctrlId = 'previewWrapper', pageId, modalVisible = true, modalTitle = '编辑', modalWidth = 1024, modalHeight = 768 } = config;
    // const self = this || window;
    // if (self.getRegisterReactDom && isFunction(self.getRegisterReactDom)) {
    const previewWrapperComponent = getRegisterReactDom(ctrlId);
    if (modalVisible) {
      previewWrapperComponent.setModal({
        modalVisible,
        modalWidth,
        modalHeight,
        modalTitle,
        isIframe: true,
        modalContent: pageId && `/#/runtime-fullscreen/runtime-fullscreen/${pageId}`
      });
      // }
    } else {
      previewWrapperComponent.modalClose();
    }
  } else {
    const previewWrapperComponent = getRegisterReactDom('previewWrapper');
    const modelSetting = JSON.parse(config);
    const clientWidth = (document.documentElement.clientWidth - 10);
    if (modelSetting.width > clientWidth) {
      modelSetting.width = clientWidth;
    }
    // const containerElement = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement;
    previewWrapperComponent.setModal({
      modalVisible: true,
      modalWidth: Number(modelSetting.width) || 1024,
      modalHeight: Number(modelSetting.height) || 768,
      modalTitle: modelSetting.modelTitle,
      modalContent: modelSetting.url,
      bodyStyle: modelSetting.padding,
      isIframe: true
    });
  }
}
// 设置
function showModalContent(config = {}) {
  const previewWrapperComponent = getRegisterReactDom('previewWrapper');
  const { width, height, title, content, visible, isIframe = true } = config;
  if (previewWrapperComponent) {
    previewWrapperComponent.setModal({
      modalVisible: visible,
      modalWidth: Number(width) || 1024,
      modalHeight: Number(height) || 768,
      modalTitle: title,
      modalContent: content,
      isIframe
    });
  }
}

function closeModal(ctrlId = 'previewWrapper') {
  // 目前脚本控制的弹框都是在 previewWrapper 层
  getRegisterReactDom(ctrlId).modalClose();
}

function showLoading(config = {}) {
  const { ctrlId = 'previewWrapper', spinIsLoading = true, spinTip } = config;
  // const self = this || window;
  // if (self.getRegisterReactDom && isFunction(self.getRegisterReactDom)) {
  const previewWrapperComponent = getRegisterReactDom(ctrlId);
  previewWrapperComponent.setSpinLoading({
    spinIsLoading,
    spinTip
  });
}
function closeLoading(ctrlId = 'previewWrapper') {
  // 目前脚本控制的弹框都是在 previewWrapper 层
  getRegisterReactDom(ctrlId).closeSpinLoading();
}


// 对象实例
function instanceApi(reqParam, cb) {
  const { name, systemProperty = false, visible = true, api = `${sysConfig.domainObject}/objectdata`, filters, rangeFilter,
    type = 'template.addInstance', dataSource, description = '', keys, properties = [], method = 'POST' } = reqParam;
  let d = {
    type,
    dataSource,
    filters
  };

  if (type === 'template.getInstances.getPropertyValues' && rangeFilter) {
    d.rangeFilter = rangeFilter;
  }

  if (type === 'dataTable.addData' || type === 'dataTable.deleteData') {
    d = Object.assign({}, d, { data: properties });
  } else if (type === 'dataTable.updateData') {
    d = Object.assign({}, d, { keys, data: properties[0] });
  } else if (type !== 'template.getInstances.getPropertyValues' && type !== 'dataTable.getData') {
    d = Object.assign({}, d, {
      data: {
        name,
        description,
        systemProperty,
        visible,
        properties
      }
    });
  }
  const param = {
    method,
    body: d
  };
  request(api, param).then((res) => {
    if (res && isFunction(cb)) {
      cb(res);
    }
  });
}

function renderTable(config) {
  const { tableId, list } = config;
  const Table = getRegisterReactDom(tableId);
  Table.setObjectSource({ list });
}

// 对象实例新增-生成primitiveType
function parsePrimitiveType(d, primitiveTypeMap) {
  const results = [];
  if (isObject(primitiveTypeMap) && isObject(d)) {
    const props = Object.keys(d);
    props.forEach((v) => {
      if (d[v] && primitiveTypeMap[v]) {
        const result = {};
        result.name = v;
        result.defaultValue = d[v];
        result.primitiveType = primitiveTypeMap[v];
        results.push(result);
      }
    });
  }
  return results;
}
// parseObjPropVals
function parseObjPropVals(res) {
  if (res && res.message && res.message.list) {
    const { list } = res.message;
    const props = [];
    if (isArray(list)) {
      list.forEach((item) => {
        if (item && item.properties) {
          props.push(Object.assign({}, item.properties, { name: item.name }));
        }
      });
    }
    return props;
  }
}

// 合并数据
function groupDataByField(arr, groupBy, countBy, otherData) {
  if (!isArray(arr)) {
    return [];
  }
  // 排序
  arr.sort((a, b) => {
    return a[groupBy] - b[groupBy];
  });
  // 分组
  const map = {};
  const dest = [];
  arr.forEach((item) => {
    if (!map[item[groupBy]]) {
      const newItem = Object.assign({}, item, {
        data: [item]
      });
      dest.push(newItem);
      map[item[groupBy]] = item;
    } else {
      dest.forEach((v) => {
        if (v[groupBy] === item[groupBy]) {
          v.data.push(item);
          return false;
        }
      });
    }
  });
  if (countBy) {
    const newArr = [];
    dest.forEach((m) => {
      const d = m.data;
      let count = 0;
      d.forEach((item) => {
        count += item[countBy] ? Number(item[countBy]) : 0;
      });
      m[countBy] = count;
      delete m.data;
      if (otherData) {
        Object.assign({}, m, otherData);
      }
      newArr.push(m);
    });
    return newArr;
  }
  return dest;
}

// 填充数据
function mergeData(arr, newData, parseItemFn) {
  if (isArray(arr) && isObject(newData)) {
    return arr.map((item) => {
      let parseItem = {};
      if (isFunction(parseItemFn)) {
        parseItem = parseItemFn(item);
      }
      return Object.assign({}, item, newData, parseItem);
    });
  }
}
// queryInstanceByTemplate
function queryInstanceByTemplate(reqParam, cb) {
  const defultPages = {
    pageNo: 1,
    pageSize: 20
  };
  const { filters: filterParam } = reqParam;
  const filters = Object.assign({}, defultPages, filterParam);
  const defaultValue = {
    api: `${sysConfig.domainObject}/objectdata/query`,
    // api: 'http://10.30.52.63:8080/api/compose/manage/objectdata/query',
    type: 'template.getInstances.getPropertyValues'
  };
  instanceApi(Object.assign({}, filters, defaultValue, reqParam), cb);
}
// addInstanceByTemplate
function addInstanceByTemplate(reqParam, cb) {
  instanceApi(reqParam, cb);
}

// updateInstance
function updateInstance(reqParam, cb) {
  const defaultValue = {
    type: 'instance.update'
  };
  instanceApi(Object.assign({}, defaultValue, reqParam), cb);
}

// delInstance
function delInstance(reqParam, cb) {
  const defaultValue = {
    type: 'instance.delete'
  };
  instanceApi(Object.assign({}, defaultValue, reqParam), cb);
}

// getEditRow
function getEditRow(tableId) {
  const tableConponent = getRegisterReactDom(tableId);
  return tableConponent && tableConponent.getCurRow ? tableConponent.getCurRow() : {};
}

//
function getSelectText(selectId) {
  const tableConponent = getRegisterReactDom(selectId);
  const option = tableConponent && tableConponent.getChooseOption ? tableConponent.getChooseOption() : {};
  return (option && option.props && option.props.children) || '';
}
// dataTable-CURD操作
// queryDataTable
function queryDataTable(reqParam, cb) {
  const defaultValue = {
    api: `${sysConfig.domainObject}/objectdata/query`,
    type: 'dataTable.getData'
  };
  instanceApi(Object.assign({}, defaultValue, reqParam), cb);
}
// addDataTable
function addDataTable(reqParam, cb) {
  const defaultValue = {
    type: 'dataTable.addData'
  };
  instanceApi(Object.assign({}, defaultValue, reqParam), cb);
}
// updateDataTable
function updateDataTable(reqParam, cb) {
  const defaultValue = {
    type: 'dataTable.updateData'
  };
  instanceApi(Object.assign({}, defaultValue, reqParam), cb);
}
// delDataTable
function delDataTable(reqParam, cb) {
  const defaultValue = {
    type: 'dataTable.deleteData'
  };
  instanceApi(Object.assign({}, defaultValue, reqParam), cb);
}

/**
 * 调用脚本服务
 * objName: 对象名称
 * serviceName: 服务名称
 * params：服务参数
 */
function excuteScriptService(reqParam = {}, cb1) {
  executeScriptService(reqParam, cb1);
}

function executeScriptService(reqParam = {}, cb1) {
  const { objName, serviceName, params, cb, ...rest } = reqParam;
  request(`${sysConfig.domain2}/api/runtime/objects/${objName}/services/${serviceName}`, {
    ...rest,
    method: 'POST',
    body: {
      ...params
    }
  }).then((res) => {
    if (res && isFunction(cb1)) {
      cb1(res);
    }
    if (res && isFunction(cb)) {
      cb(res);
    }
  });
}
/**
 * 获取用户信息
 */
function getSessionUserInfo() {
  let userSessionInfo = sessionStorage.getItem('userInfo');
  if (userSessionInfo && userSessionInfo !== 'undefined' && typeof userSessionInfo === 'string') {
    userSessionInfo = _.get(JSON.parse(userSessionInfo), 'userSessionInfo', null);
  } else {
    userSessionInfo = null;
  }
  return userSessionInfo;
}
/**
 * 获取用户信息
 */
function getUserInfo(cb) {
  const userSessionInfo = getSessionUserInfo();
  if (userSessionInfo && typeof userSessionInfo === 'object') {
    const { username } = userSessionInfo;
    request(`${sysConfig.domain2}/api/auth/users/${username}`).then((res) => {
      if (res && isFunction(cb)) {
        cb(res);
      }
    });
  }
}
/**
 * 设置用户信息
 */
function setUserInfo(ctrlId, tip = '欢迎 ', endTip = ',退出') {
  const userSessionInfo = getSessionUserInfo();
  if (userSessionInfo && typeof userSessionInfo === 'object') {
    const userName = userSessionInfo.username || userSessionInfo.realName || '';
    if (ctrlId && userName) {
      const loginOutComponent = getRegisterReactDom(ctrlId);
      loginOutComponent.setButtonName(`${tip}${userName}${endTip}`);
    }
  }
}
/**
 * 退出登录
 */
function setAuthority(authority = {
  status: false,
  currentAuthority: 'guest'
}) {
  window.location.href = '#/user/login';
  return localStorage.setItem('antd-pro-authority', authority);
}

/**
 * 获取字典
 * tableObjNameStr:字典表名
 */

function getDictionary(reqParam = {}, cb) {
  const defaultOption = {
    objName: 'ServiceObj',
    serviceName: 'getDictMapCodeName'
  };
  reqParam = Object.assign({}, defaultOption, reqParam);
  excuteScriptService(reqParam, cb);
}

/**
 * 获取翻译后结果
 * @param [{ code: "01" }] arr 待处理的数据
 * @param {code: { tableName: "table1", showName: "boxType" }} dictMap 字典表与前端字段映射
 * @param {table1: {"01": "集装箱"}} maps 后端返回的字典表数据
 * return [{code: '01', boxType: '集装箱'}] 返回结果
 */
function getDictedRes(arr = [], dictMap = {}, maps = []) {
  const keys = Object.keys(dictMap);
  return arr.map((item) => {
    const o = {};
    keys.forEach((key) => {
      const v = item[key];
      const map = dictMap[key] && dictMap[key].tableName && maps[dictMap[key].tableName];
      const showName = dictMap[key] && dictMap[key].showName;
      if (v && map && showName) {
        o[dictMap[key].showName] = map[v];
      }
    });
    return Object.assign({}, item, o);
  });
}
/**
 * JSON 转 excel or csv
 */
function JSONToExcelConvertor({ data = [], fileName, dataTitle = [], filter, extension }) {
  if (!data) { return; }
  // 转化json为object
  let arrData = [];
  try {
    arrData = typeof data !== 'object' ? JSON.parse(data) : data;
  } catch (error) {
    console.error(error);
    return false;
  }
  let uri = '';
  let contentType = '';
  if (extension === 'csv') {
    let str = '';
    dataTitle.forEach((title) => {
      str += `${title},`;
    });
    str += '\n';
    data.forEach((item) => {
      for (const index in item) {
        if (filter) {
          if (filter.indexOf(index) === -1) {
            str += `${item[index]},`;
          }
        } else {
          str += `${item[index]},`;
        }
      }
      str += '\n';
    });

    uri = `data:text/csv;charset=utf-8,\ufeff${str}`;
    contentType = 'text/csv';
  } else {
    let excel = '<table>';
    // 设置表头
    let thRow = '<tr>';
    if (dataTitle) {
      // 使用标题项
      _.forEach(dataTitle, (t) => {
        thRow += `<th align='center'>${t}</th>`;
      });
    } else {
      _.forEach(arrData[0], (t) => {
        thRow += `<th align='center'>${t}</th>`;
      });
    }

    excel += `${thRow}</tr>`;

    // 设置数据
    for (let i = 0; i < arrData.length; i += 1) {
      let row = '<tr>';

      for (const index in arrData[i]) {
        // 判断是否有过滤行
        if (filter) {
          if (filter.indexOf(index) === -1) {
            const value = arrData[i][index] == null ? '' : arrData[i][index];
            row += `<td>${value}</td>`;
          }
        } else {
          const value = arrData[i][index] === null ? '' : arrData[i][index];
          row += `<td align='center'>${value}</td>`;
        }
      }

      excel += `${row}</tr>`;
    }

    excel += '</table>';

    let excelFile = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:x='urn:schemas-microsoft-com:office:excel' xmlns='http://www.w3.org/TR/REC-html40'>";
    excelFile += '<meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8">';
    excelFile += '<meta http-equiv="content-type" content="application/vnd.ms-excel';
    excelFile += '; charset=UTF-8">';
    excelFile += '<head>';
    excelFile += '<!--[if gte mso 9]>';
    excelFile += '<xml>';
    excelFile += '<x:ExcelWorkbook>';
    excelFile += '<x:ExcelWorksheets>';
    excelFile += '<x:ExcelWorksheet>';
    excelFile += '<x:Name>';
    excelFile += '{worksheet}';
    excelFile += '</x:Name>';
    excelFile += '<x:WorksheetOptions>';
    excelFile += '<x:DisplayGridlines/>';
    excelFile += '</x:WorksheetOptions>';
    excelFile += '</x:ExcelWorksheet>';
    excelFile += '</x:ExcelWorksheets>';
    excelFile += '</x:ExcelWorkbook>';
    excelFile += '</xml>';
    excelFile += '<![endif]-->';
    excelFile += '</head>';
    excelFile += '<body>';
    excelFile += excel;
    excelFile += '</body>';
    excelFile += '</html>';

    uri = `data:application/vnd.ms-excel;charset=utf-8,${excelFile}`;
    contentType = 'application/vnd.ms-excel';
  }

  const fileNames = `${fileName}.${extension || 'xls'}`;
  saveAs(new Blob([uri], { type: contentType }), fileNames);
}

/**
 *
 * @param {*} dom ctrl组件对象
 * @param {*} value 默认提交初始值
 * @param {*} tag 是否默认提交查询，不填写按照当前方式，提交0为自动
 */
function submitDefaultValue(dom, value, tag) {
  const newFormData = {};
  newFormData[dom.state.config.formItemId] = value;
  newFormData.ctrlIndex = dom.props.widgetIndex;
  newFormData.ctrlType = tag || dom.props.pageConfig.linkage;
  dom.setValue(value);
  dom.props.updateForm(newFormData);
}

/**
 *
 * @param {*} node 自定义图元HT节点
 */
function getCustomSymbolProps(node) {
  const customProp = node.getAttr('customProp') || [];
  const customSetedProp = node.getAttr('customSetedProp') || {};
  const result = {};
  _.each(customProp, (group) => {
    if (group.props && Object.keys(group.props).length) {
      _.map(group.props, (prop, id) => {
        result[`${group.label}.${prop.name}`] = customSetedProp[id] !== undefined ? customSetedProp[id] : prop.value;
      });
    }
  });
  return result;
}

/**
 *
 * @param {*} node 自定义图元HT节点
 * @param {*} tag 组件tag
 */
function getCustomSymbolComponent(node, tag) {
  let result;
  node.getChildren().each((d) => {
    const t = d.getTag();
    if (t === tag) {
      result = d;
    }
  });
  return result;
}

/**
 * @param {*} node datalink节点
 */
function registerDatalink(node) {
  if (!window.dataLinks) {
    window.dataLinks = {};
  }
  window.dataLinks[node.getTag()] = node;
}

function getDatalink(tag) {
  return window.dataLinks ? window.dataLinks[tag] : {};
}

function Alert(Message, callback) {
  const wrapComponent = getRegisterReactDom('previewWrapper');
  wrapComponent.showWarning(Message, callback);
}

/**
 * 刷新页面
 * @param {int} time 延时时间
 */
function reload(time = 50) {
  setTimeout(() => {
    window.location.reload();
  }, time);
}

export default {
  Alert,
  reload,
  domain: sysConfig.domain2,
  // 自定义图元相关
  getCustomSymbolProps,
  getCustomSymbolComponent,
  registerDatalink,
  getDatalink,
  // 请求相关
  request: request.bind(this),
  getFormatterByDataStructName,
  excuteScriptService,
  executeScriptService,
  getUserInfo,
  getSessionUserInfo,
  submitDefaultValue,
  setUserInfo,
  setAuthority,
  // react组件操作
  registerReactDom,
  logoutReactDom,
  getRegisterReactDom,
  setReactDomValue,
  setReactDomValues,
  handleReactDom,
  getReactDomValue,
  getReactDomValues,
  getFormData,
  setFormData,
  getProp,
  // 窗口操作
  closeCurrentPage,
  openPage,
  openParentPage,
  getFormatterMap,
  parseTableMap,
  // 工具库
  objKeySort,
  arrObjectKeySort,
  getUuid,
  setUuid,
  emptyUuid,
  isFunction,
  isEmptyObject,
  triggerEvent,
  timestampFormat,
  timestampAntiFormat,
  valueCheck,
  getRequestUrl,
  parsePrimitiveType,
  parseObjPropVals,
  groupDataByField,
  mergeData,
  splitList,
  Counter,
  isVaild,
  showMessage,
  getDictionary,
  getDictedRes,
  assignObj,
  isInArray,
  getActionHandle,
  getTimeZone,
  // 表格操作
  queryTableByObjectModel,
  showModal,
  showModalContent,
  closeModal,
  showLoading,
  closeLoading,
  renderTable,
  queryInstanceByTemplate,
  addInstanceByTemplate,
  updateInstance,
  delInstance,
  getEditRow,
  getSelectText,
  addDataTable,
  updateDataTable,
  delDataTable,
  queryDataTable,
  JSONToExcelConvertor,
  isZhizhiBrowser,
  ...htmlUtil
};
