/**
 * app 管理
 */
import request from '../utils/request';
import config from '../config';

// 查询 app 列表
export async function fetchAppList() {
  return request(`${config.domain11}/api/app/manager`, {
    method: 'GET'
  });
}

// 新增 app
export async function addNewApp(params = {}) {
  return request(`${config.domain11}/api/app/manager`, {
    method: 'POST',
    body: {
      ...params
    }
  });
}

// 删除 app
export async function deleteApp(appId) {
  return request(`${config.domain11}/api/app/manager?appId=${appId}`, {
    method: 'DELETE'
  });
}

// 编辑 app
export async function editApp({ appId, ...params }) {
  return request(`${config.domain11}/api/app/manager?appId=${appId}`, {
    method: 'PUT',
    body: {
      ...params
    }
  });
}

// app 详情
export async function fetchAppDetail(appId) {
  return request(`${config.domain11}/api/app/manager/detail?appId=${appId}`, {
    method: 'GET'
  });
}

// 获取 创建 app 时需要的appId和appSecret
export async function fetchAppId() {
  return request(`${config.domain11}/api/app/manager/getAppEnv`, {
    method: 'GET'
  });
}

// APP 导出触发
export async function startExport(appId) {
  return request(`${config.domain11}/api/app/manager/export?appId=${appId}`, {
    method: 'POST'
    // body: {}
  });
}

// APP 导出状态查询
export async function queryExportStatus(appId) {
  return request(`${config.domain11}/api/app/manager/export/status?appId=${appId}`, {
    method: 'GET'
  });
}

// APP 导入状态查询
export async function queryImportStatus(appId) {
  return request(`${config.domain11}/api/app/manager/import/status?appId=${appId}`, {
    method: 'GET'
  });
}

// APP 升级状态查询
export async function queryUpdateStatus(appId) {
  return request(`${config.domain11}/api/app/manager/upgrade/status?appId=${appId}`, {
    method: 'GET'
  });
}
// APP 状态查询
export async function queryStatus(appName) {
  return request(`${config.domain11}/api/metadata/app/${appName}/upgrade/status?type=app`, {
    method: 'GET'
  });
}

// app 运行状态切换
export async function containerActivity(params) {
  return request(`${config.domain11}/api/app/manager/containerActivity`, {
    method: 'PUT',
    body: {
      ...params
    }
  });
}

