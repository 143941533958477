
import { routerRedux } from 'dva/router';
import { message } from 'antd';
import { query as queryUsers, queryCurrent, editUserPwd,
  queryUserByUserName } from '../services/user';

export default {
  namespace: 'user',

  state: {
    list: [],
    currentUser: {},
    userInfo: {}
  },

  effects: {
    * fetch(_, { call, put }) {
      const response = yield call(queryUsers);
      yield put({
        type: 'save',
        response
      });
    },
    // *fetchCurrent(_, { call, put }) {
    * fetchCurrent({ payload, callback }, { call, put }) {
      const response = yield call(queryCurrent, payload);
      sessionStorage.setItem('userInfo', JSON.stringify(response));
      if (!response) {
        message.warning('接口发生异常，请重新登录！');
        yield put(routerRedux.push('/user/login'));
      }
      yield put({
        type: 'saveCurrentUser',
        response
      });

      if (callback) callback(response);
    },
    * updateCurrent({ payload, callback }, { call }) {
      const response = yield call(queryCurrent, payload);
      if (callback) callback(response);
    },
    * fetchUserInfo({ payload }, { call, put }) {
      const response = yield call(queryUserByUserName, payload);
      if (response && typeof response === 'object' && response.userInfo) {
        const { userInfo } = response;
        yield put({
          type: 'getUserInfo',
          payload: userInfo
        });
      } else {
        return false;
      }
    },
    *editPwd({
      payload,
      callback
    }, { call }) {
      const response = yield call(editUserPwd, payload);
      if (callback) { callback(response); }
    }
  },

  reducers: {
    save(state, action) {
      return {
        ...state,
        list: action.response
      };
    },
    saveCurrentUser(state, action) {
      return {
        ...state,
        currentUser: action.response && action.response.userSessionInfo
      };
    },
    getUserInfo(state, { payload }) {
      return {
        ...state,
        userInfo: payload
      };
    },
    changeNotifyCount(state, action) {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          notifyCount: action.payload
        }
      };
    }
  }
};
