import { queryLabelList, getNameByShowName } from '../services/api';

export default {
  namespace: 'modelLabels',
  state: {
    labeldata: { list: [] },
    aliasName: ''
  },
  effects: {
    // 获取标签
    *getLabel({ label, callback }, { call, put }) {
      const response = yield call(queryLabelList, label);
      yield put({
        type: 'save',
        label: response
      });
      if (callback) callback(response);
    },
    *getNameByShowName({ payload }, { call, put }) {
      const response = yield call(getNameByShowName, payload);
      yield put({
        type: 'saveName',
        payload: response
      });
    }
  },

  reducers: {
    save(state, action) {
      return {
        ...state,
        labeldata: action.label
      };
    },
    saveName(state, action) {
      return {
        ...state,
        aliasName: action.payload.name
      };
    }
  }
};
