import React from 'react';
import { connect } from 'dva';
import { IntlProvider, addLocaleData } from 'react-intl';
import { LocaleProvider } from 'antd';
import en from 'react-intl/locale-data/en';
import zh from 'react-intl/locale-data/zh';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import enUS from 'antd/lib/locale-provider/en_US';

addLocaleData([...en, ...zh]);

class LanguageProvider extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.dispatch = props.dispatch;
  }
  render() {
    const antdLanguage = this.props.id === 'zh-cn' ? zhCN : enUS;
    return (
      <IntlProvider
        locale={this.props.locale}
        id={this.props.id}
        messages={this.props.messages}
      >
        <LocaleProvider locale={antdLanguage}>
          {this.props.children}
        </LocaleProvider>
      </IntlProvider>
    );
  }
}

LanguageProvider.propTypes = {};

function mapStateToProps(state) {
  return {
    locale: state.language.locale,
    id: state.language.id,
    messages: state.language.messages
  };
}

export default connect(mapStateToProps)(LanguageProvider);
