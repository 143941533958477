import {
  addObjectTemplate,
  queryObjectTemplate,
  queryTemplateDetail,
  queryPropertyManager
  // removePropertyManager
} from '../services/api';

export default {
  namespace: 'propertyManagerData',
  state: {
    data: {
      list: [],
      pagination: {}
    }
  },
  effects: {

    // 获取对象模板列表
    * getList({ payload }, { call, put }) {
      const response = yield call(queryPropertyManager, payload);
      yield put({
        type: 'save',
        payload: response
      });
    },
    // 获取对象实例详情
    * getDetail({ payload }, { call, put }) {
      const response = yield call(queryTemplateDetail, payload);
      yield put({
        type: 'save',
        payload: response
      });
    },
    * fetch({ payload }, { call, put }) {
      const response = yield call(queryObjectTemplate, payload);
      yield put({
        type: 'save',
        payload: response
      });
    },
    * add({ payload, callback }, { call, put }) {
      const response = yield call(addObjectTemplate, payload);
      yield put({
        type: 'save',
        payload: response
      });
      if (callback) callback();
    }

    // // 删除对象模板
    // *remove({ payload, callback }, { call, put }) {
    //   const response = yield call(removePropertyManager, payload);
    //   yield put({
    //     type: 'save',
    //     payload: response
    //   });
    //   if (callback) callback();
    // }
  },

  reducers: {
    save(state, action) {
      return {
        ...state,
        data: action.payload
      };
    }
  }
};
