import { createElement } from 'react';
import dynamic from 'dva/dynamic';
import { injectIntl } from 'react-intl';
import { isSameDomain } from '../utils/utils';

let routerDataCache;

const modelNotExisted = (app, model) => (
  // eslint-disable-next-line
  !app._models.some(({ namespace }) => {
    return namespace === model.substring(model.lastIndexOf('/') + 1);
  })
);


// wrapper of dynamic  ..
const dynamicWrapper = (app, models, component) => {
  // () => require('module')
  // transformed by babel-plugin-dynamic-import-node-sync
  if (component.toString().indexOf('.then(') < 0) {
    models.forEach((model) => {
      if (modelNotExisted(app, model)) {
        // eslint-disable-next-line
        app.model(require(`../models/${model}`).default);
      }
    });
    return (props) => {
      if (!routerDataCache) {
        routerDataCache = getRouterData(app);
      }
      return createElement(injectIntl(component().default), {
        ...props,
        routerData: routerDataCache
      });
    };
  }
  return dynamic({
    app,
    models: () => models.filter(
      model => modelNotExisted(app, model)).map(m => import(`../models/${m}.js`)
    ),
    // add routerData prop
    component: async () => {
      if (!routerDataCache) {
        routerDataCache = getRouterData(app);
      }
      if (window.SUPOS && window.SUPOS.registerSuposFn && localStorage.getItem('ticket')) {
        if (!isSameDomain()) {
          await window.SUPOS.registerSuposFn('onReady').then((fn) => {
            if (fn) fn();
          });
        }
      }
      // console.log('raw');
      return component().then((raw) => {
        const Component = raw.default || raw;
        return props => createElement(Component, {
          ...props,
          routerData: routerDataCache
        });
      });
    }
  });
};

export const getRouterData = (app) => {
  const routerConfig = {
    '/': {
      component: dynamicWrapper(app, ['user', 'login', 'authMenu'], () => import('../layouts/BasicLayout'))
    },
    '/layout': {
      component: dynamicWrapper(app, ['user', 'global', 'authMenu'], () => import('../layouts/BlankLayout'))
    },
    '/layout/application-preview/:appId': { // app预览页面
      component: dynamicWrapper(app, ['industryApplicationDetail', 'industryApplicationLayout', 'newIndustryWidgets',
        'objectSource', 'alarmInfo', 'systemInfoMgr', 'authMenu', 'global', 'task', 'process'],
      () => import('../routes/IndustryApplicationMgr/ApplicationPreview/ApplicationPreview'))
    },
    '/layout/industry-application-mgr/application-editor/:id': { // APP页面编辑
      component: dynamicWrapper(app, ['industryApplicationDetail', 'industryApplicationLayout', 'process', 'alarmInfo', 'authMenuPage', 'objectSource'],
        () => import('../routes/IndustryApplicationMgr/ApplicationEditor/ApplicationEditor'))
    },
    '/layout/workflow/:id': {
      component: dynamicWrapper(app, ['newIndustryWidgets', 'objectSource', 'authMenu', 'process', 'commonModel'],
        () => import('../routes/Workflow/Workflow'))
    },
    // ****************** 普通用户页面 *******************
    // 个人信息管理
    '/personal/personal-msg': { // 个人信息管理基本信息
      component: dynamicWrapper(app, ['user', 'objectSource', 'usermanage'], () => import('../routes/Personal/personalMsg'))
    },
    // 个人信息管理
    '/personal/password': { // 个人修改密码
      component: dynamicWrapper(app, ['user'], () => import('../routes/Personal/password'))
    },
    '/application-runtime/:pageId': { // app运行页面
      component: dynamicWrapper(app, ['industryApplicationLayout', 'newIndustryWidgets',
        'objectSource', 'authMenu', 'global', 'alarmInfo', 'systemInfoMgr', 'task', 'process'],
      () => import('../routes/IndustryApplicationMgr/ApplicationRuntime/ApplicationRuntime'))
    },
    // ******************  设计人员页面 *******************
    '/design': {
      component: dynamicWrapper(app, ['user', 'login', 'authMenu'], () => import('../layouts/DesignLayout'))
    },
    // // 个人信息管理
    '/design/personal/personal-msg': { // 个人信息管理基本信息
      component: dynamicWrapper(app, ['user', 'usermanage', 'objectSource'], () => import('../routes/Personal/personalMsg'))
    },
    // 个人信息管理
    '/design/personal/password': { // 个人修改密码
      component: dynamicWrapper(app, ['user'], () => import('../routes/Personal/password'))
    },
    // // 用戶管理配置
    // '/design/user-manage/user-itemlist': { // 用户管理列表首页
    //   component: dynamicWrapper(app, ['usermanage', 'resources', 'objectSource'], () => import('../routes/UserManage/UserItemlist'))
    // },
    // '/design/user-itemlist/ActiveUser': { // 用户管理列表
    //   component: dynamicWrapper(app, ['usermanage'], () => import('../routes/UserManage/ActiveUser'))
    // },
    // // 角色管理配置
    // '/design/user-manage/role-itemlist': { // 角色管理列表首页
    //   component: dynamicWrapper(app, ['rolemanage', 'roleResources'], () => import('../routes/RoleManage/RoleItemlist'))
    // },
    // 接入管理配置
    '/design/collector/acceptm': { // 设备接入管理页面
      component: dynamicWrapper(app, ['deviceManage'], () => import('../routes/DeviceManage/DeviceItemList'))
    },
    // 采集站管理
    '/design/collector/manage': { // 设备接入管理页面
      component: dynamicWrapper(app, ['deviceManage'], () => import('../routes/DeviceManage/DeviceManage'))
    },
    // 系统信息管理
    '/design/system-info-mgr/system-info': {
      component: dynamicWrapper(app, ['systemInfoMgr', 'authMenu', 'global'], () => import('../routes/SystemInfoMgr/SystemInfo'))
    },
    // supos 版本
    '/design/about': {
      component: dynamicWrapper(app, ['systemInfoMgr', 'authMenu', 'global'], () => import('../routes/SystemInfoMgr/About'))
    },

    /* ********************************************天匠模块********************************************** */

    // // 工厂单元类型
    // '/design/factory-modeling-mgr/factory-unit-type': {
    //   component: dynamicWrapper(app, [], () => import('../routes/FactoryModelingMgr/FactoryUnitType'))
    // },

    // 工厂单元类型
    '/design/factory-modeling-mgr/:path': {
      component: dynamicWrapper(app, [], () => import('../routes/WorkShop'))
    },
    '/design/object-template-mgr/:path': {
      component: dynamicWrapper(
        app, [], () => import('../routes/WorkShop'))
    },
    // 非iframe模式使用
    '/design/factory-modeling-mgr/tree/:path': {
      component: dynamicWrapper(app, [], () => import('../routes/WorkShop'))
    },
    '/design/object-template-mgr/modelLabel/:path': {
      component: dynamicWrapper(
        app, [], () => import('../routes/WorkShop'))
    },

    /* ********************************************天匠模块********************************************** */

    // 工业大数据
    '/design/industry-big-data/:path': {
      component: dynamicWrapper(app, [], () => import('../routes/IndustryBigData/BigData'))
    },
    /* 计算任务 */
    '/design/industry-task-scheduler/:path': {
      component: dynamicWrapper(app, [], () => import('../routes/IndustryBigData/BigData'))
    },
    /* 全局配置 */
    '/design/global-setting/:path': {
      component: dynamicWrapper(app, [], () => import('../routes/IndustryBigData/BigData'))
    },

    /* **********************天盾模块************************ */

    /* 企业组织架构 */
    '/design/network/:path': {
      component: dynamicWrapper(app, [], () => import('../routes/Shield/Shield'))
    },
    '/design/network/organizationsEdit/add': {
      component: dynamicWrapper(app, [], () => import('../routes/Shield/Shield'))
    },
    /* 用户安全 */
    '/design/user-manage/:path': {
      component: dynamicWrapper(app, [], () => import('../routes/Shield/Shield'))
    },
    /* 移动设备鉴权 */
    '/design/mobile-auth/:path': {
      component: dynamicWrapper(app, [], () => import('../routes/Shield/Shield'))
    },
    // 非iframe模式使用
    '/design/network/organizationsEdit/root/:id': { // 组织管理
      component: dynamicWrapper(app, [], () => import('../routes/Shield/Shield'))
    },
    '/design/user-manage/data-auth-detail/:id': {
      component: dynamicWrapper(app, [], () => import('../routes/Shield/Shield'))
    },
    '/design/user-manage/data-auth-detail/:name/:id': {
      component: dynamicWrapper(app, [], () => import('../routes/Shield/Shield'))
    },
    // 工业APP管理
    '/design/industry-application-mgr/application-mgr': { // APP管理
      component: dynamicWrapper(app, ['industryApplication', 'global', 'authMenu'],
        () => import('../routes/IndustryApplicationMgr/ApplicationMgr/ApplicationMgr'))
    },
    '/design/industry-application-mgr/application-editor/:id': { // APP页面编辑
      component: dynamicWrapper(app, ['industryApplicationDetail', 'industryApplicationLayout', 'process', 'alarmInfo', 'authMenuPage', 'objectSource'],
        () => import('../routes/IndustryApplicationMgr/ApplicationEditor/ApplicationEditor'))
    },
    '/design/industry-application-mgr/business-designer/app_default': { // 业务设计
      component: dynamicWrapper(app, ['industryApplicationDetail', 'industryApplicationLayout', 'process', 'objectSource', 'authMenuPage'],
        () => import('../routes/IndustryApplicationMgr/ApplicationEditor/ApplicationEditor'))
    },
    '/design/industry-application-mgr/business-designer/workprocess_instance_mgr': { // 流程实例
      component: dynamicWrapper(app, ['industryApplicationDetail', 'industryApplicationLayout', 'process', 'objectSource', 'authMenuPage'],
        () => import('../routes/WorkProcess/WorkProcessInstanceList/WorkProcessInstanceList'))
    },
    '/design/industry-application-mgr/business-designer/todo_instance_mgr': { // 任务实例
      component: dynamicWrapper(app, ['industryApplicationDetail', 'industryApplicationLayout', 'process', 'objectSource', 'authMenuPage'],
        () => import('../routes/WorkProcess/TaskInstanceList/TaskInstanceList'))
    },
    // APP 静态资源管理
    '/layout/industry-application-mgr/file-mgr/:id': { // APP管理
      component: dynamicWrapper(app, [], () => import('../routes/FileMgr/FileMgr'))
    },

    // 自定义图元管理
    '/layout/custom-graph-mgr/graph-editor/:id': {
      component: dynamicWrapper(app, ['customGraph', 'industryApplicationLayout', 'process', 'authMenuPage', 'objectSource'],
        () => import('../routes/CustomGraphMgr/CustomGraphEditor/CustomGraphEditor'))
    },

    // 通知中心-类型配置
    '/design/notification-center/type-config': {
      component: dynamicWrapper(app, [],
        () => import('../routes/NotificationCenter/NotificationCenter'))
    },

    // 通知中心-日志管理
    '/design/notification-center/log-manage': {
      component: dynamicWrapper(app, [],
        () => import('../routes/NotificationCenter/LogManage'))
    },

    // 通知中心-站内信管理
    '/design/notification-center/msg-manage': {
      component: dynamicWrapper(app, [],
        () => import('../routes/NotificationCenter/MsgManage'))
    },

    // // 公司组织架构 => 组织管理列表
    // '/design/network/organizations': { // 组织管理
    //   component: dynamicWrapper(app, ['organizationsManager'],
    //     () => import('../routes/Network/Organizations/index'))
    // },

    // // 公司组织架构 => 组织管理新增
    // '/design/network/organizationsEdit/add': { // 组织管理
    //   component: dynamicWrapper(app, ['organizationsManager'],
    //     () => import('../routes/Network/Organizations/detail'))
    // },

    // // 公司组织架构 => 组织管理详情
    // '/design/network/organizationsEdit/root/:id': { // 组织管理
    //   component: dynamicWrapper(app, ['organizationsManager'],
    //     () => import('../routes/Network/Organizations/detail'))
    // },

    // // 公司组织架构 => 部门管理
    // '/design/network/department': { // 部门管理
    //   component: dynamicWrapper(app, ['objectInstance', 'objectpropertyManager', 'networkDepartment'],
    //     () => import('../routes/Network/Department/index'))
    // },
    // // 公司组织架构 => 岗位管理
    // '/design/network/position': { // 岗位管理
    //   component: dynamicWrapper(app, ['objectInstance', 'positionManager', 'objectpropertyManager', 'networkStaff', 'networkDepartment'],
    //     () => import('../routes/Network/Position/PositionIndex'))
    // },
    // // 公司组织架构 => 人员管理
    // '/design/network/staff': { // 人员管理
    //   component: dynamicWrapper(app, ['objectInstance', 'networkStaff', 'objectpropertyManager', 'networkDepartment'],
    //     () => import('../routes/Network/Staff/index'))
    // },

    // ******************  工作流页面 *******************
    '/design/workflow/:id': {
      component: dynamicWrapper(app, ['newIndustryWidgets', 'objectSource', 'authMenu', 'process', 'commonModel'],
        () => import('../routes/Workflow/Workflow'))
    },
    '/layout/custom-comp/:name': {
      name: 'custom-comp',
      component: dynamicWrapper(app, ['newIndustryWidgets', 'objectSource', 'authMenu', 'process', 'commonModel', 'customGraph'],
        () => import('../routes/Workflow/Workflow'))
    },
    // ****************** 运行期全屏预览页面 *******************
    '/runtime-fullscreen': {
      component: dynamicWrapper(app, ['user', 'login', 'authMenu'], () => import('../layouts/RuntimeLayout'))
    },
    '/runtime-fullscreen/runtime-fullscreen/:pageId': {
      component: dynamicWrapper(app, ['industryApplicationLayout', 'newIndustryWidgets', 'objectSource',
        'authMenu', 'global', 'alarmInfo', 'systemInfoMgr', 'task', 'process'],
      () => import('../routes/IndustryApplicationMgr/ApplicationRuntime/ApplicationRuntime'))
    },
    // TODO：项目中后期需要清理以下参考页面以及不需要的文件 @张顺金
    // ******************  操作展示页面 *******************
    '/exception/403': {
      component: dynamicWrapper(app, [], () => import('../routes/Exception/403'))
    },
    '/exception/404': {
      component: dynamicWrapper(app, [], () => import('../routes/Exception/404'))
    },
    '/exception/500': {
      component: dynamicWrapper(app, [], () => import('../routes/Exception/500'))
    },
    '/exception/trigger': {
      component: dynamicWrapper(app, ['error'], () => import('../routes/Exception/triggerException'))
    },
    // ******************  用户登录注册页面 *******************
    '/user': {
      component: dynamicWrapper(app, [], () => import('../layouts/UserLayout'))
    },
    '/user/login': {
      component: dynamicWrapper(app, ['login', 'systemInfoMgr', 'global'], () => import('../routes/User/Login'))
    },
    '/hellow': {
      component: dynamicWrapper(app, [], () => import('../components/Hellow/Hellow.js'))
    },
    '/design/hellow': {
      component: dynamicWrapper(app, [], () => import('../components/Hellow/Hellow.js'))
    },
    '/design/app-menu-setting': {
      component: dynamicWrapper(app, ['appMenuSetting'], () => import('../routes/AppMenu/AppMenuSetting.js'))
    }
  };

  const routerData = {};
  Object.keys(routerConfig).forEach((item) => {
    routerData[item] = {
      ...routerConfig[item],
      name: routerConfig[item].name,
      authority: routerConfig[item].authority
    };
  });
  return routerData;
};
