import {
  addUser,
  editUser,
  removeUser,
  queryUserList,
  queryUserByUserName,
  queryRoleList,
  queryUserListByName,
  batchRemoveUser
} from '../services/api';

export default {
  namespace: 'usermanage',

  state: {
    data: {
      list: [],
      pagination: {}
    },
    roleData: {
      list: [],
      pagination: {}
    },
    userListData: {
      list: [],
      pagination: {}
    },
    userData: {
      user: {}
    }
  },

  effects: {

    // 获取用户列表
    *getList({ payload, callback }, { call, put }) {
      const response = yield call(queryUserList, payload);
      yield put({
        type: 'getUserList',
        payload: response
      });
      if (callback) callback(response);
    },

    // 根据用户名称获取用户信息
    *getUserListByName({ payload, callback }, { call, put }) {
      const response = yield call(queryUserListByName, payload);
      yield put({
        type: 'getUserList',
        payload: response
      });
      if (callback) callback(response);
    },

    // 通过用户id获取用户信息
    *queryUserByUserName({ payload, callback }, { call, put }) {
      const response = yield call(queryUserByUserName, payload);
      yield put({
        type: 'getUser',
        payload: response
      });
      if (callback) callback(response);
    },
    // 修改用户
    *edit({ payload, callback }, { call }) {
      const response = yield call(editUser, payload);
      if (callback) callback(response);
    },
    // 增加用户
    *add({ payload, callback }, { call, put }) {
      const response = yield call(addUser, payload);
      yield put({
        type: 'save',
        payload: response
      });
      if (callback) callback(response);
    },
    // 删除用户
    *remove({ payload, callback }, { call }) {
      const response = yield call(removeUser, payload);
      if (callback) callback(response);
    },
    // 批量删除用户
    *batchRemove({ payload, callback }, { call }) {
      const response = yield call(batchRemoveUser, payload);
      if (callback) callback(response);
    },
    // 查询角色信息
    *getRoleList({ payload, callback }, { call, put }) {
      const response = yield call(queryRoleList, payload);
      yield put({
        type: 'roleList',
        payload: response
      });
      if (callback) callback(response);
    }
  },

  reducers: {
    save(state, action) {
      return {
        ...state,
        data: action.payload
      };
    },
    roleList(state, action) {
      return {
        ...state,
        roleData: action.payload
      };
    },
    getUserList(state, action) {
      return {
        ...state,
        userListData: action.payload
      };
    },
    getUser(state, action) {
      return {
        ...state,
        userData: action.payload
      };
    }
  }
};
