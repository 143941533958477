import request from '../utils/request';
import config from '../config';

/**
 * 非 自定义图元库 相关API不要放在这里!
 */

// 获取 获取图元库树结构
export async function fetchFoldersTree(data) {
  return request(`${config.domain2}/api/compose/manage/folders?parentId=${data.id}&type=symbol`);
}

// 获取自定义图元文件夹数据
export async function fetchTree(id) {
  return request(`${config.domain2}/api/compose/manage/symbol/tree/${id}`);
}

// 新增 图元
export async function addNewSymbol(obj) {
  return request(`${config.domain2}/api/compose/manage/symbol`,
    {
      method: 'POST',
      body: { ...obj }
    }
  );
}

// 删除 图元
export async function delSymbol(id) {
  return request(`${config.domain2}/api/compose/manage/symbol/${id}`,
    { method: 'DELETE' }
  );
}

// 编辑 图元
export async function editSymbol({ id, ...params }) {
  return request(`${config.domain2}/api/compose/manage/symbol/${id}`,
    {
      method: 'PUT',
      body: { ...params }
    }
  );
}

// 拷贝 图元
export async function copySymbol(id) {
  return request(`${config.domain2}/api/compose/manage/symbol/${id}/copy`,
    { method: 'POST' }
  );
}

// 新增 文件夹
export async function addNewCategory(params) {
  return request(`${config.domain2}/api/compose/manage/category`,
    {
      method: 'POST',
      body: { ...params }
    }
  );
}

// 删除 文件夹
export async function delCategory(id) {
  return request(`${config.domain2}/api/compose/manage/category/${id}`,
    { method: 'DELETE' }
  );
}

// 编辑 文件夹
export async function editCategory({ id, ...params }) {
  return request(`${config.domain2}/api/compose/manage/category/${id}`,
    {
      method: 'PUT',
      body: { ...params }
    }
  );
}

export async function getSymbol(id) {
  return request(
    `${config.domain2}/api/compose/manage/symbol/${id}`,
    { method: 'GET' }
  );
}

export async function getAllSymbol(symbols) {
  return request(`${config.domainObject}/objectselector/instances/batchQuery/forSymbol`,
    {
      method: 'POST',
      body: {
        input: symbols
      }
    }
  );
}
