import {
  queryDataStructManager, postDataStructManager,
  getDetailDataStruct, putDataStructManager,
  deleteDataStructManager, deleteDataStructManagerList,
  postDataStructFields,
  queryDataStructFields, getDataStructField,
  putDataStructField, deleteDataStructField, deleteDataStructFieldList,
  queryTemplateList, getdatastructList
} from '../services/api';

export default {
  namespace: 'dataStructManager',
  state: {
    dataStructs: { list: [] },
    detailDataStruct: {},
    structFieldList: [[]],
    dataStructField: {}
  },

  effects: {
    * queryDataStructManager({ payload, callback }, { call, put }) {
      const response = yield call(queryDataStructManager, payload);

      if (callback) callback(response);
      if (!response) return;
      yield put({
        type: 'setDataStructs',
        payload: response
      });
    },
    * postDataStructManager({ payload, callback }, { call }) {
      const response = yield call(postDataStructManager, payload);
      if (callback) callback(response);
    },
    * getDetailDataStruct({ payload, callback }, { call, put }) {
      const response = yield call(getDetailDataStruct, payload);
      yield put({
        type: 'setDetailDataStruct',
        payload: response
      });
      if (callback) callback(response);
    },
    * putDataStructManager({ payload, callback }, { call }) {
      const response = yield call(putDataStructManager, payload);
      if (callback) callback(response);
    },
    * deleteDataStructManager({ payload, callback }, { call }) {
      const response = yield call(deleteDataStructManager, payload);
      if (callback) callback(response);
    },
    * deleteDataStructManagerList({ payload, callback }, { call }) {
      const response = yield call(deleteDataStructManagerList, payload);
      if (callback) callback(response);
    },
    * postDataStructFields({ payload, callback }, { call }) {
      const response = yield call(postDataStructFields, payload);
      if (callback) callback(response);
    },
    * queryDataStructFields({ payload, callback }, { call, put }) {
      const response = yield call(queryDataStructFields, payload);
      yield put({
        type: 'setDataStructFields',
        payload: response
      });
      if (callback) callback(response);
    },
    * getDataStructField({ payload, callback }, { call, put }) {
      const response = yield call(getDataStructField, payload);
      yield put({
        type: 'setDataStructField',
        payload: response
      });
      if (callback) callback(response);
    },
    * putDataStructField({ payload, callback }, { call }) {
      const response = yield call(putDataStructField, payload);
      if (callback) callback(response);
    },
    * deleteDataStructField({ payload, callback }, { call }) {
      const response = yield call(deleteDataStructField, payload);
      if (callback) callback(response);
    },
    * deleteDataStructFieldList({ payload, callback }, { call }) {
      const response = yield call(deleteDataStructFieldList, payload);
      if (callback) callback(response);
    },
    // 获取功能集合列表
    * getObjectTemList({ payload, callback }, { call }) {
      const response = yield call(queryTemplateList, payload);
      if (callback) callback(response);
    },
    * getdatastructList({ payload, callback }, { call }) {
      const response = yield call(getdatastructList, payload);
      if (callback) callback(response);
    }

  },

  reducers: {
    setDataStructs(state, action) {
      return {
        ...state,
        dataStructs: action.payload
      };
    },
    setDetailDataStruct(state, action) {
      return {
        ...state,
        detailDataStruct: action.payload
      };
    },
    setDataStructFields(state, action) {
      return {
        ...state,
        structFieldList: action.payload
      };
    },
    setDataStructField(state, action) {
      return {
        ...state,
        dataStructField: action.payload
      };
    }
  }
};

