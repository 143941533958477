import { stringify } from 'qs';
import { allGroup } from 'root/constants';
import commonMessage from 'root/common/messages';
import request from '../utils/request';
import config from '../config';

export async function queryProjectNotice() {
  return request('/api/project/notice');
}

export async function queryActivities() {
  return request('/api/activities');
}
// 类型查询
export async function queryPrimitiveType() {
  return request(`${config.domain2}/api/metadata/primitive_types`);
}


// 执行测试
export async function getServiceTest(params) {
  return request(`${config.domain2}/api/runtime/objects/${params.objectName}/debugServices/${params.serviceName}`, {
    method: 'POST',
    body: {
      ...params.input
    }
  });
}
// 批量查询对象模板的我的属性管理列表 【格式如：/api/object_templates?name=name】
export async function queryPropertyManager(params) {
  return request(
    `${config.domain2}/api/metadata/object_templates/${params.name}/properties`
  );
  // return request(`http://192.168.10.63:8080/api/objects?${stringify(params)}`);
}


// 属性管理移除 (对象实例)
export async function transferProperty({ name, targetName, properties }) {
  return request(
    `${config.domain2}/api/metadata/objects/${name}/properties/move/${targetName}`,
    {
      method: 'PUT',
      body: {
        properties
      }
    }
  );
}
// 属性管理移除 (对象模板)
export async function transferTemProperty({ name, targetName, properties }) {
  return request(
    `${config.domain2}/api/metadata/object_templates/${name}/properties/move/${targetName}`,
    {
      method: 'PUT',
      body: {
        properties
      }
    }
  );
}

// 对象模板我的属性管理的单行删除
export async function removeTemProperty(params) {
  return request(
    `${config.domain2}/api/metadata/object_templates/${params.temName}/properties/${params.propName}`,
    {
      method: 'DELETE'
    }
  );
}
// 对象模板我的属性管理的多行删除
export async function removeTemPropertys(params) {
  return request(
    `${config.domain2}/api/metadata/object_templates/${params.name}/properties/delete`,
    {
      method: 'POST',
      body: {
        names: params.names
      }
    }
  );
}

// 对象模板我的属性的修改
export async function queryTemplateEdit(params) {
  return request(
    `${config.domain2}/api/metadata/object_templates/${
      params.temName
    }/properties/${params.propName}`,
    {
      method: 'PUT',
      body: {
        ...params
      }
    }
  );
}
// 对象模板我的属性的添加
export async function addTemProperty(params) {
  return request(
    `${config.domain2}/api/metadata/object_templates/${
      params.temName
    }/properties`,
    {
      method: 'POST',
      body: {
        ...params
      }
    }
  );
}

// 对象模板-其他属性-列表
export async function getParentPropertyList(params) {
  return request(`${config.domain2}/api/metadata/object_templates/${params.name}/parentProperties`);
}

// 对象实例的属性管理的删除
export async function removePropertyManager(params) {
  return request(
    `${config.domain2}/api/metadata/objects/${params.instanceName}/properties/${
      params.name
    }`,
    {
      method: 'DELETE'
    }
  );
}

// 批量删除对象模板
export async function removeObjectsByNames(params) {
  return request(
    `${config.domain2}/api/metadata/objects/delete`,
    {
      method: 'POST',
      body: {
        ...params
      }
    }
  );
}

// 对象实例的属性管理的批量删除/objects/{name}/properties
export async function removePropertyManagers(params) {
  return request(
    `${config.domain3}/api/metadata/objects/${params.name}/properties/delete`,
    {
      method: 'POST',
      body: {
        names: params.names
      }
    }
  );
}
// 对象实例的属性管理的批量修改/objects/{name}/properties
export async function updateBatchProperty(params) {
  // console.log(params);
  return request(
    `${config.domain2}/api/metadata/objects/${params.name}/properties`,
    {
      method: 'PUT',
      body: {
        ...params
      }
    }
  );
}

// 更新-对象实例-我的属性  详情
export async function queryProperDetail(params) {
  return request(
    `${config.domain2}/api/metadata/objects/${params.objName}/properties/${
      params.myPropertyName
    }`
  );
}

// 更新-对象实例-我的属性 编辑
export async function queryProperChange(params) {
  return request(
    `${config.domain2}/api/metadata/objects/${params.objName}/properties/${
      params.myPropertyName
    }`,
    {
      method: 'PUT',
      body: {
        ...params
      }
    }
  );
}
// 获取我的对象模板列表
export async function queryTemplateList(params) {
  const keyWord = params.keyWord ? `&keyWord=${encodeURIComponent(params.keyWord)}` : '';
  return request(`${config.domain2}/api/metadata/object_templates?page=${params.page || 1}&per_page=${
    params.per_page || 20000
  }&visible=${
    params.visible || 'true'
  }&systemProperty=${
    params.systemProperty || 'true'
  }${keyWord}`);
  // return request(`http://192.168.10.63:8080/api/objects?${stringify(params)}`);
}

// 删除对象模板实例
export async function removeObjectTemplate(params) {
  return request(
    `${config.domain2}/api/metadata/object_templates/${params.name}`,
    {
      method: 'DELETE',
      body: {
        ...params
      }
    }
  );
}
// 批量删除对象模板
export async function removeObjectTemplates(params) {
  return request(
    `${config.domain2}/api/metadata/object_templates/delete`,
    {
      method: 'POST',
      body: {
        ...params
      }
    }
  );
}

// 获取标签-基础信息中使用
export async function queryLabelList(params) {
  return request(`${config.domain2}/api/metadata/modelLabels?modelLabelsType=${params.modelLabelsType}`);
  // return request(`http://192.168.10.63:8080/api/objects?${stringify(params)}`);
}

// 获取name-基础信息中使用
export async function getNameByShowName(params) {
  return request(`${config.domain2}/api/metadata/autoNumber?templateName=${params.templateName}`);
  // return request(`http://192.168.10.63:8080/api/objects?${stringify(params)}`);
}

// 获取属性标签
export async function queryEleLabelList() {
  return request(`${config.domain2}/api/metadata//ElementsmodelLabels`);
  // return request(`http://192.168.10.63:8080/api/objects?${stringify(params)}`);
}

// 新增-对象实例-我的属性
export async function addproperty(params) {
  // return request('/api/objectInstance', {
  return request(
    `${config.domain2}/api/metadata/objects/${params.objName}/properties`,
    {
      method: 'POST',
      body: {
        ...params
      }
    }
  );
}

// 新增-对象实例-绑定数据源- 属性添加
export async function addpropertyByBind(params) {
  return request(
    `${config.domain2}/api/metadata/objects/${params.objName}/bindings/batchAdd`,
    {
      method: 'POST',
      body: {
        ...params
      }
    }
  );
}
// 新增对象模板
export async function addObjectTemplate(params) {
  // return request('/api/objectInstance', {
  return request(`${config.domain2}/api/metadata/object_templates`, {
    // return request(`${config.domain}/api/objects`, {
    method: 'POST',
    body: {
      ...params
    }
  });
}

// 对象快照 【格式如：/api/objects/name2/snapshot】
export async function queryObjectSnapshot(params) {
  return request(
    `${config.domain}/api/runtime/objects/${params.name}/snapshot`
  );
}

// 查询对象模板详情 【格式如：/api/objects/name2】
export async function queryTemplateDetail(params) {
  return request(`${config.domain5}/api/metadata/objects/${params.name}`);
}

// 查询对象模板
export async function queryObjectTemplate(params) {
  return request(
    `${config.domain}/api/metadata/objectInstance?${stringify(params)}`
  );
}

// 批量查询对象实例列表 【格式如：/api/objects?name=name2】
export async function queryObjectsList(params) {
  const keyWord = params.keyWord ? `&keyWord=${encodeURIComponent(params.keyWord)}` : '';
  return request(`${config.domain2}/api/metadata/objects?page=${params.page || 1}&per_page=${
    params.per_page || 20000
  }&resultType=${
    params.resultType || false
  }&visible=${
    params.visible || 'true'
  }&systemProperty=${
    params.systemProperty || 'true'
  }&remote=${
    params.remote || 'true'
  }${keyWord}`);
  // return request(`http://192.168.10.63:8080/api/objects?${stringify(params)}`);
}

// 批量查询对象实例的属性管理列表 【格式如：/api/objects?name=name2】
export async function queryObjectManager(params) {
  // return request(`http://192.168.10.63:8080/api/objects?${stringify(params)}`);
  return request(
    `${config.domain2}/api/metadata/objects/${params.name}/properties`,
    {
      method: 'GET'
      // body: {
      //   ...params,
      //   method: 'delete'
      // }
    }
  );
}

// 批量查询对象实例的属性管理列表 【格式如：/api/objects?name=name2
// name 对象名称
export async function queryObjectParentManager(params) {
  // return request(`http://192.168.10.63:8080/api/objects?${stringify(params)}`);
  return request(
    `${config.domain2}/api/metadata/objects/${params.name}/parentProperties/${
      params.templateName // 父模板
    }`,
    {
      method: 'GET'
    }
  );
}

// 获取数据源
export async function getObejctSource(params) {
  return request(`/api/runtime/objects/snapshot?name=${params.name}`);
}

// 更新-对象实例-基础信息
export async function queryObjectsDetail(params) {
  return request(`${config.domain2}/api/metadata/objects/${params.name}`, {
    method: 'PUT',
    body: {
      ...params
    }
  });
}

// 删除-对象实例
export async function removeObjectInstance(params) {
  // return request('/api/objectInstance', {
  return request(`${config.domain2}/api/metadata/objects/${params.name}`, {
    method: 'DELETE'
  });
}
// 批量删除-对象实例
export async function removeObjectInstances(params) {
  // return request('/api/objectInstance', {
  return request(`${config.domain2}/api/metadata/objects/delete`, {
    method: 'POST',
    body: {
      ...params
    }
  });
}


// 拷贝-对象实例
export async function copyObjectInstance(params) {
  return request(`${config.domain2}/api/metadata/objects/${params.sourceName}/cloneTo`, {
    method: 'POST',
    body: {
      showName: params.showName,
      name: params.name
    }
  });
}


// 新增-对象实例
export async function addObjectInstance(params) {
  return request(`${config.domain2}/api/metadata/objects`, {
    // return request(`${config.domain}/api/objects`, {

    method: 'POST',
    body: {
      ...params
    }
  });
}

// 修改-对象模板-基本信息
export async function queryTemplateBasic(params) {
  // return request('/api/objectInstance', {
  return request(
    `${config.domain2}/api/metadata/object_templates/${params.name}`,
    {
      // return request(`${config.domain}/api/objects`, {
      method: 'PUT',
      body: {
        ...params
      }
    }
  );
}
// 列表-对象模板
export async function getTemplateBasic(params) {
  return request(
    `${config.domain2}/api/metadata/object_templates/${params.name}`
  );
}

// 拷贝 对象模板
export async function copyObjectTemplate(params) {
  return request(
    `${config.domain2}/api/metadata/object_templates/${params.sourceName}/cloneTo`,
    {
      method: 'POST',
      body: {
        showName: params.showName,
        name: params.name
      }
    }
  );
}


// 对象实例事件列表 /objects/{name}/events
export async function getObjectEvents(params) {
  return request(
    `${config.domain2}/api/metadata/objects/${params.instanceName}/events`,
    {
      method: 'GET'
    }
  );
}
// 列表-对象实例-父级列表 /objects/{name}/events
export async function getObjectParentEvents(params) {
  return request(
    `${config.domain2}/api/metadata/objects/${params.instanceName}/parentEvents/${params.templateName}`,
    {
      method: 'GET'
    }
  );
}
// 对象实例事件
export async function getObjEvent(params) {
  return request(
    `${config.domain2}/api/metadata/objects/${params.instanceName}/events/${
      params.name
    }`,
    {
      method: 'GET'
    }
  );
}
// 新增实例事件
export async function postObjectEvents(params) {
  return request(
    `${config.domain2}/api/metadata/objects/${params.instanceName}/events`,
    {
      method: 'POST',
      body: {
        ...params
      }
    }
  );
}
// 删除实例事件
export async function deleteObjEvent(params) {
  return request(
    `${config.domain2}/api/metadata/objects/${params.instanceName}/events/${
      params.name
    }`,
    {
      method: 'DELETE'
    }
  );
}
// 批量删除实例事件
export async function deleteObjEvents(params) {
  return request(
    `${config.domain2}/api/metadata/objects/${params.name}/events/delete`,
    {
      method: 'POST',
      body: {
        names: params.names
      }
    }
  );
}
// 修改实例事件
export async function putObjEvent(params) {
  return request(
    `${config.domain2}/api/metadata/objects/${params.instanceName}/events/${
      params.eventName
    }`,
    {
      method: 'PUT',
      body: {
        ...params
      }
    }
  );
}
// 对象实例事件
export async function getdatastructList(params) {
  return request(`${config.domain2}/api/metadata/datastructs?page=${params.page || 1}&per_page=${
    params.per_page || 20000
  }`, {
    method: 'GET'
  });
}
// 查询对象实例订阅
export async function getSubscription(params) {
  return request(
    `${config.domain2}/api/metadata/objects/${
      params.instanceName
    }/subscriptions`,
    {
      method: 'GET'
    }
  );
}
// 列表-功能集合-订阅
export async function getFeatureSubscription(params) {
  return request(
    `${config.domain2}/api/metadata/features/${
      params.objName
    }/subscriptions?queryType=${params.queryType}&ownerType=${params.ownerType}`,
    {
      method: 'GET'
    }
  );
}
// 查询对象实例订阅
export async function getTemplateSubscription(params) {
  return request(
    `${config.domain2}/api/metadata/object_template/${
      params.objName
    }/subscriptions?queryType=${params.queryType}&ownerType=${params.ownerType}`,
    {
      method: 'GET'
    }
  );
}
// 添加对象实例订阅
export async function postSubscription(params) {
  return request(
    `${config.domain2}/api/metadata/objects/${
      params.instanceName
    }/subscriptions`,
    {
      method: 'POST',
      body: {
        ...params
      }
    }
  );
}
// 更新对象实例
export async function updateObjectInstance(params) {
  return request(`${config.domain2}/api/metadata/objects/${params.name}`, {
    method: 'PUT',
    body: {
      ...params,
      description: params.description,
      modelLabels: params.modelLabels
    }
  });
}

// 获取该对象实例上的所有绑定信息
export async function queryObjectPropertyBinding(params) {
  return request(
    `${config.domain5}/api/metadata/objects/${params.bindingName}/bindings`
  );
}

// 为指定名称的对象实例添加新的绑定
export async function addObjectPropertyBinding(params) {
  return request(
    `${config.domain5}/api/metadata/objects/${params.objName}/bindings`,
    {
      method: 'POST',
      body: {
        source: `${params.sourceName}/${params.sourceProp}`, // objName: 对象名称， propName: 对象属性
        target: params.propName
      }
    }
  );
}

// 查询对象属性
export async function queryObjectProperty(params) {
  return request(
    `${config.domain}/api/metadata/objects/${params.name}/properties`
  );
}

// 删除对象属性
export async function removeObjectProperty(params) {
  return request('/api/metadata/objectProperty', {
    method: 'POST',
    body: {
      ...params
    }
  });
}

// 新增对象属性
export async function addObjectProperty(params) {
  return request(
    `${config.domain}/api/metadata/objects/${
      params.objectInstanceName
    }/properties`,
    {
      method: 'POST',
      body: {
        ...params
      }
    }
  );
}

// 查询用户列表
export async function queryUserList(params) {
  return request(`${config.domain3}/api/auth/users?${stringify(params)}`, {
    method: 'GET'
  });
}

// 根据用户名称查询用户信息
export async function queryUserListByName(params) {
  return request(`${config.domain3}/api/auth/users/queryUsersByName/${params.username}`, {
    method: 'GET'
  });
}

// 用户管理界面的删除
export async function removeUser(params) {
  return request(`${config.domain3}/api/auth/users/${params.username}`, {
    method: 'DELETE'
  });
}

// 用户管理界面的批量删除
export async function batchRemoveUser(params) {
  return request(`${config.domain3}/api/auth/users`, {
    method: 'DELETE',
    body: {
      ...params
    }
  });
}

// 用户管理界面的增加
export async function addUser(params) {
  // return request('/api/auth/user/addUser', {
  return request(`${config.domain3}/api/auth/users`, {
    method: 'POST',
    body: {
      ...params
    }
  });
}

// 用户管理获取当前数值
export async function checkUserDataAuthority(params) {
  return request(`${config.domain3}/api/auth/users/checkUserDataAuthority`, {
    method: 'POST',
    body: {
      ...params
    }
  });
}

// 用户管理获取当前数值
export async function queryUserByUserName(params) {
  return request(`${config.domain3}/api/auth/users/${params.username}`);
}

// 用户管理的修改
export async function editUser(params) {
  return request(`${config.domain3}/api/auth/users/${params.username}`, {
    method: 'PUT',
    body: {
      ...params
    }
  });
}

// 查询角色信息列表
export async function queryRoleList(params) {
  return request(`${config.domain3}/api/auth/roles?roleName=${params.roleName}&pageIndex=${params.pageIndex}`, {
    method: 'GET'
  });
}

export async function newQueryRoleList(params) {
  return request(`${config.domain3}/api/auth/roles?keyword=${params.roleName}&pageIndex=${params.pageIndex}`, {
    method: 'GET'
  });
}

// /users/queryUsersByRoleId/
export async function queryRoleUser(params) {
  return request(`${config.domain3}/api/auth/users/queryUsersByName/${params.name}`, {
    method: 'GET'
  });
}

// 查询设备信息列表 ${config.domain3}
export async function queryDeviceList(params) {
  return request(`${config.domain3}/api/auth/devices?fieldValue=${params.fieldValue}&fieldName=${params.fieldName}&sortMode=${params.sortMode}`, {
    method: 'GET'
  });
}

// 设备接入管理注册
export async function addDevice(params) {
  return request(`${config.domain2}/api/auth/devices`, {
    method: 'POST',
    body: {
      ...params
    }
  });
}
// 设备接入管理删除
export async function deleteDevice(params) {
  return request(`${config.domain2}/api/auth/devices/${params.id}`, {
    method: 'DELETE'
  });
}

// 设备接入管理删除
export async function deleteDeviceRelation(params) {
  return request(`${config.domain2}/api/auth/devices/removeRelationInfoById/${params.id}`, {
    method: 'DELETE'
  });
}

// 设备接入管理修改 /devices/deleteDeviceRelationById
export async function putDevice(params) {
  return request(`${config.domain2}/api/auth/devices/${params.id}`, {
    method: 'PUT',
    body: {
      ...params
    }
  });
}

// 确认接入设备信息
export async function confirmDevice(params) {
  return request(`${config.domain3}/api/auth/devices/confirmClientInfo`, {
    method: 'POST',
    body: {
      ...params
    }
  });
}
// 采集站管理列表
export async function manageDeviceList(params) {
  return request(`${config.domain3}/api/auth/queryDeviceRelations?fieldValue=${params.fieldValue}&fieldName=${params.fieldName}&sortMode=${params.sortMode}`, {
    method: 'GET'
  });
}
// 得到对应的token
export async function Idticket(params) {
  return request(`${config.domain2}/api/metadata/objects?templateName=RemoteCollector&deepTraverse=true&resultType=true&propName=token&propDefaultName=${params}`, {
    method: 'GET'
  });
}
// 得到采集站的详细信息
export async function DeviceDetails(params) {
  return request(`${config.domain2}/api/runtime/objects/${params}/services/getPropertyVQTValue`, {
    method: 'POST',
    body: {
      propName: 'online'
    }
  });
}
// 得到采集站的驱动信息
export async function Drivers(params) {
  return request(`${config.domain2}/api/runtime/objects/${params.deviceName}/services/getPropertyVQTValue`, {
    method: 'POST',
    body: {
      propName: 'drivers'
    }
  });
}
// 客户端注销
export async function Devicel(params) {
  return request(`${config.domain2}/api/auth/devices/${params}`, {
    method: 'DELETE'
  });
}

// 通过名称查询客户端注册信息
export async function DevicelDetailByName(params) {
  return request(`${config.domain2}/api/auth/devices/queryRegInfoByName?deviceName=${params.deviceName}`);
}

/** ---------------------------------------------------------对象实例 服务更新 ------------------------------------ -------------*/
// 批量对象实例服务管理数据 【格式如：/api/objects?name=name2】
export async function queryServiceManager(params) {
  return request(
    `${config.domain2}/api/metadata/objects/${params.name}/services`
  );
  // return request(`http://192.168.10.63:8080/api/objects?${stringify(params)}`);
}

// 对象实例服务管理单个数据的删除
export async function queryServiceRemove(params) {
  return request(
    `${config.domain2}/api/metadata/objects/${params.objName}/services/${
      params.serviceName
    }`,
    {
      method: 'DELETE'
    }
  );
}
// 对象实例服务管理批量删除
export async function queryServiceRemoves(params) {
  return request(
    `${config.domain2}/api/metadata/objects/${params.name}/services/delete`,
    {
      method: 'POST',
      body: {
        names: params.names
      }
    }
  );
}
// 对象实例服务管理我的服务增加
export async function queryServiceAdd(params) {
  return request(
    `${config.domain2}/api/metadata/objects/${params.objName}/services`,
    {
      method: 'POST',
      body: {
        ...params
      }
    }
  );
}

// 对象实例服务管理我的服务基本信息修改
export async function queryServiceEdit(params) {
  return request(
    `${config.domain2}/api/metadata/objects/${params.objName}/services/${
      params.serviceName
    }`,
    {
      method: 'PUT',
      body: {
        ...params
      }
    }
  );
}

// 对象实例服务管理我的服务基本信息修改
export async function queryServiceDetail(params) {
  return request(
    `${config.domain2}/api/metadata/objects/${params.objName}/services/${
      params.serviceName
    }`
  );
}

// 对象实例服务管理我的服务输入的新增
export async function queryServiceImport(params) {
  return request(
    `${config.domain2}/api/metadata/objects/${params.objName}/services/${
      params.serviceName
    }/inputs`,
    {
      method: 'POST',
      body: {
        ...params
      }
    }
  );
}

// 对象实例服务管理我的服务输入的修改
export async function queryImportEdit(params) {
  return request(
    `${config.domain2}/api/metadata/objects/${params.objName}/services/${
      params.serviceName
    }/inputs/${params.inputsName}`,
    {
      method: 'PUT',
      body: {
        ...params
      }
    }
  );
}
// 对象实例服务管理 我的输入单个的删除
export async function queryImportRemove(params) {
  return request(
    `${config.domain2}/api/metadata/objects/${params.objName}/services/${
      params.serviceName
    }/inputs/${params.inputName}`,
    {
      method: 'DELETE',
      body: {
        ...params
      }
    }
  );
}


// 对象实例服务管理我的服务输出的修改
export async function queryOutputEdit(params) {
  return request(
    `${config.domain2}/api/metadata/objects/${params.objName}/services/${
      params.serviceName
    }/out`,
    {
      method: 'PUT',
      body: {
        ...params
      }
    }
  );
}

// 列表-对象实例-我的父级服务
export async function objectParentServiceManager(params) {
  return request(`${config.domain2}/api/metadata/objects/${params.name}/parentServices`);
  // return request(`http://192.168.10.63:8080/api/objects?${stringify(params)}`);
}

/** ---------------------------------------------------------对象实例 服务更新 ------------------------------------ -------------*/

// 批量查询对象模板服务管理数据 【格式如：/api/objects?name=name2】
// export async function temServiceManager(params) {
//   return request(
//     `${config.domain2}/api/metadata/object_templates/${params.name}/services`
//   );
//   // return request(`http://192.168.10.63:8080/api/objects?${stringify(params)}`);
// }

// 对象模板服务管理单个数据的删除
export async function temServiceRemove(params) {
  return request(`${config.domain2}/api/metadata/objects_templates/${params.temName}/services/${params.serviceName}`, {
    method: 'DELETE'
    // body: {
    //   name: params.temName,
    //   serviceName: params.serviceName
    // }
  });
}
// 对象模板服务管理我的服务增加
export async function temServiceAdd(params) {
  return request(
    `${config.domain2}/api/metadata/object_templates/${
      params.temName
    }/services`,
    {
      method: 'POST',
      body: {
        ...params
      }
    }
  );
}

// 对象模板服务管理我的服务基本信息修改
export async function temServiceEdit(params) {
  return request(`${config.domain2}/api/metadata/objects_templates/${params.temName}/services/${params.serviceName}`, {
    method: 'PUT',
    body: {
      ...params,
      description: params.description,
      script: params.script
    }
  });
}

// 对象模板服务管理获取我的服务输入列表
export async function temImportList(params) {
  return request(`${config.domain2}/api/metadata/objects_templates/${params.temName}/services/${params.serviceName}`, {

  });
}

// 对象模板服务管理获取我的服务输出列表
export async function temOutputList(params) {
  return request(`${config.domain2}/api/metadata/objects_templates/${params.temName}/services/${params.serviceName}`, {

  });
}

// 对象模板服务管理我的服务输入的新增
export async function temServiceImport(params) {
  return request(`${config.domain2}/api/metadata/objects_templates/${params.temName}/services/${params.serviceName}/inputs`, {
    method: 'POST',
    body: {
      ...params
    }
  });
}

// 对象模板服务管理我的服务输入的修改
export async function temImportEdit(params) {
  return request(`${config.domain2}/api/metadata/objects_templates/${params.temName}/services/${params.serviceName}/inputs/${params.inputsName}`, {
    method: 'PUT',
    body: {
      ...params
    }
  });
}

// 对象模板服务管理 我的输入单个的删除
export async function temImportRemove(params) {
  return request(`${config.domain2}/api/metadata/objects_templates/${params.temName}/services/${params.serviceName}/inputs/${params.inputName}`, {
    method: 'DELETE',
    body: {
      ...params
    }
  });
}
// 对象模板服务管理 批量删除
export async function temImportRemoves(params) {
  return request(`${config.domain2}/api/metadata/object_templates/${params.name}/services/delete`, {
    method: 'POST',
    body: {
      names: params.names
    }
  });
}

// 对象模板服务管理我的服务输出的修改
export async function temOutputEdit(params) {
  return request(`${config.domain2}/api/metadata/objects_templates/${params.temName}/services/${params.serviceName}/out`, {
    method: 'PUT',
    body: {
      ...params
    }
  });
}

// 列表-对象模板-我的父级服务
// export async function temParentServiceManager(params) {
//   return request(`${config.domain2}/api/metadata/object_templates/${params.name}/parentServices`);
//   // return request(`http://192.168.10.63:8080/api/objects?${stringify(params)}`);
// }


// 对象实例我的血缘关系
export async function queryBloodList(params) {
  return request(
    `${config.domain8}/api/metadata/objects/${params.name}/bloodRelationship`,
    {}
  );
}

// 对象模板我的血缘关系
export async function queryBloodParent(params) {
  return request(
    `${config.domain8}/api/metadata/object_template/${
      params.name
    }/bloodRelationship`,
    {}
  );
}
// /object_templates/{name}/events查询对象模板事件列表
export async function queryTemplateEvents(params) {
  return request(
    `${config.domain2}/api/metadata/object_templates/${
      params.templateName
    }/events`
  );
}
// /object_templates/{name}/events/{eventName}获取指定名称的对象模板事件详细元数据
export async function getTemplateEvent(params) {
  return request(
    `${config.domain2}/api/metadata/object_templates/${
      params.templateName
    }/events/${params.eventName}`
  );
}
// /object_templates/{name}/events/{eventName}修改对象实例的事件
export async function putTemplateEvent(params) {
  return request(
    `${config.domain2}/api/metadata/object_templates/${
      params.templateName
    }/events/${params.eventName}`,
    {
      method: 'PUT',
      body: {
        ...params
      }
    }
  );
}
// object_templates/{name}/events 新增对象模板事件
export async function postTemplateEvent(params) {
  return request(
    `${config.domain2}/api/metadata/object_templates/${
      params.templateName
    }/events`,
    {
      method: 'POST',
      body: {
        ...params
      }
    }
  );
}
// object_templates/{name}/events/{eventName} 删除对象模板中的事件
export async function deleteTemplateEvent(params) {
  return request(
    `${config.domain2}/api/metadata/object_templates/${
      params.templateName
    }/events/${params.eventName}`,
    {
      method: 'DELETE'
    }
  );
}
// 批量删除对象模板中事件
export async function deleteTemplateEvents(params) {
  return request(
    `${config.domain2}/api/metadata/object_templates/${params.templateName}/events/delete`,
    {
      method: 'POST',
      body: {
        names: params.names
      }
    }
  );
}
// 批量删除对象模板中的事件
export async function removeTemplateEvents(params) {
  return request(
    `${config.domain2}/api/metadata/object_templates/${
      params.templateName
    }/events`,
    {
      method: 'DELETE',
      body: {
        ...params
      }
    }
  );
}
// 查询对象父模板的事件
export async function getParentEvents(params) {
  return request(
    `${config.domain2}/api/metadata/object_templates/${
      params.templateName
    }/parentEvents`
  );
}
// 获取数据集合管理列表
export async function queryDataStructManager(params) {
  const keyWord = params.keyWord ? `&keyWord=${encodeURIComponent(params.keyWord)}` : '';
  return request(`${config.domain2}/api/metadata/datastructs?page=${
    params.page || 1
  }&per_page=${
    params.per_page || 20000
  }&visible=${
    params.visible || 'true'
  }&systemProperty=${
    params.systemProperty || 'true'
  }${keyWord}`);
}
// 新增一条数据集合
export async function postDataStructManager(params) {
  return request(`${config.domain2}/api/metadata/datastructs`, {
    method: 'POST',
    body: {
      ...params
    }
  });
}
// 单条数据集合详情
export async function getDetailDataStruct(params) {
  return request(
    `${config.domain2}/api/metadata/datastructs/${params.structName}`
  );
}
// 修改一条数据集合
export async function putDataStructManager(params) {
  return request(
    `${config.domain2}/api/metadata/datastructs/${params.structName}`,
    {
      method: 'PUT',
      body: {
        ...params
      }
    }
  );
}
// 删除一条数据集合
export async function deleteDataStructManager(params) {
  return request(
    `${config.domain2}/api/metadata/datastructs/${params.structName}`,
    {
      method: 'DELETE'
    }
  );
}

// 批量 删除数据集合
export async function deleteDataStructManagerList(params) {
  return request(
    `${config.domain2}/api/metadata/datastructs/delete`,
    {
      method: 'POST',
      body: {
        names: params.names
      }
    }
  );
}
// 为数据集合新增一个域
export async function postDataStructFields(params) {
  return request(
    `${config.domain2}/api/metadata/datastructs/${params.structName}/fields`,
    {
      method: 'POST',
      body: {
        ...params
      }
    }
  );
}
// 查询数据集合域列表
export async function queryDataStructFields(params) {
  return request(
    `${config.domain2}/api/metadata/datastructs/${params.structName}/fields`
  );
}
// 查询单条数据集合域详情
export async function getDataStructField(params) {
  return request(
    `${config.domain2}/api/metadata/datastructs/${params.structName}/fields/${
      params.fieldsName
    }`
  );
}
// 修改一条数据集合的域
export async function putDataStructField(params) {
  return request(
    `${config.domain2}/api/metadata/datastructs/${params.structName}/fields/${
      params.fieldsName
    }`,
    {
      method: 'PUT',
      body: {
        ...params
      }
    }
  );
}
// 删除一条数据集合的域
export async function deleteDataStructField(params) {
  return request(
    `${config.domain2}/api/metadata/datastructs/${params.structName}/fields/${
      params.fieldsName
    }`,
    {
      method: 'DELETE'
    }
  );
}

// 删除多条数据集合的域
export async function deleteDataStructFieldList(params) {
  return request(
    `${config.domain2}/api/metadata/datastructs/${params.structName}/fields/delete`,
    {
      method: 'POST',
      body: {
        names: params.names
      }
    }
  );
}

// 功能集合相关接口

// 列表-功能集合
export async function getFeaturesList() {
  return request(
    `${config.domain2}/api/metadata/features`
  );
}
// 新增-功能集合
export async function addFeature(params) {
  return request(`${config.domain2}/api/metadata/features`, {
    method: 'POST',
    body: {
      ...params
    }
  });
}
// *************************************订阅对象/*******************************
// 列表-订阅对象
export async function getSubObjectList(params) {
  return request(
    `${config.domain2}/api/metadata/objects/${params.objName}/subscriptions?queryType=${params.queryType}&ownerType=${params.ownerType}`
  );
}
// 列表-报警对象
export async function getAlertObjectList(params) {
  return request(
    `${config.domain2}/api/metadata/objects/${params.objName}/properties/${params.propName}/alerts?ownerType=${params.ownerType}`
  );
}
// *************************************查询所有-属性/服务/事件/*******************
// 列表-对象实例-属性
export async function getAllObjectProps(params) {
  const propName = params.propName ? `&propName=${encodeURIComponent(params.propName)}` : '';
  return request(
    `${config.domain2}/api/metadata/objects/${params.objName}/properties?type=${
      params.type
    }&page=${
      params.page || 1
    }&per_page=${
      params.per_page || 20000
    }${propName}`
  );
}
// 列表-对象模板-属性
export async function getAllObjectTemplateProps(params) {
  return request(
    `${config.domain2}/api/metadata/object_templates/${params.name}/properties?type=${
      params.type
    }&page=${
      params.page || 1
    }&per_page=${
      params.per_page || 20000
    }`
  );
}
// 列表-对象实例-服务
export async function getAllObjectService(params) {
  return request(
    `${config.domain2}/api/metadata/objects/${params.objName}/services?type=${params.type}`
  );
}
// 列表-对象模板-服务
export async function getAllObjectTemplateService(params) {
  return request(
    `${config.domain2}/api/metadata/object_templates/${params.name}/services?type=${params.type}`
  );
}
// 列表-对象实例-事件
export async function getAllObjectEvent(params) {
  return request(
    `${config.domain2}/api/metadata/objects/${params.objName}/events?type=${params.type}`
  );
}
// 列表-对象模板-事件
export async function getAllObjectTemplateEvent(params) {
  return request(
    `${config.domain2}/api/metadata/object_templates/${params.name}/events?type=${params.type}`
  );
}
// 获取工厂生产架构列表
export async function getNetWorkList(params) {
  return request(`${config.domain2}/api/metadata/networks?page=${params.page}&per_page=${params.per_page}`);
}
// 删除一个network
export async function removeNetWork(params) {
  return request(`${config.domain2}/api/metadata/networks/${params.networkName}`, {
    method: 'DELETE'
  });
}
// 添加一个network
export async function addNetWork(params) {
  return request(`${config.domain2}/api/metadata/networks`, {
    method: 'POST',
    body: {
      ...params
    }
  });
}
// 修改一个network
export async function putNetWork(params) {
  return request(
    `${config.domain2}/api/metadata/networks/${params.networkName}`,
    {
      method: 'PUT',
      body: params
    }
  );
}
// 获取工厂生产架构树
export async function getNetworkTree(params) {
  return request(`${config.domain2}/api/metadata/networks/${params.networkName}?recursion=recursion`);
}
// 可用组件列表
export async function getNetworkObjects(params) {
  const keyWord = params.objName ? `&objName=${encodeURIComponent(params.objName)}` : '';
  return request(`${config.domain2}/api/metadata/networks/${params.networkName}/objects?page=${params.page}&per_page=${params.per_page}${keyWord}`);
}
// 新增跟节点/networks/{networkName}/organize
export async function addTreeNode(params) {
  return request(`${config.domain2}/api/metadata/networks/${params.networkName}/organize?objName=${params.objName}&parentObjName=${params.parentObjName}&networkOrder=${params.networkOrder}`, {
    method: 'POST',
    body: params
  });
}
// 修改节点关系 /networks/{networkName}/organize/{objName} parentObjName
export async function putTreeNode(params) {
  return request(
    `${config.domain2}/api/metadata/networks/${params.networkName}/organize/${params.objName}?parentObjName=${params.parentObjName}&networkOrder=${params.networkOrder}`,
    {
      method: 'PUT',
      body: params
    }
  );
}
// 删除一个节点/networks/{networkName}/organize/{objName}
export async function removeTreeNode(params) {
  return request(`${config.domain2}/api/metadata/networks/${params.networkName}/organize/${params.objName}`, {
    method: 'DELETE'
  });
}
// 获取父子节点 /networks/{networkName}/objects/{objName}
export async function getTreeNode(params) {
  return request(`${config.domain2}/api/metadata/networks/${params.networkName}/objects/${params.objName}`);
}
// ***********************************系统信息管理*********************************
// 获取默认页面信息api/config/homePage/default  ${config.domain2} http://10.30.52.63:8087
export async function queryDefaultPage() {
  return request(`${config.domain2}/api/config/homePage/default`);
}
// 工程备份/backup/{filename}
export async function projectBackup(params) {
  return request(`${config.domain2}/api/config/backup/${params.filename}`);
}
// 设置默认首页setDefaultPage /homePage/default
export async function setDefaultPage(params) {
  return request(
    `${config.domain2}/api/config/homePage/default`,
    {
      method: 'POST',
      body: params
    }
  );
}
// service列表
export async function queryServiceList() {
  return request(`${config.domain2}/api/config/service`);
}
// queryParsingState 配置文件解析状态查询 --  还原
export async function queryParsingState() {
  return request(`${config.domain2}/api/config/system/restore/status`);
}

// 配置报警颜色(保存)
export async function saveAlarmColor(data) {
  return request(`${config.domainMenu}/api/config/configInfo`, {
    method: 'POST',
    body: data
  });
}

// 配置报警颜色(获取)
export async function getAlarmColor() {
  return request(`${config.domainMenu}/api/config/configInfo/user_widget_alarm_color`, {
    method: 'GET'
  });
}

// *************************************绑定数据源*************************
// 获取绑定数据的值
export async function getRunTimeSource(params) {
  return request(`${config.domain2}/api/runtime/objects/${params.objName}/services/getPropertyValue`, {
    method: 'POST',
    body: {
      ...params
    }
  });
}
// 解绑
export async function removeBindingInfo(params) {
  return request(`${config.domain2}/api/metadata/objects/${params.objName}/bindings/${params.propName}`, {
    method: 'DELETE'
  });
}

// *************************************modelLabel-标签*************************
// 列表
export async function getModelLabelList(params) {
  const keyWord = params.keyWord ? `&keyWord=${encodeURIComponent(params.keyWord)}` : '';
  const modelLabelsType = params.modelLabelsType ? `&modelLabelsType=${encodeURIComponent(params.modelLabelsType)}` : '';
  return request(
    `${config.domain2}/api/metadata/modelLabels?page=${
      params.page || 1
    }&per_page=${
      params.per_page || 20000
    }${keyWord}${modelLabelsType}`
  );
}
// 新增
export async function addModelLabel(params) {
  return request(`${config.domain2}/api/metadata/modelLabels`, {
    method: 'POST',
    body: {
      ...params
    }
  });
}

// 详情
export async function getDetailModelLabel(params) {
  return request(
    `${config.domain2}/api/metadata/modelLabels/${params.labelName}`
  );
}
// 修改
export async function putModelLabel(params) {
  return request(
    `${config.domain2}/api/metadata/modelLabels/${params.labelName}`,
    {
      method: 'PUT',
      body: {
        ...params
      }
    }
  );
}
// 删除
export async function delModelLabel(params) {
  return request(
    `${config.domain2}/api/metadata/modelLabels/${params.labelName}`,
    {
      method: 'DELETE'
    }
  );
}
// 批量删除
export async function delModelLabelList(params) {
  return request(
    `${config.domain2}/api/metadata/modelLabels/delete`,
    {
      method: 'POST',
      body: {
        ...params
      }
    }
  );
}

// *************************************modelLabel-标签*************************

// *************************************modelLabelType-标签类型*************************
// 列表
export async function getModelLabelTypeList(params) {
  const keyWord = params.keyWord ? `&keyWord=${encodeURIComponent(params.keyWord)}` : '';
  return request(
    `${config.domain2}/api/metadata/modelLabelType?page=${
      params.page || 1
    }&per_page=${
      params.per_page || 20000
    }${keyWord}`
  );
}
// 新增
export async function addModelLabelType(params) {
  return request(`${config.domain2}/api/metadata/modelLabelType`, {
    method: 'POST',
    body: {
      ...params
    }
  });
}
// 修改
export async function putModelLabelType(params) {
  return request(
    `${config.domain2}/api/metadata/modelLabelType`,
    {
      method: 'PUT',
      body: {
        ...params
      }
    }
  );
}
// 删除
export async function delModelLabelType(params) {
  return request(
    `${config.domain2}/api/metadata/modelLabelType/${params.labelName}`,
    {
      method: 'DELETE'
    }
  );
}


// 批量删除
export async function delModelLabelTypeList(params) {
  return request(
    `${config.domain2}/api/metadata/modelLabelType/delete`,
    {
      method: 'POST',
      body: {
        ...params
      }
    }
  );
}

// *************************************modelLabelType-标签类型*************************


// *************************************unit-单位*************************
// 列表
export async function getUnitList(params) {
  const keyWord = params.keyWord ? `&keyWord=${encodeURIComponent(params.keyWord)}` : '';
  return request(
    `${config.domain2}/api/metadata/unit?page=${
      params.page || 1
    }&per_page=${
      params.per_page || 20000
    }&visible=${
      params.visible || true
    }${keyWord}`
  );
}
// 新增
export async function addUnit(params) {
  return request(`${config.domain2}/api/metadata/unit`, {
    method: 'POST',
    body: {
      ...params
    }
  });
}

// 修改
export async function updateUnit(params) {
  return request(
    `${config.domain2}/api/metadata/unit/${encodeURIComponent(params.preName)}`,
    {
      method: 'PUT',
      body: {
        ...params
      }
    }
  );
}
// 删除
export async function delUnit(params) {
  return request(
    `${config.domain2}/api/metadata/unit/${encodeURIComponent(params.name)}`,
    {
      method: 'DELETE'
    }
  );
}


// 批量删除
export async function batchUpdateUnit(params) {
  return request(
    `${config.domain2}/api/metadata/unit/batchUpdate/`,
    {
      method: 'PUT',
      body: {
        ...params
      }
    }
  );
}

// *************************************unit-单位*************************

export async function queryRule(params) {
  return request(`/api/rule?${stringify(params)}`);
}

export async function removeRule(params) {
  return request('/api/rule', {
    method: 'POST',
    body: {
      ...params,
      method: 'delete'
    }
  });
}

export async function addRule(params) {
  return request('/api/rule', {
    method: 'POST',
    body: {
      ...params,
      method: 'post'
    }
  });
}

// 列出真实的数据
export async function queryDatasource(params) {
  return request(`/api/metadata/datasource?${stringify(params)}`);
}

export async function fakeSubmitForm(params) {
  return request(`${config.domain9}/api/forms`, {
    method: 'POST',
    body: params
  });
}

export async function fakeChartData() {
  return request('/api/fake_chart_data');
}

export async function builderChartData() {
  return request('/api/builder_chart_data');
}

export async function queryTags() {
  return request('/api/tags');
}

export async function queryBasicProfile() {
  return request('/api/profile/basic');
}

export async function queryAdvancedProfile() {
  return request('/api/profile/advanced');
}

export async function queryFakeList(params) {
  return request(`/api/fake_list?${stringify(params)}`);
}

export async function fakeAccountLogin(params, isHiddenNote = true) {
  return request(`${config.domain10}/api/auth/users/login`, {
    method: 'POST',
    body: params
  }, isHiddenNote);
}

// 验证码登录
export async function fakeAccountSmsLogin(params, isHiddenNote = true) {
  return request(`${config.domain10}/api/auth/users/smsLogin`, {
    method: 'POST',
    body: params
  }, isHiddenNote);
}

export async function loginout() {
  return request(`${config.domain10}/api/auth/users/logout`, {
    method: 'POST'
  });
}

export async function getWidget() {
  return request('/api/widget');
}

export async function getPropertiesByWidgets(data) {
  return request('/api/getPropertiesByWidgets', {
    method: 'POST',
    body: data
  });
}

export async function getWidgetsGroup(data) {
  const { intl } = data;
  return [
    {
      name: 'chart',
      displayName: intl.formatMessage(commonMessage.chartRoom)
    },
    {
      name: 'form',
      displayName: intl.formatMessage(commonMessage.formRoom)
    },
    {
      name: 'control',
      displayName: intl.formatMessage(commonMessage.controlRoom)
    }
  ];
}

export async function getFetchWidget(data) {
  return request('/api/getFetchWidget', {
    method: 'POST',
    body: data
  });
}

// 组件库列表
export async function getWidgetsByGroup(data, intl) {
  const { groupName } = data;
  return allGroup(intl)[groupName];
}

export async function getHaloConfig() {
  return request('/api/widgetHalo');
}

export async function getInspectorConfig() {
  return request('/api/widgetInspector');
}


// 工业APP

// 编辑 内建页面树结构
export async function getPagesTree() {
  return request(`${config.domain10}/api/auth/getAllResourceTree`);
}

// 编辑 获取功能树结构
export async function getFoldersTree(data) {
  return request(`${config.domainIndustryApplication}/folders?parentId=${data.id}`);
}
// 编辑 树结构的改动
export async function updateFolderTree(data) {
  return request(`${config.domainIndustryApplication}/order`, {
    method: 'PUT',
    body: data
  });
}
// 编辑 添加文件夹
export async function addNewfolder(data) {
  return request(`${config.domainIndustryApplication}/folders`, {
    method: 'POST',
    body: data
  });
}
// 编辑 添加页面
export async function addNewpage(data) {
  return request(`${config.domainIndustryApplication}/pages`, {
    method: 'POST',
    body: data
  });
}
// 编辑 拷贝页面
export async function copyPage(data) {
  return request(`${config.domainIndustryApplication}/page/${data}/copy`, {
    method: 'POST',
    body: data
  });
}
// 编辑 修改目录
export async function editFolder(data) {
  return request(`${config.domainIndustryApplication}/folders/${data.id}`, {
    method: 'PUT',
    body: data
  });
}
// 编辑 修改页面
export async function editPage(data) {
  return request(`${config.domainIndustryApplication}/pages/${data.id}`, {
    method: 'PUT',
    body: data
  });
}

// 编辑 删除目录
export async function delFolder(data) {
  return request(`${config.domainIndustryApplication}/folders/${data.id}`, {
    method: 'DELETE'
  });
}
// 编辑 删除页面
export async function delPage(data) {
  return request(`${config.domainIndustryApplication}/pages/${data.id}`, {
    method: 'DELETE'
  });
}

//* *********** 工业APP folder page layout ******************
export async function queryIndustryApplication() {
  return request(
    `${config.domainIndustryApplication}/apps`
  );
}

export async function getIndustryApplication(data) {
  return request(`${config.domainIndustryApplication}/pages/${data.appId}`);
}

export async function removeIndustryApplication(data) {
  return request(`${config.domainIndustryApplication}/apps/${data.id}`, {
    method: 'DELETE'
  });
}

export async function addIndustryApplication(data) {
  return request(`${config.domainIndustryApplication}/apps`, {
    method: 'POST',
    body: data
  });
}

export async function updateIndustryApplication(data) {
  const URI = `${config.domainIndustryApplication}/apps/${data.id}`;
  delete data.id;
  return request(URI, {
    method: 'PUT',
    body: data
  });
}

export async function queryIndustryApplicationFolder(params) {
  return request(
    `${config.domainIndustryApplication}/folders?${stringify(params)}`
  );
}

export async function removeIndustryApplicationFolder(data) {
  return request(`${config.domainIndustryApplication}/folders/${data.id}`, {
    method: 'DELETE'
  });
}

export async function addIndustryApplicationFolder(data) {
  return request(`${config.domainIndustryApplication}/folders`, {
    method: 'POST',
    body: data
  });
}

export async function updateIndustryApplicationFolder(data) {
  const URI = `${config.domainIndustryApplication}/folders/${data.id}`;
  delete data.id;
  return request(URI, {
    method: 'PUT',
    body: data
  });
}

export async function queryIndustryApplicationPage(params) {
  return request(
    `${config.domainIndustryApplication}/pages?${stringify(params)}`
  );
}

export async function removeIndustryApplicationPage(data) {
  return request(`${config.domainIndustryApplication}/pages/${data.id}`, {
    method: 'DELETE'
  });
}

export async function addIndustryApplicationPage(data) {
  return request(`${config.domainIndustryApplication}/pages`, {
    method: 'POST',
    body: data
  });
}

export async function updateIndustryApplicationPage(data) {
  const URI = `${config.domainIndustryApplication}/pages/${data.id}`;
  const layout = Object.assign(JSON.parse(data.layout), { version: 'v1.1' });
  return request(URI, {
    method: 'PUT',
    body: {
      layout: JSON.stringify(layout)
    }
  });
}
//* *********** APP应用管理-静态资源 ******************

//* *********** APP应用管理-报警 folder page layout ******************
export async function alarmHisrtory(data) {
  return request(`${config.domain12}/objects/alert/history?${stringify(data)}`, {
    method: 'GET'
  });
}

export async function addNewFolderResource(data) {
  return request(`${config.domain11}/api/app/manager/images/folders`, {
    method: 'POST',
    body: data
  });
}

export async function foldersList(data) {
  return request(`${config.domain11}/api/app/manager/images/folders?${stringify(data)}`, {
    method: 'GET'
  });
}

export async function foldersImageList(data) {
  return request(`${config.domain11}/api/app/manager/images/files?${stringify(data)}`, {
    method: 'GET'
  });
}

export async function realAlarmList(data) {
  return request(`${config.domain12}/objects/alert/current?${stringify(data)}`, {
    method: 'GET'
  });
}

export async function queryRealAlarm(data) {
  return request(`${config.domain12}/objects/alert/ack`, {
    method: 'POST',
    body: data
  });
}

export async function ackUsersList() {
  return request(`${config.domain12}/objects/alert/ackUsers`, {
    method: 'GET'
  });
}

// 对象属性的详细信息
export async function queryPropertyBatchQuery(data) {
  return request(`${config.domainIndustryApplication}/objectdata/propertyBatchQuery`, {
    method: 'POST',
    body: data
  });
}

// 获取对象下所有属性打实时值
export async function queryBatchRealValue(data) {
  return request(`${config.domainIndustryApplication}/object/${data.objectName}/snapshot?type=${data.type}&page=${data.page}&per_page=${data.perPage}`, {
    method: 'GET'
  });
}

// 属性写值
export async function setPropVal(data) {
  const content = {
    propName: data.propName,
    propValue: data.propValue
  };
  return request(`${config.domain2}/api/runtime/objects/${data.objectName}/services/setPropertyValue`, {
    method: 'POST',
    body: content
  });
}

// ***************  布局相关
export async function queryIndustryApplicationLayouts(pageId) {
  return request(`${config.domainIndustryApplication}/pages/${pageId}`, {
    method: 'GET'
  });
}

export async function addIndustryApplicationLayout(data) {
  return request(`${config.domainIndustryApplication}/layouts`, {
    method: 'POST',
    body: data
  });
}

export async function removeIndustryApplicationLayout(id) {
  return request(`${config.domainIndustryApplication}/layouts/${id}`, {
    method: 'DELETE'
  });
}

export async function queryIndustryApplicationLayout(layoutId) {
  return request(`${config.domainIndustryApplication}/layouts/${layoutId}`, {
    method: 'GET'
  });
}

export async function updateIndustryApplicationLayout(data) {
  const context = Object.assign(JSON.parse(data.context), { version: 'v1.0' });
  return request(`${config.domainIndustryApplication}/layouts/${data.id}`, {
    method: 'PUT',
    body: {
      context: JSON.stringify(context)
    }
  });
}
// *****************************************

// 返回单列表的数据结果
export async function getSingleListData() {
  return [
    {
      key: 1001,
      propertyName: '温度',
      propertyKey: 'temperature',
      propertyType: 'int'
    },
    {
      key: 1002,
      propertyName: '转速',
      propertyKey: 'rollSpeed',
      propertyType: 'int'
    },
    {
      key: 1003,
      propertyName: '压力',
      propertyKey: 'pressure',
      propertyType: 'float'
    }
  ];
}
export async function allResourcesInfo(params) {
  // console.log("apiapipai"+params)
  return request(
    `${config.domain3}/api/auth/resourceIdsAndResourcesTree/userId/${params.userId}/resourceType/${params.resourceType}`,
    // `${config.domain3}/api/auth/queryResourceIdsAndResourcesTree/${params.userId}/${params.resourceType}`,
    {
      method: 'GET'
      // body: {
      //   ...params,
      //   method: 'delete'
      // }
    }
  );
}
export async function submmitChosenCheckBox(params) {
  return request(
    `${config.domain3}/api/auth/resourcesTreeByUserId`,
    {
      method: 'PUT',
      body: {
        ...params
      }
    }
  );
}

export async function getAuthMenu() {
  return request(`${config.domainMenu}/api/auth/userResourceTree?resourceType=system&resourceType=business&resourceType=app&resourceFunctionType=folder&resourceFunctionType=page`, {
    method: 'GET'
  });
}

// 上传图标
export async function uploadIcon(file) {
  const formData = new FormData();
  formData.append('file', file);

  return request(
    `${config.domain2}/api/image/upload/uploadImage`,
    {
      method: 'POST',
      body: formData,
      fetchType: 'file'
    }
  );
}

// 保存系统配置
export async function setConfigInfo(data) {
  return request(`${config.domain2}/api/config/configInfo`, {
    method: 'POST',
    body: data
  });
}

// 查询系统配置
export async function getConfigInfo(data) {
  return request(`${config.domain2}/api/config/configInfo/${data}`, {
    method: 'GET'
  });
}

// 查询登录页面图片
export async function getLoginImgs() {
  return request(`${config.domain2}/api/config/getImageUrl`, {
    method: 'GET'
  });
}

//* ********* 运行期配置信息  start **************
export async function getSingleRuntimeSetting(mkey) {
  return request(`${config.domain2}/api/auth/user/config/dashboard/${mkey}`, {
    method: 'GET'
  });
}

export async function getRuntimeSetting(mkeys) {
  return request(`${config.domain2}/api/auth/user/config/dashboard?${mkeys}`, {
    method: 'GET'
  });
}
export async function saveRuntimeSetting({ mkey, configInfo }) {
  const fields = JSON.stringify({ version: 'v1.0' });
  return request(`${config.domain2}/api/auth/user/config/dashboard`, {
    method: 'PUT',
    body: {
      mkey,
      configInfo,
      fields
    }
  });
}
//* ********* 运行期配置信息  start **************


//* ********* 系统设置的工程备份 start **************
// 备份
export async function engBackup(params) {
  return request(`${config.domain2}/api/config/system/backup`, {
    method: 'POST',
    body: params
  });
}

//* ********* 系统设置的工程备份 end **************

//* ********* 系统设置的密码设置 start **************

// 密码规则保存
export async function setPwdRule(params) {
  return request(`${config.domain10}/api/auth/passwd/settings`, {
    method: 'POST',
    body: params
  });
}

// 密码规则获取
export async function getPwdRule() {
  return request(`${config.domain10}/api/auth/passwd/settings`);
}

//* ********* 系统设置的密码设置 end**************

//* ********* 获取系统版本号 start **************
export async function getSystemVersion() {
  return request(`${config.domain2}/api/config/version`);
}
// 订制化，京博站内信报警弹窗ID查询固定对象实例，取code字段，Ethylbenzene
export async function getEthyPageID() {
  return request(`${config.domain2}/api/metadata/search/objects?page=1&per_page=200&resultType=false&visible=true&systemProperty=false&remote=false&keyWord=Ethylbenzene&scope=4`);
}

// 获取第三方集成的环境
export async function checkThirdPart() {
  return request(`${config.domain2}/api/auth/checkIntegration`);
}
