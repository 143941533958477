// import _ from 'lodash';
import { alarmHisrtory, realAlarmList, queryRealAlarm, ackUsersList } from '../services/api';

export default {
  namespace: 'alarmInfo',

  state: {
    alarmHisrtorySource: {
      list: [],
      pagination: {}
    },
    realAlarmSource: {
      list: []
    },
    ackUsersList: {
      list: []
    }
  },

  effects: {
    *fetchAlarmHisrtory({ payload, callback }, { call, put }) {
      const response = yield call(alarmHisrtory, payload);
      yield put({
        type: 'fetchAlarmHisrtoryList',
        payload: response
      });
      if (callback) callback(response);
    },
    *fetchRealAlarm({ payload, callback }, { call, put }) {
      const response = yield call(realAlarmList, payload);
      // const list = [];
      // for (let index = 0; index < 10000; index += 1) {
      //   const aa = {
      //     fullName: `PREc8Bind6_PREAlert6cd9f255-2576-4eb2-910a-702821fe63bf_c8doub_147${Math.random(0, 1) * 10000}`,
      //     status: 1,
      //     startDatatimestamp: 1534312253731,
      //     disappearedDatatimestamp: '',
      //     ackStatus: 0,
      //     ackDatatimestamp: '',
      //     ackUserName: null,
      //     alertName: `PREAlert6cd9f255-2576-4eb2-910a-702821fe63bf${Math.random(0, 1) * 10000}`,
      //     showName: 'showName',
      //     alertType: 'ge',
      //     priority: '10',
      //     source: 'PREc8Bind6',
      //     sourcePropertyName: 'c8doub_147',
      //     description: '',
      //     newValue: '99.0',
      //     oldValue: '50.0'
      //   };
      //   list.push(aa);
      // }
      // response.list = list;
      // response.list = [];
      yield put({
        type: 'fetchRealAlarmList',
        payload: response
      });
      if (callback) callback(response);
    },
    *queryRealAlarm({ payload, callback }, { call, put }) {
      const response = yield call(queryRealAlarm, payload);
      yield put({
        type: 'queryAlarm',
        payload: response
      });
      if (callback) callback(response);
    },
    *fetchAckUsers({ payload, callback }, { call, put }) {
      const response = yield call(ackUsersList, payload);
      yield put({
        type: 'fetchAckUsersList',
        payload: response
      });
      if (callback) callback(response);
    }
  },

  reducers: {
    fetchAlarmHisrtoryList(state, action) {
      return {
        ...state,
        alarmHisrtorySource: action.payload
      };
    },
    fetchRealAlarmList(state, action) {
      return {
        ...state,
        realAlarmSource: action.payload
      };
    },
    queryAlarm(state, action) {
      return {
        ...state,
        data: action.payload
      };
    },
    fetchAckUsersList(state, action) {
      return {
        ...state,
        ackUsersList: action.payload
      };
    }
  }
};
