// use localStorage to store the authority info, which might be sent from server in actual project.
import request from './request';

export function getAuthority() {
  return localStorage.getItem('antd-pro-authority');
}

export function setAuthority(authority) {
  return localStorage.setItem('antd-pro-authority', authority);
}

export function loginActions(loginDetails, loginRes) {
  remeberUserName(loginDetails.autoLogin, loginDetails.username);
  localStorage.setItem('ticket', loginRes.ticket);
  localStorage.setItem('loginMsg', JSON.stringify(loginRes));
  if (loginRes.status === 'firstLogin') {
    localStorage.setItem('isFirstLogin', 'yes');
  } else {
    localStorage.setItem('isFirstLogin', 'no');
  }
}

export function isAdmin(loginRes) {
  if (loginRes) {
    return loginRes.userId === 1 ? 'admin' : 'user';
  } else {
    return 'guest';
  }
}

function remeberUserName(autoLogin, username) {
  if (autoLogin) localStorage.setItem('suposUserName', username);
  else localStorage.removeItem('suposUserName');
}

// 订制化，登出erp
export function erpLogout() {
  request('http://111.59.135.189:8069/web/user/logout', {
    mode: 'cors',
    credentials: 'include'
  });
}
