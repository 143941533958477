import _ from 'lodash';
import commonMessage from 'root/common/messages';

/**
 * 命名规则注意：
 * name为菜单上的中文显示名称
 * icon为对应的图标
 * path为routes下的小写名称，使用小写加'-'横线符号，不要使用大小写的驼峰名称，尽量统一！
 * 只有根节点path
 * @type {*[]}
 */
function defaultMenu(intl) {
  return {
    id: 'personalInfo',
    name: intl.formatMessage(commonMessage.persenalInfoManage),
    icon: 'admin_nav',
    path: 'personal',
    parentId: ['personalInfo'],
    children: [
      // {
      //   id: 'baseInfo',
      //   name: intl.formatMessage(commonMessage.baseInfo),
      //   path: 'personal/personal-msg',
      //   parentId: ['personalInfo', 'baseInfo'],
      //   children: []
      // },
      {
        id: 'editPassword',
        name: intl.formatMessage(commonMessage.modifyPassword),
        path: 'personal/password',
        parentId: ['personalInfo', 'editPassword'],
        children: []
      }]
  };
}
function designDefaultMenu(intl) {
  return {
    id: 'personalInfo',
    name: intl.formatMessage(commonMessage.persenalInfoManage),
    icon: 'admin_nav',
    path: 'personal',
    parentId: ['personalInfo'],
    children: [
      // {
      //   id: 'baseInfo',
      //   name: intl.formatMessage(commonMessage.baseInfo),
      //   path: 'design/personal/personal-msg',
      //   parentId: ['personalInfo', 'baseInfo'],
      //   children: []
      // },
      {
        id: 'editPassword',
        name: intl.formatMessage(commonMessage.modifyPassword),
        path: 'design/personal/password',
        parentId: ['personalInfo', 'editPassword'],
        children: []
      }]
  };
}
function baseMenu(intl) {
  return [
    {
      id: 'collectionStation',
      name: intl.formatMessage(commonMessage.collectionStation),
      icon: 'collect_nav',
      path: 'collector',
      children: [{
        id: 'acceptManage',
        name: intl.formatMessage(commonMessage.acceptManage),
        path: 'collector/acceptm'
      }, {
        id: 'collectionMange',
        name: intl.formatMessage(commonMessage.statusManage),
        path: 'collector/manage'
      }]
    }, {
      id: 'mobileAuth',
      name: intl.formatMessage(commonMessage.mobileAuth),
      icon: 'mobile_auth',
      path: 'mobile-auth/auth-itemlist'
    }, {
      id: 'objectTemplateManage',
      name: intl.formatMessage(commonMessage.objectTemplateManage),
      icon: 'model_nav',
      path: 'object-template-mgr',
      children: [
        {
          id: 'objectTemplate',
          name: intl.formatMessage(commonMessage.objectTemplate),
          path: 'object-template-mgr/object-template'
        }, {
          id: 'objectInstance',
          name: intl.formatMessage(commonMessage.objectInstance),
          path: 'object-template-mgr/object-instance'
        }, {
          id: 'featureCollection',
          name: intl.formatMessage(commonMessage.featureCollection),
          path: 'object-template-mgr/feature-set-mgr'
        }, {
          id: 'modelLabelManage',
          name: intl.formatMessage(commonMessage.modelLabelManage),
          path: 'object-template-mgr/modelLabelType'
        }, {
          id: 'datastructManage',
          name: intl.formatMessage(commonMessage.datastructManage),
          path: 'object-template-mgr/data-struct-mgr'
        }
      ]
    }, {
      id: 'factoryModelingManage',
      name: intl.formatMessage(commonMessage.factoryModelingManage),
      icon: 'factory_nav',
      path: 'factory-modeling-mgr',
      children: [
        {
          id: 'factoryProductionArchitecture',
          name: intl.formatMessage(commonMessage.factoryProductionArchitecture),
          path: 'factory-modeling-mgr/factory-production-architecture'
        }]
    }, {
      id: 'companyOrgStructure',
      name: intl.formatMessage(commonMessage.companyOrgStructure),
      icon: 'organize_nav',
      path: 'network',
      children: [{
        id: 'staffMgr',
        name: intl.formatMessage(commonMessage.staffMgr),
        path: 'network/staff'
      }, {
        id: 'organizationsMgr',
        name: intl.formatMessage(commonMessage.organizationsMgr),
        path: 'network/organizations'
      // }, {
      //   id: 'departmentMgr',
      //   name: '部门管理',
      //   path: 'network/department'
      // }, {
      //   id: 'jobsMgr',
      //   name: '岗位管理',
      //   path: 'network/position'
      }]
    }, {
      id: 'bisDesigner',
      name: intl.formatMessage(commonMessage.bisDesigner),
      icon: 'reports_nav',
      path: 'industry-application-mgr/business-designer',
      children: [
        {
          id: 'uiDesigner',
          name: intl.formatMessage(commonMessage.uiDesigner),
          path: 'industry-application-mgr/business-designer/app_default'
        }, {
          id: 'workProcessInstanceManage',
          name: intl.formatMessage(commonMessage.workProcessInstanceManage),
          path: 'industry-application-mgr/business-designer/workprocess_instance_mgr'
        }, {
          id: 'todoInstanceManage',
          name: intl.formatMessage(commonMessage.todoInstanceManage),
          path: 'industry-application-mgr/business-designer/todo_instance_mgr'
        }
        // {
        //   id: 'logManage',
        //   name: intl.formatMessage(commonMessage.logManage),
        //   path: 'industry-application-mgr/business-designer/log_mgr'
        // }
      ]
    }, {
      id: 'appDesigner',
      name: intl.formatMessage(commonMessage.appDesigner),
      icon: 'apps_nav',
      path: 'industry-application-mgr/application-mgr',
      children: []
    },
    {
      id: 'industryBigData',
      name: intl.formatMessage(commonMessage.industryBigData),
      icon: 'bigData_nav',
      path: 'industry-big-data',
      children: [{
        id: 'samplesManage',
        name: intl.formatMessage(commonMessage.samplesManage),
        path: 'industry-big-data/samples-mgr'
      }, {
        id: 'experimentsManage',
        name: intl.formatMessage(commonMessage.experimentsManage),
        path: 'industry-big-data/experiments-mgr'
      }, {
        id: 'release',
        name: intl.formatMessage(commonMessage.release),
        path: 'industry-big-data/publish-mgr-release'
      }
      ]
    },
    {
      id: 'industryTaskScheduler',
      name: intl.formatMessage(commonMessage.industryTaskScheduler),
      icon: 'bigData_nav',
      path: 'industry-task-scheduler',
      children: [{
        id: 'experimentTask',
        name: intl.formatMessage(commonMessage.experimentTask),
        path: 'industry-task-scheduler/experiment-task'
      },
      {
        id: 'statisticsTask',
        name: intl.formatMessage(commonMessage.statisticsTask),
        path: 'industry-task-scheduler/statistics-task'
      },
      {
        id: 'reportStatistics',
        name: intl.formatMessage(commonMessage.reportStatistics),
        path: 'industry-task-scheduler/reportStatistics-task'
      },
      {
        id: 'realTimeData',
        name: intl.formatMessage(commonMessage.realTimeData),
        path: 'industry-task-scheduler/realtime-data'
      },
      {
        id: 'systemTask',
        name: intl.formatMessage(commonMessage.systemTask),
        path: 'industry-task-scheduler/system-task'
      },
      {
        id: 'machineVision',
        name: intl.formatMessage(commonMessage.machineVision),
        path: 'industry-big-data/machine-vision'
      }
      ]
    },
    {
      id: 'globalSetting',
      name: intl.formatMessage(commonMessage.globalSetting),
      icon: 'safe_nav',
      path: 'global-setting',
      children: [{
        id: 'sqlDataSource',
        name: intl.formatMessage(commonMessage.sqlDataSource),
        path: 'global-setting/sql-datasource'
      }]
    },
    {
      id: 'systemSecurityManage',
      name: intl.formatMessage(commonMessage.systemSecurityManage),
      icon: 'safe_nav',
      path: 'user-manage',
      children: [{
        id: 'userManage',
        name: intl.formatMessage(commonMessage.userManage),
        path: 'user-manage/user-itemlist'
      }, {
        id: 'roleManage',
        name: intl.formatMessage(commonMessage.roleManage),
        path: 'user-manage/role-itemlist'
      }, {
        id: 'authGroupManage',
        name: intl.formatMessage(commonMessage.authGroupManage),
        path: 'user-manage/data-auth'
      }, {
        id: 'operationLog',
        name: intl.formatMessage(commonMessage.operationLog),
        path: 'user-manage/operation-log'
      }, {
        id: 'userAccessLog',
        name: intl.formatMessage(commonMessage.userAccessLog),
        path: 'user-manage/user-access-log'
      }]
    },
    {
      id: 'notificationCenter',
      name: intl.formatMessage(commonMessage.notificationCenter),
      icon: 'icon_notice',
      path: 'notification-center',
      children: [
        {
          id: 'typeConfig',
          name: intl.formatMessage(commonMessage.typeConfig),
          path: 'notification-center/type-config'
        }, {
          id: 'logManage',
          name: intl.formatMessage(commonMessage.logManage),
          path: 'notification-center/log-manage'
        }, {
          id: 'msgManage',
          name: intl.formatMessage(commonMessage.msgManage),
          path: 'notification-center/msg-manage'
        }
      ]
    },
    {
      id: 'appMenuSetting',
      name: intl.formatMessage(commonMessage.appMenuSetting),
      icon: 'icon_notice',
      path: 'app-menu-setting'
    }
  ];
}

let hasSystemManage;
const formatterSystemMenu = (menu, authMenu, parentId = '') => {
  let system = _.map(menu, (baseItem, baseIndex) => {
    const authMenuItem = _.find(authMenu, (authItem) => {
      return authItem.resourceId === baseItem.id;
    });
    const systemInfo = _.find(authMenu, (authItem) => {
      return authItem.resourceId === 'systemInfoManage';
    });
    if (systemInfo && systemInfo.childResources && systemInfo.childResources.length &&
       systemInfo.childResources[0].resourceId === 'systemInfo') {
      hasSystemManage = true;
    }
    if (authMenuItem) {
      const tempItem = Object.assign({}, menu[baseIndex]);
      tempItem.path = `design/${tempItem.path}`;
      tempItem.parentId = _.concat(parentId || [], tempItem.id);
      tempItem.children = [];
      const { children } = baseItem;
      const { childResources } = authMenuItem;
      if (children && children.length && childResources && childResources.length) {
        tempItem.children = formatterSystemMenu(children, childResources, tempItem.parentId);
      }
      return tempItem;
    }
  });
  system = _.filter(system, sItem => sItem);
  return system;
};

const formatterAppMenu = (menu, parentId) => {
  const appItem = _.map(menu, (item) => {
    const { childResources, resource, resourceFunctionType, resourceCode, name, resourceId } = item;
    const { config = {} } = resource ? JSON.parse(resource) : {};
    let { menuDisplay } = config;
    menuDisplay = menuDisplay === undefined ? true : menuDisplay;
    let tempItem;
    if (resourceFunctionType === 'page') {
      let pathId;
      let linkUrl;
      if (resourceCode && resourceCode.indexOf('Page_') === 0) {
        pathId = resourceCode;
      } else {
        pathId = resourceId;
        try {
          linkUrl = JSON.parse(resource).url;
        } catch (error) {
          linkUrl = '';
        }
      }
      tempItem = {
        id: pathId,
        name,
        path: `application-runtime/${pathId}`,
        parentId: _.concat(parentId, pathId),
        url: linkUrl,
        children: [],
        menuDisplay
      };
      return tempItem;
    } else if (resourceFunctionType === 'folder' && childResources && childResources.length) {
      tempItem = {
        id: resourceCode,
        name,
        path: `application-runtime/${resourceCode}`,
        parentId: _.concat(parentId, resourceCode),
        children: [],
        menuDisplay
      };
      const children = _.filter(formatterAppMenu(childResources, tempItem.parentId), i => i);
      if (children.length) {
        tempItem.children = children;
        return tempItem;
      }
    }
  });
  return appItem;
};

const menuFormatter = (authMenu, intl) => {
  let systemMenu = [];
  let appMenu;
  let businessMenu;
  hasSystemManage = false;
  const [system, business, app] = authMenu;
  systemMenu = formatterSystemMenu(baseMenu(intl), system);
  appMenu = _.map(app, (item) => {
    const { childResources } = item;
    if (childResources && childResources.length) {
      const appItem = {
        id: item.resourceCode,
        name: item.name,
        icon: 'app_nav',
        path: `application-runtime/${item.resourceCode}`,
        parentId: [item.resourceCode],
        children: [],
        menuDisplay: true
      };
      const children = _.filter(formatterAppMenu(childResources, appItem.parentId), i => i);
      if (children.length) {
        appItem.children = children;
        return appItem;
      }
    }
  });
  appMenu = _.filter(appMenu, appItem => appItem);
  businessMenu = _.map(business, (item) => {
    const { childResources } = item;
    if (childResources && childResources.length) {
      const bussinessItem = {
        id: item.resourceCode,
        name: item.name,
        icon: 'report_controls',
        path: `application-runtime/${item.resourceCode}`,
        parentId: [item.resourceCode],
        children: []
      };
      const children = _.filter(formatterAppMenu(childResources, bussinessItem.parentId), i => i);
      if (children.length) {
        bussinessItem.children = children;
        return bussinessItem;
      }
    }
  });
  businessMenu = _.filter(businessMenu, businessItem => businessItem);

  if (businessMenu.length) {
    businessMenu = _.map(businessMenu[0].children, (businessItem) => {
      businessItem.icon = 'report_controls';
      return businessItem;
    });
  }


  const basicMenu = _.concat(businessMenu, appMenu, defaultMenu(intl));
  const designMenu = _.concat(systemMenu, designDefaultMenu(intl));
  localAuthMenu = { designFlatMenu: [], basicFlatMenu: [] };
  flatmenu(basicMenu, 'basicFlatMenu');
  flatmenu(designMenu, 'designFlatMenu');
  localStorage.setItem('basicFlatMenu', JSON.stringify(localAuthMenu.basicFlatMenu));
  if (hasSystemManage) {
    localAuthMenu.designFlatMenu.push('/design/system-info-mgr/system-info');
  }
  // localAuthMenu.designFlatMenu.push('/design/app-menu-setting');
  localStorage.setItem('designFlatMenu', JSON.stringify(localAuthMenu.designFlatMenu));
  return {
    basicMenu,
    designMenu,
    hasSystemManage
  };
};
let localAuthMenu = { designFlatMenu: [], basicFlatMenu: [] };
const flatmenu = (arr, key) => {
  _.map(arr, (item) => {
    if (item.children.length) {
      localAuthMenu[key].push(item.path);
      flatmenu(item.children, key);
    } else {
      localAuthMenu[key].push(item.path);
    }
  });
};

export default menuFormatter;
