import { message, notification } from 'antd';

document.addEventListener('webkitfullscreenchange', () => {
  popUpInFullscreen();
});

function popUpInFullscreen() {
  const containerElement = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement;
  const getContainer = () => containerElement || document.body;
  message.destroy();
  notification.destroy();
  message.config({
    getContainer
  });
  notification.config({
    getContainer
  });
}
