import {
  getPagesTree,
  getFoldersTree,
  updateIndustryApplication,
  addNewfolder,
  addNewpage,
  copyPage,
  editFolder,
  editPage,
  delFolder,
  delPage,
  updateFolderTree
} from '../services/api';

export default {
  namespace: 'industryApplicationDetail',
  state: {
    app: {},
    folders: [],
    tree: {},
    pageTree: {},
    pages: {
      list: [],
      pagination: {}
    }
  },

  effects: {
    *getPageTree({ payload }, { call, put }) {
      const response = yield call(getPagesTree, payload);
      yield put({
        type: 'queryPageTree',
        payload: response
      });
    },
    *getTree({ payload, callback }, { call, put }) {
      const response = yield call(getFoldersTree, payload);
      yield put({
        type: 'queryFolderTree',
        payload: response
      });
      if (callback) callback();
    },
    *updateTree({ payload, intl, callback }, { call, put }) {
      const response = yield call(updateFolderTree, payload);
      yield put({
        type: 'save',
        payload: response
      });
      yield put({
        type: 'authMenuPage/fetchAuthMenu',
        intl
      });
      if (callback) callback(response);
    },

    *update({ payload, intl, callback }, { call, put }) {
      const response = yield call(updateIndustryApplication, payload);
      yield put({
        type: 'save',
        payload: response
      });
      yield put({
        type: 'authMenuPage/fetchAuthMenu',
        intl
      });
      if (callback) callback();
    },

    *addFolders({ payload, callback }, { call, put }) {
      const response = yield call(addNewfolder, payload);
      yield put({
        type: 'save',
        paload: response
      });
      if (callback) callback();
    },
    *addPage({ payload, intl, callback }, { call, put }) {
      const response = yield call(addNewpage, payload);
      if (!(response && response.location)) return false;
      yield put({
        type: 'save',
        paload: response
      });
      yield put({
        type: 'authMenuPage/fetchAuthMenu',
        intl
      });
      const params = {
        type: 'addPage',
        pageId: response.location
      };
      if (callback) callback(params);
    },
    *copyPage({ payload, intl, callback }, { call, put }) {
      const response = yield call(copyPage, payload.id);
      if (!(response && typeof response === 'object' && response.location)) {
        return false;
      }
      yield put({
        type: 'save',
        paload: response
      });
      yield put({
        type: 'authMenuPage/fetchAuthMenu',
        intl
      });
      if (callback) callback();
    },

    *editFolder({ payload, intl, callback }, { call, put }) {
      const response = yield call(editFolder, payload);
      yield put({
        type: 'save',
        paload: response
      });
      yield put({
        type: 'authMenuPage/fetchAuthMenu',
        intl
      });
      if (callback) callback();
    },

    *editPage({ payload, intl, callback }, { call, put }) {
      const response = yield call(editPage, payload);
      if (response.code !== '201') return;
      yield put({
        type: 'industryApplicationLayout/updatePageInfo',
        payload
      });
      yield put({
        type: 'authMenuPage/fetchAuthMenu',
        intl
      });
      if (callback) callback();
    },

    *delFolder({ payload, intl, callback }, { call, put }) {
      const response = yield call(delFolder, payload);
      yield put({
        type: 'save',
        paload: response
      });
      yield put({
        type: 'authMenuPage/fetchAuthMenu',
        intl
      });
      if (callback) callback();
    },

    *delPage({ payload, intl, callback }, { call, put }) {
      const response = yield call(delPage, payload);
      yield put({
        type: 'save',
        paload: response
      });
      yield put({
        type: 'authMenuPage/fetchAuthMenu',
        intl
      });
      if (callback) callback();
    }
  },

  reducers: {
    initState() {
      return {
        app: {},
        folders: [],
        tree: {},
        pageTree: {},
        pages: {
          list: [],
          pagination: {}
        }
      };
    },
    save(state, action) {
      return {
        ...state,
        app: action.payload
      };
    },
    queryPageTree(state, action) {
      return {
        ...state,
        pageTree: action.payload
      };
    },
    queryFolderTree(state, action) {
      return {
        ...state,
        tree: action.payload || {}
      };
    }
  }
};
