/**
 * app 文件管理
 */
import { stringify } from 'qs';

import request from '../utils/request';
import config from '../config';
// 第一次查询第一层级目录和文件
export async function fetchFirst(params = {}) {
  return request(`${config.domain11}/api/app/resource/fileAndfolder?${stringify(params)}`, {
    method: 'GET'
  });
}

// 指定目录查询文件夹列表
export async function fetchFolderList(params = {}) {
  return request(`${config.domain11}/api/app/resource/folder?${stringify(params)}`, {
    method: 'GET'
  });
}

// 指定目录查询文件列表
export async function fetchFileList(params = {}) {
  return request(`${config.domain11}/api/app/resource/file?${stringify(params)}`, {
    method: 'GET'
  });
}

// 新增 文件夹
export async function addNewFolder(params = {}) {
  return request(`${config.domain11}/api/app/resource/folder`, {
    method: 'POST',
    body: {
      ...params
    }
  });
}

// 编辑 文件夹
export async function editFolder(params = {}) {
  return request(`${config.domain11}/api/app/resource/folder`, {
    method: 'PUT',
    body: {
      ...params
    }
  });
}

// 删除 文件夹或者文件
export async function deleteObj(params = {}) {
  return request(`${config.domain11}/api/app/resource/fileAndfolder?${stringify(params)}`, {
    method: 'DELETE'
  });
}

