import { defineMessages } from 'react-intl';

export default defineMessages({
  today: '今天',
  thismonth: '本月',
  thisyear: '今年',
  startDate: '开始日期',
  endDate: '结束日期',
  pimsPage: 'PIMS页面',
  noData: '无数据',
  addCustomProps: '请先添加自定义属性',
  set: '设置',
  linked: '链接',
  linkCustom: '链接自定义属性',
  seconds: '秒',
  selectDateTime: '请选择日期时间',
  selectDate: '请选择日期',
  dateTip: '该项为必填',
  selectMonth: '请选择月份',
  selectYear: '请选择年份',
  showTitle: '显示标题栏',
  cancelPermission: '取消权限',
  scrollbarBackground: '滚动条背景',
  uploadDone: '上传完成',
  historicalAlarm: '历史报警',
  timeRange: '开启时间范围选择',
  emailAddressPh: '请输入正确的邮箱地址',
  telephonePh: '请输入正确的手机号码',
  collection: '收藏本页',
  help: '帮助',
  loginError: '账户或密码错误',
  userlogin: '用户登录',
  enterUsername: '请输入用户名',
  enterPw: '请输入密码',
  remember: '记住用户名',
  SSLlogin: 'SSL安全登录',
  login: '登录',
  modifySuc: '修改成功',
  uploadRule: '请上传小于5M的JPG、GIF或PNG文件',
  eMail: '邮箱地址:',
  entereMail: '请输入邮箱地址',
  eMailTip: '请输入正确的邮箱',
  save: '保存',
  cancel: '取消',
  edit: '编辑',
  phoneNo: '手机号码:',
  enterPhoneNo: '请输入手机号码',
  phoneNoTip: '请输入正确的电话号码',
  upload: '上传',
  personalInfo: '个人信息:',
  username: '用户名:',
  timezone: '时区:',
  sculpture: '修改头像',
  ok: '确定',
  localUpload: '本地上传',
  localUploadRule: '请上传小于5M的JPG、GIF或PNG文件；该头像会对外显示，请您留意不要上传私密头像。',
  pwNoSame: '两次输入的密码不一样',
  nowPw: '现用密码',
  enterNowPw: '请输入您的现用密码',
  newPw: '新密码',
  enterNewPw: '请输入您的新密码',
  confirmPw: '确认密码',
  enterConfirmPw: '请输入您的确认密码',
  persenalInfoManage: '个人信息管理',
  baseInfo: '个人信息',
  modifyPassword: '密码修改',
  collectionStation: '采集节点管理',
  acceptManage: '鉴权管理',
  statusManage: '状态管理',
  mobileAuth: '移动设备鉴权',
  objectTemplateManage: '对象模型管理',
  objectTemplate: '对象模板',
  objectInstance: '对象实例',
  featureCollection: '功能集合',
  modelLabelManage: '标签管理',
  datastructManage: '数据集合',
  factoryModelingManage: '工厂建模管理',
  factoryProductionArchitecture: '工厂模型',
  companyOrgStructure: '企业组织架构',
  staffMgr: '人员管理',
  organizationsMgr: '组织管理',
  bisDesigner: '业务设计器',
  uiDesigner: '界面设计',
  workProcessInstanceManage: '流程实例',
  todoInstanceManage: '任务实例',
  appDesigner: 'APP设计器',
  industryBigData: '大数据分析',
  samplesManage: '样本集管理',
  experimentsManage: '实验管理',
  release: '部署管理',
  industryTaskScheduler: '计算任务',
  experimentTask: '实验任务',
  statisticsTask: '统计任务',
  reportStatistics: '实时统计任务',
  realTimeData: '实时数据转储',
  systemTask: '系统任务',
  globalSetting: '全局配置',
  sqlDataSource: '关系库数据源',
  systemSecurityManage: '用户安全管理',
  userManage: '用户管理',
  roleManage: '角色管理',
  authGroupManage: '数据分组管理',
  operationLog: '操作记录',
  userAccessLog: '用户访问日志',
  welcome: '欢迎回来',
  rebackFail: '还原失败',
  rebackSuc: '还原成功',
  backupRule: '备份文件小于10K，请重新选择备份文件',
  idle: '空闲',
  setNoModify: '没有执行过系统配置修改操作，请点击工程还原按钮上传文件',
  analysis: '解析中',
  setAnalysising: '上一次上传配置文件后台正在解析中，解析完成后可以再次执行上传操作',
  success: '成功',
  setSuccess: '上一次上传配置文件已解析成功，可以再次上传更改配置',
  analysisFail: '解析失败',
  setFail: '上一次上传的配置文件解析失败，请重nextProps.formData;配置文件',
  imgUploadSuc: '图片上传成功',
  imgUploadFail: '图片上传失败',
  enterUpload: '请上传图片',
  copyright: '© 版权所有 {min}-{max}',
  SMTPserverRule: '请输入SMTP邮件服务器',
  SMTPportRule: '请输入SMTP端口',
  protocolRule: '请输入邮件协议',
  emailAddressRule: '请输入发送邮箱地址',
  emailPasswordRule: '请输入发送邮箱密码',
  loginSetting: '登录页配置',
  copyrightMark: '版权标记',
  background: '背景',
  slogan: '标语',
  reset: '重置',
  emaiLServer: '邮件服务器配置',
  SMTPServer: 'SMTP邮件服务器',
  SMTPport: 'SMTP端口',
  emailProtocol: '邮件协议',
  sendAddress: '发送邮箱地址',
  sendPassword: '发送邮箱密码',
  globalFuncSetting: '全局函数配置',
  baseScript: '流程图展示设备基本信息脚本',
  uploadImg: '上传图片',
  choosePage: '请先选择一个页面',
  rebacking: '正在还原',
  uploadFail: '{name}上传失败',
  backupFail: '备份失败',
  backupError: '备份出错',
  name: '名称',
  alias: '别名',
  desc: '描述',
  operate: '操作',
  brodertype: '边框类型',
  delete: '删除',
  objectSelect: '对象选择器',
  projectBackup: '工程备份',
  directBackup: '直接备份',
  includeDataTable: '含DataTable数据',
  dataTableBackUpTips: 'Datatable数据量过大时，请采用其他方式转移数据。',
  backuping: '工程备份中，请稍候...',
  backupSuccess: '备份成功',
  systemManage: '系统信息管理',
  defaultPage: '默认首页',
  backupManage: '备份管理',
  downloadBackup: '下载备份',
  projectReback: '工程还原',
  priority: '优先级',
  color: '颜色',
  value: '值',
  priorityValue: '优先级{text}',
  alarmColor: '配置报警颜色',
  alarmExample: '报警示例',
  passwordRule: '密码规则保存成功',
  pwcomplicated: '密码复杂度',
  assembleRule: '组合规则',
  case: '大小写',
  number: '数字',
  specialCharacter: '特殊字符',
  pwLength: '密码长度',
  enterMinLength: '请输入最小长度',
  equalThan: '请输入大于等于{num}的整数',
  minThanMax: '最小长度必须小于最大长度',
  minNumber: '最小为{num}',
  enterMaxLength: '请输入最大长度',
  maxThanMax: '最大长度必须大于最大长度',
  representation: '表达式',
  regular: '正则表达式',
  enterRegular: '请输入正则表达式',
  example: '例: {text}',
  checkTip: '校验提示语',
  enterCheckTip: '请输入校验提示语',
  passwordSet: '密码设置',
  useLimit: '密码使用期限',
  day: '天',
  unlimited: '不限制',
  pwRetryCount: '密码重试次数',
  count: '次',
  lockTime: '重试锁定时间',
  minute: '分钟',
  passwordHistory: '强制密码历史',
  reuse: '可重用',
  unreuse: '不可重用',
  mainPage: '首页',
  systemTitle: 'supOS工业操作系统',
  sure: '确认',
  tip: '提示',
  mainPageNodel: '首页不能删除',
  isQuit: '是否确认退出?',
  liable: '责任人',
  corporateName: '公司名称',
  status: '状态',
  logicalDeletion: '逻辑删除',
  waitInsert: '待接入',
  waitReplace: '待替换',
  waitcheck: '待审核',
  checked: '已审核',
  refused: '已拒绝',
  createTime: '创建时间',
  enterSearchContent: '请输入搜索内容',
  deleteUUID1: '删除该UUID后对应采集器也会被删除，',
  deleteUUID2: '且解除所有与该采集器相关联的绑定关系!!!',
  mustUUID: '必须要有uuid',
  enterName: '请输入名称',
  nameRule: '只允许输入以字母开头，字母数字和_的组合名称',
  companyAddress: '公司地址',
  enterCompanyAddress: '请输入公司地址',
  official: '负责人',
  enterOfficial: '请输入负责人',
  enterCompanyName: '请输入公司名称',
  UUID: 'UUID标识',
  create: '生成',
  enterDesc: '请输入描述',
  index: '序号',
  addressInfo: '地址信息',
  offline: '离线',
  online: '在线',
  catch: '异常',
  refreshTime: '最新刷新时间',
  refuseCollector: '是否拒绝该采集器接入？',
  sourceName: '源点名称',
  driverName: '驱动名称',
  sourceDesc: '源点描述',
  sourceStatus: '源点状态',
  updateTime: '更新时间',
  sourceInfo: '源点信息',
  createObject: '创建该设备到对象实例',
  Iagree: '我同意',
  agree: '同意',
  refuse: '拒绝',
  NotSupportBrowser: '您的浏览器暂不支持全屏展示！',
  isSureDelete: '是否确定删除该区块？',
  NotSupportDelete: '最后一个布局块不可删除',
  delSuccess: '删除成功',
  saveFail: '保存失败',
  editSuccess: '编辑成功',
  newField: '新文件夹',
  copySuccess: '拷贝成功',
  connectApp: '链接内部app',
  noConnectAuthority: '链接页面暂无权限',
  editPage: '编辑页面',
  exportFail: '导出失败',
  exportSuccess: '导出成功',
  installFail: '安装失败',
  installSuccess: '安装成功',
  createAPPFail: '创建APP失败',
  createAPP: '创建新的APP',
  importAPPRule: '请上传正确的APP文件',
  importFail: '上传失败',
  importSuccess: '上传成功',
  install: '安装',
  runJAVA: '选择运行的JAVA基础镜像',
  APPSetting: 'APP设置',
  pleaseEnter: '请输入',
  more: '更多',
  version: '版本',
  enterVersion: '请输入版本号',
  versionRule: '该版本号不合法，例如 1.0.1',
  importJAVA: '上传JAVA包',
  runtime: '运行环境',
  chooseRuntime: '请选择运行环境',
  sureDelete: '是否确定删除?',
  pageSetting: '页面配置',
  blockApply: '单块应用',
  layoutType: '布局类型',
  flowLayout: '流式布局',
  freeLayout: '自由布局',
  linkageType: '联动方式',
  auto: '自动',
  hand: '手动',
  freeWidth: '自由宽度',
  freeHeight: '自由高度',
  chooseBackground: '背景选择',
  pleaseChoose: '请选择',
  transparent: '透明',
  black: '黑色',
  blue: '蓝色',
  setting: '设置',
  popType: '弹框类型',
  page: '页面',
  blank: '空白页面',
  connectionPage: '链接页面',
  connectionURL: '链接URL',
  carousel: '轮播',
  pointOrdinaryColor: '页码颜色',
  pointSelectColor: '选中颜色',
  openWorkFlow: '启用工作流',
  distribute: '分发到',
  mobile: '移动端',
  showWay: '展示方式',
  horizontal: '横屏',
  vertical: '竖屏',
  autoScreen: '自动横竖屏',
  field: '文件夹',
  designPage: '界面设计',
  componentRoom: '组件库',
  userDefined: '自定义',
  exRoom: '扩展库',
  pelRoom: '图元库',
  chartRoom: '图表库',
  formRoom: '表单库',
  controlRoom: '控件库',
  LineChart: '折线图',
  SplineChart: '曲线图',
  Column: '柱状图',
  Area: '面积图',
  Dashboard: '仪表盘',
  Gauge: '速度仪',
  Bubble: '气泡图',
  Scatter: '散点图',
  Pie: '饼图',
  Funnel: '漏斗图',
  SpiderChart: '蛛网图',
  AnnularChart: '环形图',
  SpcChart: 'spc图',
  BarChart: '直方图',
  LineCtrl: '线条',
  LabelCtrl: '标签',
  HiddenFieldCtrl: '隐藏域',
  InputCtrl: '输入框',
  SelectCtrl: '下拉框',
  CascaderCtrl: '级联选择',
  RadioCtrl: '单选框',
  CheckboxCtrl: '复选框',
  DatePickerCtrl: '日期',
  ButtonCtrl: '按钮',
  TreeCtrl: '树状',
  SingleList: '单列表',
  TableCtrl: '表格',
  UploadImg: '图片',
  TabsCtrl: '标签页',
  UploadCtrl: '上传',
  Shape: '不规则图形',
  Circle: '圆形',
  Rect: '矩形',
  Oval: '椭圆',
  RoundRect: '圆角矩形',
  Polygon: '正多边形',
  Hexagon: '自定义形状',
  Triangle: '三角形',
  Star: '星形',
  Arc: '圆弧形',
  Text: '文字',
  Edge: '连线',
  image: '图片',
  Pipe: '管道',
  dataLink: '数据连接',
  lineArrow: '线段',
  RealAlarm: '实时报警',
  HistoryAlarm: '历史报警',
  LiveStreaming: '驱动视频',
  NewLiveStreaming: '位号视频',
  TrendChart: '趋势图',
  ViewTrendChart: '查看趋势图',
  ViewTrendInfo: '查看趋势信息',
  ViewAlarmInfo: '查看报警信息',
  TodoCtrl: '待办列表',
  ReporterCtrl: '报表',
  Webview: '网页',
  ProcessLogCtrl: '流程记录',
  StartWorkProcess: '发起流程',
  WorkProcessList: '流程列表',
  FlowParams: '配置工作流',
  selectTag: '选择标签',
  service200: '服务器成功返回请求的数据',
  service201: '新建或修改数据成功。',
  service202: '一个请求已经进入后台排队（异步任务）',
  service204: '删除数据成功。',
  service400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  service401: '用户没有权限（令牌、用户名、密码错误）。',
  service403: '用户得到授权，但是访问是被禁止的。',
  service404: '发出的请求针对的是不存在的记录，服务器没有进行操作',
  service406: '请求的格式不可得。',
  service410: '请求的资源被永久删除，且不会再得到的。',
  service422: '当创建一个对象时，发生一个验证错误。',
  service500: '服务器发生错误，请检查服务器',
  service502: '网关错误',
  service503: '服务不可用，服务器暂时过载或维护',
  service504: '网关超时',
  tower: '塔',
  other: '其他',
  flowmeter: '流量计',
  condensing: '冷凝设备',
  treater: '搅拌器',
  heatexchanger: '换热器',
  filter: '过滤器',
  pump: '泵',
  sensor: '传感器',
  transfer: '传送器',
  electric: '电机',
  valve: '阀',
  fan: '风机',
  symbol: '符号',
  pipe: '管道',
  jar: '罐',
  MicrosoftYaHei: '微软雅黑',
  blackbody: '黑体',
  regularscript: '楷体',
  officialscript: '隶书',
  youngcircle: '幼圆',
  songtypeface: '宋体',
  activeline: '实线',
  dottedline: '点线',
  shedline: '虚线',
  baseSet: '基础设置',
  reseauSet: '网格设置',
  eachother: '交互',
  userdefined: '自定义事件',
  rightMouse: '自定义右键菜单',
  canvasSize: '画布大小',
  contactroller: '滚轮缩放',
  suitScreen: '适应屏幕',
  playback: '支持回放',
  supportdrag: '支持拖动',
  reseauSize: '网格大小',
  everyreseau: '深浅每隔',
  reseauColor: '网格颜色',
  depthColor: '深线颜色',
  event: '事件',
  startTime: '开始时间',
  endTime: '结束时间',
  piecharact: '饼图特性',
  showLabel: '显示标签',
  labelBorder: '标签边框设置',
  labelColor: '标签颜色',
  bgColor: '背景颜色',
  alignmentmode: '对齐方式',
  labelformatting: '标签格式化',
  styleSet: '样式设置',
  startmeasure: '起始角度',
  endmeasure: '结束角度',
  fill: '填充',
  border: '边框',
  mark: '标注',
  dataSource: '数据源',
  title: '标题',
  linechartStyle: '折线图样式',
  base: '基础',
  picture: '图例',
  xaxis: 'X 轴',
  yaxis: 'Y 轴',
  remarks: '备注',
  yaxisShow: 'y轴显示',
  style: '样式',
  reseau: '网格',
  warning: '预警',
  ctrlId: '控件Id',
  show: '显示',
  hidden: '隐藏',
  mainTile: '主标题',
  subTitle: '副标题',
  textStyle: '文本样式',
  showRemarks: '显示备注',
  remarksInfo: '备注信息',
  baseBorder: '基础边框设置',
  baseBorderMarger: '基础边距设置',
  chartColor: '图表颜色',
  showXaxis: '显示X轴',
  graduation: '刻度',
  sightbead: '准星线',
  titlePosition: '标题位置',
  axisFontColor: '轴字颜色',
  rotation: '旋转角度',
  showYaxis: '显示Y轴',
  picBorderSet: '图例边框设置',
  layoutFunc: '布局方式',
  splineStyle: '曲线图样式',
  pile: '堆叠',
  columWidth: '柱宽',
  borderColor: '边框颜色',
  columnStyle: '柱状图样式',
  areaStyle: '面积图样式',
  DashboardStyle: '仪表盘样式',
  RegressionLine: '回归线',
  scatterStyle: '散点图样式',
  bubblescale: '气泡比例',
  bubbleStyle: '气泡图样式',
  originColor: '源点颜色',
  gaugeStyle: '速度仪样式',
  labelPosition: '标签位置',
  pieStyle: '饼图样式',
  type: '类型',
  neckSet: '颈部设置',
  funnelStyle: '漏斗图样式',
  spiderStyle: '蛛网图样式',
  UnClarity: '不透明度',
  annularStyle: '环形图样式',
  spcStyle: 'SPC图样式',
  xaxisStyle: 'X轴类型',
  spcType: 'SPC图类型',
  marksymbol: '标记符号',
  lineStyle: '线条样式',
  lineWidth: '线宽',
  CuLine: '拟合线',
  barStyle: '直方图样式',
  attribute: '属性',
  Doublesolidline: '双实线',
  componentName: '控件名称',
  Linedirection: '线条方向',
  LineType: '线条类型',
  Linesize: '线条大小',
  LineColor: '线条颜色',
  ColumnWidth: '列宽占比',
  ZIndex: '定位层级',
  componentWidth: '组件宽度',
  componentHeight: '组件高度',
  read: '只读',
  showLoad: '显示加载',
  fontSize: '字体大小',
  fontSizeUnit: '字体单位',
  fontColor: '字体颜色',
  font: '字体',
  overstriking: '加粗',
  alignment: '对齐',
  borderwidth: '边框宽度',
  mousestyle: '鼠标样式',
  mustfill: '必填标识',
  defaultValue: '默认值',
  placeholder: '占位符',
  titleWidth: '标题宽度',
  titleSize: '标题大小',
  titleColor: '标题颜色',
  titlefamily: '标题字体',
  ContextColor: '内容颜色',
  filled: '必填',
  IsMultipleLine: '是否多行',
  DataSelection: '动态数据选择',
  IsMultiple: '是否多选',
  AssociatedObject: '联动对象',
  HasAllChecked: '是否有全选',
  HasIcon: '勾选框',
  LabelCheckedColor: '选中颜色',
  LabelCheckedBackgroundColor: '选中背景',
  DateOpts: '日期类型',
  InitValue: '初始值',
  ButtonName: '按钮标题',
  BorderRadius: '圆角半径',
  Icon: '按钮图标',
  IconUpload: '自定义图标',
  IconPos: '图标位置',
  IconSize: '图标大小',
  BackgroundColor: '背景色',
  PrintHidden: '打印隐藏',
  Disabled: '不可用状态',
  IsSearch: '是否搜索',
  bindSource: '字段绑定',
  IsRequired: '是否必填',
  SingleListSelect: '字段',
  IsFixedHeader: '表头固定',
  IsSelected: '是否可选',
  BorderOut: '外边框',
  borderLg: '边框大小',
  TheadBackgroundColor: '表头背景',
  TbodyBackgroundColor: '表身背景',
  SingleRowColor: '单行颜色',
  DoubleRowColor: '双行颜色',
  TableX: '滚动宽度',
  IsPagination: '是否分页',
  PageSize: '每页条数',
  BindingTableHeaderSource: '表头设置',
  ListType: '列表类型',
  LimitNum: '限制数量',
  Height: '高度',
  Width: '宽度',
  AutoPlay: '自动播放',
  StreamSource: '视频源',
  data: '数据',
  parameter: '参数',
  FillDataType: '填充类型',
  RunTimeEdit: '运行时可编辑',
  sheetPage: '展示Sheet页',
  initLoadData: '初始化加载数据',
  zoomSheet: '小尺寸缩放',
  zoomLargeSheet: '大尺寸缩放',
  adaptive: '自适应',
  horizontalAdaptive: '横向',
  verticalAdaptive: '纵向',
  biAdaptive: '双向',
  noAdaptive: '不适应',
  position: '位置',
  LabelContent: '内容',
  margin: '边距',
  Iframe: '网页',
  IframeMargin: '外边距',
  IframePadding: '内边距',
  url: '网址',
  size: '大小',
  CtrlTitle: '标题设置',
  progressSet: '进度设置',
  intervalLine: '间隔线',
  ColsNum: '列数',
  button: '按键',
  TitleMarginTop: '上边距',
  HTRotation: '旋转',
  Layer: '图层',
  Visible: '可见',
  FillingSwitch: '启用',
  diameter: '直径大小',
  HTMarkBorder: '边框样式',
  HTRadius: '圆角大小',
  HTSide: '边数',
  HTShape: '图形',
  character: '字符',
  lineheight: '行高',
  FontDirection: '竖排文字',
  HTImageStyle: '拉伸',
  HTImageSource: '上传图片',
  HTDash3dColor: '高光颜色',
  HTPipeSize: '粗细',
  deviceInfo: '设备振动分析',
  deviceConnection: '设备链接',
  length: '长度',
  left: '居左',
  center: '居中',
  right: '居右',
  null: '无',
  gaussian: '正态分布',
  low: '居下',
  top: '居上',
  horizontalLayout: '水平布局',
  verticalLayout: '垂直布局',
  topcenter: '顶部居中',
  topleft: '顶部居左',
  topright: '顶部居右',
  bottomcenter: '底部居中',
  bottomleft: '底部居左',
  bottomright: '底部居右',
  middleleft: '左部居中',
  middleright: '右部居中',
  normal: '普通',
  bold: '加粗',
  search: '搜索',
  reload: '刷新',
  add: '新增',
  download: '下载',
  up: '上',
  down: '下',
  newFile: '新增文件夹',
  document: '文档',
  file: '文件夹',
  extraSmall: '超小',
  small: '小',
  default: '默认',
  large: '大',
  extraLarge: '超大',
  have: '有',
  singleline: '单行',
  multiline: '多行',
  orderLeft: '左对齐',
  orderRight: '右对齐',
  crosshair: '十字线',
  connectPointer: '链接指针',
  moveable: '可移动',
  writeText: '文本',
  onebear: '单轴',
  twobear: '多轴',
  positionLeft: '左',
  positionRight: '右',
  shortDotted: '短虚线',
  longDotted: '长虚线',
  square: '正方形',
  rhombus: '菱形',
  triangledown: '倒三角形',
  timebear: '时间轴',
  fixed: '固定',
  nofixed: '不固定',
  chooseable: '可选',
  unchooseable: '不可选',
  paging: '分页',
  nopaging: '不分页',
  onlytext: '纯文字',
  imageCard: '图片-卡片',
  nofilled: '非必填',
  baseSetting: '基础配置',
  ConpontBackground: '控件背景',
  ListBackground: '列表背景',
  TitleBackground: '标题背景',
  enterexactnessnum: '请输入正确的数字',
  objectName: '对象名称',
  dataColor: '数据颜色',
  startColor: '起始颜色',
  endColor: '结束颜色',
  fieldChoose: '字段选择',
  parameterSet: '参数设置',
  chooseDatasource: '选择数据源',
  intervalTimer: '定时刷新',
  addY: '添加',
  rattle: '格',
  colorWin: '关闭窗口',
  editEvent: '事件编辑',
  finish: '完成',
  aliasName: '别名：{name}',
  pleaseEditEvent: '请编辑事件',
  rightClickRule: '同一个对象中，不同鼠标右键菜单的名称、别名不能重复',
  saveSuccess: '保存成功',
  addoption: '+ 新增选项',
  addRightClick: '新增右击项',
  enteralias: '别名不能为空',
  min: '最小值',
  max: '最大值',
  minthanmax: '最小预警值不能大于最大预警值',
  maxlessmin: '最大预警值不能小于最小预警值',
  marginBottom: '下边距',
  marginLeft: '左边距',
  marginRight: '右边距',
  marked: '标记',
  interval: '间隔',
  fillet: '圆角',
  digitcapacity: '位数',
  percentage: '百分比',
  nameValue: '名称:值',
  namePercentage: '名称:百分比',
  valuePercentage: '值:百分比',
  nameValuePer: '名称 值:百分比',
  canvasChange: '画布信息有改动并暂未保存,是否退出?',
  ctrlNameIsRepeat: '保存失败，表单库组件控件名称不能重复',
  ctrlNameIsNull: '表单库组件控件名称不能为空，是否继续?',
  preview: '预览',
  quit: '退出',
  check: '检查',
  Scaledivision: '刻度分割',
  pointradius: '点半径',
  pointconnect: '连接点',
  distance: '距离',
  radiusScale: '内半径比例',
  neckWidth: '颈宽',
  neckHeight: '颈高',
  nValue: 'n值',
  decimal: '小数位数',
  unusualColor: '异常颜色',
  individualsChart: '单值图',
  rangeDiagram: '极差图',
  validTime: '校验时机',
  canonical: '请输入正确的正则',
  selfdefined: '自定义',
  form: '格式',
  novalid: '不校验',
  changeContent: '内容改变',
  onblur: '失去焦点',
  formSubmit: '表单提交',
  phoneCode: '电话号码',
  zipcode: '邮编',
  IDcard: '身份证',
  email: '邮箱',
  IPaddress: 'IP地址',
  chooseAction: '请选择交互',
  loadContent: '内容加载',
  flowGetData: '工作流获取数据',
  flowSubData: '工作流提交数据',
  flowRejectData: '工作流驳回数据',
  selected: '选中',
  click: '单击',
  dbclick: '双击',
  focus: '获得焦点',
  opText: '选项文本',
  opValue: '选项的值',
  filterCondition: '过滤条件',
  dyData: '动态数据',
  staticData: '静态数据',
  Alternative: '备选类型',
  dynamic: '动态',
  static: '静态',
  DateForm: '日期格式',
  enterDateForm: '请输入时间格式',
  selfForm: '自定义格式',
  alarmTime: '报警时间',
  keyTip: '请输入名称或描述',
  time: '时间',
  year: '年',
  month: '月',
  dateTime: '日期+时间',
  dateZone: '日期区间',
  dateTimeZone: '日期+时间区间',
  uploadIcon: '点击上传图标',
  addBind: '添加绑定关系',
  addHeader: '新增表头',
  filterArray: '过滤数组',
  filterArrayRule: '请输入过滤数组,例如[{"type":"类型"}]',
  filterFunction: '过滤方法',
  filterFunctionRule: '请输入过滤方法,value代表当前值,record 代表当前行数据 例 return record.name === value',
  serviceSort: '后端排序',
  sequence: '排序',
  sequenceRule: '请输入排序方法,pre代表前一个,cur代表当前,例:return pre.count > cur.count',
  inlineEdit: '行内编辑',
  yes: '是',
  no: '否',
  editType: '编辑类型',
  select: '选择框',
  enterDatasourceRule: '请输入数据源,例如[{"type":"类型"}]',
  dynamicEdit: '是否编辑(动态)',
  enterdynamicEdit: '请输入动态配置是否编辑,record代表当前行,例如 return record.isEdit === true',
  dynamicSave: '是否保存(动态)',
  enterdynamicSave: '请输入动态配置是否编辑,originalName代表当前字段,row代表当前行,tableData代表全部数据,例如 return row.isHandleSave == true',
  editCallback: '编辑回调(动态)',
  entereditCallback: '请输入行内编辑的回调方法,row代表当前行数据,例如 console.log(row)',
  dataMustFilled: '数据的字段名必填!',
  datafield: '数据的字段名',
  showName: '显示名',
  enterShowName: '数据的显示名称',
  enterTableWidth: '表格内容宽度',
  isShowTip: '是否展示tips',
  dataType: '数据类型',
  iconType: '图片格式',
  timeformat1: '时间格式:年月日',
  timeformat2: '时间格式:年月日时分秒',
  timeformat3: '时间格式:时分秒',
  dataRender: '数据渲染',
  enterDataRender: '请输入数据渲染的方法,可用参数:text, row, index, tableData, 例如 return <a href=/>text</a>',
  bottomTip: 'Tips: 简单配置可通过该页面设置，如需要编辑等其他功能仍需要脚本维护',
  chooseStreamSource: '请选择视频源',
  channel: '通道',
  model: '型号',
  manufacturer: '厂商',
  videoTag: '视频位号',
  timespan: '时间跨度',
  quantities: '工程量',
  myTodo: '我的待办',
  myDone: '我的已办',
  myRequest: '我的发起',
  myEntrust: '我的委托',
  selfAttribut: '自定义属性',
  attrName: '属性名称',
  labelName: '标签名',
  enterValueName: '请输入名称和值',
  replace: '替换',
  insert: '插入',
  bindDate: '日期绑定',
  initSet: '初始值设置',
  linkageSet: '联动配置',
  bindRule: '按钮绑定（联动方式为手动时，单击此处绑定按钮）',
  chooseTime: '选择时间',
  network: '网络',
  filterMachine: '条件过滤器',
  URLparameter: 'URL参数',
  chooseCompoent: '可选控件',
  blod: '粗',
  italic: '斜',
  pure: '纯色',
  gradient: '渐变色',
  nowWindow: '当前窗口',
  newWindow: '新建窗口',
  popWindow: '弹出窗口',
  fatherWindow: '父级窗口',
  modelWindow: '模态窗口',
  openConnection: '打开链接',
  script: '脚本',
  equalratio: '等比',
  Centerequalratio: '中心等比',
  hexagon: '六边形',
  pentagon: '五边形',
  diamond: '钻石形',
  rightTriangle: '直角三角形',
  parallelogram: '平行四边形',
  trapezoid: '梯形',
  noScal: '不缩放',
  normWidth: '以宽为准',
  normHeight: '以高为准',
  bespread: '铺满',
  chooseColor: '选择颜色',
  widthHeightscale: '宽高比',
  root: '根节点',
  childnode: '子节点{id}',
  cotyledon: '叶子{id}',
  listItem: '列表条目',
  chooseListItem: '选中的列表条目',
  tabs: '标签{id}',
  confirmTime: '确认时间',
  deleteTime: '消除时间',
  confirmPerson: '确认人员',
  alarmTotal: '报警总数',
  allConfirm: '全部确认',
  prev: '上一页',
  next: '下一页',
  ep: '尾页',
  freeze: '冻结',
  higher: '高优先级',
  filte: '过滤',
  twig: '条',
  update: '更新',
  user: '用户{count}',
  message: '任务{count}',
  submit: '提交',
  verifyResult: '审批结果{count}',
  note: '批注{count}',
  ProcessRecord: '流程记录',
  messageName: '任务名称：{name}',
  opreator: '操作者：{name}',
  opTime: '操作时间：{name}',
  opType: '操作类型：{name}',
  verifyResultcolon: '审批结果：{name}',
  notecolon: '批注：{name}',
  flowWork: '工作流',
  distanceTop: '距上',
  distanceLeft: '距左',
  isCenter: '是否居中',
  open: '打开位置',
  createPageError: '页面信息有误,请重新创建',
  machineInfo: '设备信息',
  machineCode: '设备编号',
  cancelDelegateSuccess: '取消委托成功',
  setDelegateSuccess: '设置委托成功',
  assemble: '组合:ctrl + g',
  Cancelassemble: '取消组合:ctrl + shift + g',
  fullScreen: '全屏',
  sameWidth: '等宽',
  sameHeight: '等高',
  sameSize: '等大小',
  DynamicBrush: '动态格式刷',
  modelSave: '另存为模板',
  notNull: '不能为空',
  nameRule1: '只允许输入以字母开头，字母数字中划线和_的组合名称',
  maxNumber50: '不能超过50个字',
  maxNumber200: '不能超过200个字',
  maxNumber500: '不能超过500个字',
  enterNumber: '请输入正整数',
  noBlank: '不能只输入空格',
  page403: '抱歉，你无权访问该页面',
  page404: '抱歉，你访问的页面不存在',
  page500: '抱歉，服务器出错了',
  retMainPage: '返回首页',
  preObjectSet: '请先配置对象属性!',
  SourceNoExist: '数据源属性不存在',
  addSuccess: '添加成功',
  dataTypeRule: '{name}数据类型不符合要求!请更换数据源!',
  attrExist: '{name}对象属性已存在',
  FolerSetting: '文件夹设置',
  FolerName: '文件夹名称',
  afterFolderDel: '删除该文件夹后，将删除文件下所有模板',
  IsDelModel: '请确认删除该模板',
  startBackup: '开始备份',
  backupSupos: '备份supOS工程文件',
  FlowButton: '流程按钮',
  AxisColor: '轴线颜色',
  Formatting: '格式化',
  AxisReversal: '坐标反转',
  dialColor: '表盘颜色',
  dialBorderColor: '盘边颜色',
  dialBorderWidth: '盘边宽度',
  ScaleColor: '刻度颜色',
  originRadius: '源点半径',
  unit: '单位',
  Gridline: '网格线',
  changeAnnularChart: '可变宽度环形图',
  systemShow: '系统菜单栏显示',
  ShowNavigation: '显示导航',
  cornerMarkHeight: '角标高度',
  cornerMarkWidth: '角标宽度',
  cornerMarkColor: '角标颜色',
  titleHeight: '标题高度',
  titleContent: '标题内容',
  titleBorderVisible: '显示边框',
  themeFirst: '主题一',
  BackendName: '后端名称',
  ComponentCompleted: '组件加载完成',
  singleClick: '单击数据点',
  doubleClick: '双击数据点',
  showObjectName: '显示名称',
  markSize: '标记大小',
  insideSpace: '内间距',
  markMax: '标记最大值',
  changeType: '切换类型',
  canvalName: '画布名称',
  dialBore: '表盘内径',
  scaleLength: '刻度长度',
  signArea: '标识区',
  tailLength: '尾部长度',
  connectScale: '连接刻度',
  maxLength: '最大长度',
  alignTop: '上对齐',
  alignMiddle: '居中',
  alignBottom: '下对齐',
  verticalAlign: '垂直对齐',
  roundSowing: '视频轮播',
  changeWidthPie: '可变宽度饼图',
  colour: '配色',
  buttonSetting: '按钮设置',
  purple: '紫色',
  wathetBlue: '浅蓝色',
  yellow: '黄色',
  green: '绿色',
  red: '红色',
  StrokeColor: '描边颜色',
  buttonStyle: '按钮样式',
  CutLine: '垂直排列',
  buttonBackground: '按钮背景',
  chooseButtonBg: '按钮选中背景',
  chooseFontBg: '字体选中颜色',
  node: '节点',
  requestApi: '请求地址',
  requestMethod: '请求方式',
  searchKey: '搜索字段',
  pageTotal: '条数字段',
  showTotal: '展示条数',
  fieldIdentification: '字段标识',
  showField: '展示字段',
  confirmIcon: '确认图标',
  TextVertical: '文字竖排',
  selfPropSet: '自定义属性配置',
  headDrag: '列宽调整',
  showHead: '显示表头',
  formHeight: '表格高度',
  setTime: '定时刷新',
  setfixedTime: '刷新频率',
  timer: '定时器',
  rotationFrequency: '轮播频率',
  PlayerSource: '播放源',
  pageTurningArrow: '翻页箭头',
  rotaryPageNumber: '轮播页码',
  unitSecond: '秒',
  layoutList: '布局列表',
  mobileNavs: '导航栏:',
  addMobileNavsTip: '请添加移动端导航',
  verifyMobileNavsTip: '至少添加一个导航栏!',
  isIncludeCanvas: '已经存在该画布!',
  layoutTypeNoExist: '该区块类型不存在',
  changePCInterface: '切换至PC版布局',
  changeMobileInterface: '切换至移动端布局',
  verifyMobileNavsLength: '最多支持5种类型',
  lastOneBanDelete: '最后一个不可删除',
  navsList: '导航列表',
  navsInputPlaceholder: '请输入导航名称',
  mobileNavsBar: '移动端导航栏',
  addMobileNav: '添加导航',
  sureDelLayout: '是否确定删除该区块?',
  verifyLayoutWidth: '设置宽度超过最大可配置宽度，自适应为最大宽度',
  loginTitle: '登录标题',
  isOpen: '是否展开',
  defaultSelect: '默认选中',
  all: '所有',
  files: '文件',
  radio: '影音',
  mutilSelect: '多选',
  showList: '显示列表',
  uploadType: '上传类型',
  crumbs: '面包屑',
  calendar: '日历',
  programme: '可编程组件',
  pagePreviewTip: '正在保存信息,请稍后重试!',
  widgetIsError: '脚本运行出错',
  initContent: '页面初始化',
  blockIsError: '该区块配置信息有误',
  machineVision: '机器视觉',
  dragModalTip: '弹窗个数过多，若要继续打开，请关闭其他弹窗!',
  addCustomProp: '添加自定义属性',
  tabName: '标签名',
  propsList: '属性列表',
  addTab: '添加标签',
  modal: '弹窗',
  modalTitle: '弹窗标题',
  modalSize: '弹窗大小',
  width: '宽',
  height: '高',
  tabIndex: '序号',
  linkPage: '链接页面',
  pleaseSelect: '请选择',
  pageVariable: '页面参数',
  noticeMatters: '通知事项',
  overdue: '逾期',
  overdueValue: '逾期值',
  overdueName: '逾期名称',
  overdueColor: '逾期颜色',
  normalTodo: '正常',
  normalTodoValue: '正常值',
  normalTodoName: '正常名称',
  normalTodoColor: '正常颜色',
  init: '默认',
  initValue: '默认值',
  initName: '默认名称',
  initColor: '默认颜色',
  weekCalendar: '周历',
  monthCalendar: '月历',
  yearCalendar: '年历',
  initSelected: '默认选中',
  propName: '属性名称',
  noLinkWorkflow: '当前流程按钮未绑定工作流，请联系管理员',
  notificationCenter: '通知中心',
  typeConfig: '类型配置',
  logManage: '日志管理',
  msgManage: '站内信管理',
  appMenuSetting: '菜单配置',
  // 自定义属性
  editConfirm: '确认删除吗?',
  noSameGroup: '组名不能重复',
  noSameProp: '属性名称不能重复',
  noEmptyProp: '属性名不能为空',
  numberOnly: '只能输入数字',
  noEmptyType: '类型不能为空',
  boolen: '布尔',
  addNewProp: '新增属性配置组',
  group: '组',
  groupName: '组名称',
  noEmptyGroup: '名称不能为空',
  idDuplicate: '控件Id重复，请修改后重试',
  Compress: '是否压缩',
  uploadProfile: '上传权限文件',
  addLayoutTip: '正在添加区块,请稍后...',
  layoutJumpToDrawingTip: '页面含有请求未完成,请稍候...',
  toolBarShow: '显示工具栏',
  navbarStyle: '导航栏样式',
  navbarBackgroundColor: '导航栏背景色',
  navbarFront: '导航栏字体',
  suspend: '悬浮',
  white: '白色',
  gray: '灰色',
  dark: '深色',
  light: '浅色',
  scrollbarVisible: '滚动条',
  phugoidCurve: '振动曲线',
  deleteSuccess: '删除成功',
  Tiling: '平铺',
  list: '列表',
  showListType: '列表模式',
  isPrestrain: '预加载',
  fullscreen: '全屏',
  showNum: '数值',
  BgColor: '背景颜色',
  BorderColor: '边框颜色',
  SlideColor: '滑杆颜色',
  FontColor: '文字颜色',
  BgChartColor: '图表颜色',
  colWidth: '列宽',
  colAutoWidth: '列宽自适应',
  changeMenuName: '菜单名称修改',
  brushRule: '动态格式刷只能在相同对象之间使用',
  NoAuthority: '无数据权限',
  codeLogin: '验证码登录',
  off: '关闭',
  on: '开启'
});
