/**
  * Observer pattern implementation with event.
  *
  */

const eventCallback = function (callback, ctx, args) {
  callback.apply(ctx, args);
};

const SuposEvents = {

  on(name, callback, ctx) {
    this.eventMap = this.eventMap || {};
    const cbCtx = { cb: callback, ctx: ctx || this };
    const ls = this.eventMap[name] || (this.eventMap[name] = []);
    ls.push(cbCtx);
    return this;
  },

  trigger(...rest) {
    if (!this.eventMap) {
      return this;
    }
    const name = rest[0];
    const ls = this.eventMap[name];
    if (ls && ls.length > 0) {
      const args = Array.prototype.slice.call(rest, 1);
      let cbCtx;
      ls.forEach((item) => {
        cbCtx = item;
        eventCallback(cbCtx.cb, cbCtx.ctx, args);
      });
    }
    return this;
  },

  off(name, callback, ctx) {
    if (!name && !callback && !ctx) {
      this.eventMap = undefined;
      return this;
    }
    let handlers;
    let retain;
    const names = name ? [name] : this._keys(this.eventMap);
    const iterator = (handler) => {
      if ((callback && callback !== handler.cb) || (ctx && ctx !== handler.ctx)) {
        retain.push(handler);
      }
    };
    names.forEach((v) => {
      name = v;
      if (handlers = this.eventMap[name]) {
        this.eventMap[name] = retain;
        retain = [];
        if (callback || ctx) {
          handlers.forEach(iterator);
        }
        if (!retain.length) delete this.eventMap[name];
      }
    });
    return this;
  },

  _keys(obj) {
    const keys = Object.keys(obj);
    return keys;
  }
};

export default SuposEvents;

