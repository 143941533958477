import { getPropertiesByWidgets } from '../services/api';

export default{
  namespace: 'industryWidgets',

  state: {
    flows: null,
    widgets: null
  },

  effects: {
    *getFlowPic(_, { call, put }) {
      const response = yield call(getPropertiesByWidgets);
      yield put({
        type: 'done',
        payload: response
      });
    }
  },

  reducers: {
    done(state, action) {
      return {
        ...state,
        flows: action.payload.Flows,
        widgets: action.payload.Widgets
      };
    }
  }
};
