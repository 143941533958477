import request from '../utils/request';
import config from '../config';

export async function queryAllTemplates({ pageNum, pageSize, searchKey }) {
  const url = `${config.domainObject}/objectselector/templates?page=${pageNum}&per_page=${pageSize}`;
  return request(searchKey ? `${url}&name=${searchKey}` : url);
}

export async function queryAllInstances({ pageNum, pageSize, searchKey }) {
  const url = `${config.domainObject}/objectselector/instances?page=${pageNum}&per_page=${pageSize}`;
  return request(searchKey ? `${url}&name=${searchKey}` : url);
}

export async function queryInstancesByTemplate({ templateName, pageNum, pageSize, searchKey }) {
  const url = `${config.domainObject}/objectselector/templates/${templateName}?page=${pageNum}&per_page=${pageSize}&isBinding=true`;
  return request(searchKey ? `${url}&name=${searchKey}` : url);
}

// 查询角色
export async function queryRole({ pageNum, pageSize, searchKey }) {
  const url = `${config.domain2}/api/auth/roles?pageIndex=${pageNum}&pageSize=${pageSize}`;
  return request(searchKey ? `${url}&rolename=${searchKey}` : url);
}

export async function queryPropsByInstace({ instanceName, pageNum = 1, pageSize = 20, searchKey }) {
  let url = `${config.domainObject}/objectselector/instance/${instanceName}?page=${pageNum}&per_page=${pageSize}`;
  url = searchKey ? `${url}&propName=${searchKey}` : url;
  return request(url);
}

export async function getHistorySingleData({ dataSource, type, filters }) {
  return request(`${config.domainObject}/objectdata/query`,
    {
      method: 'POST',
      body: {
        dataSource,
        type,
        filters
      }
    });
}

export async function getHistoryData(parameters) {
  return request(`${config.domainObject}/objectdata/batchQuery`,
    {
      method: 'POST',
      body: {
        list: parameters
      }
    });
}

export async function queryVideoInfo({ list }) {
  return request(`${config.domainIndustryApplication}/objectdata/batchQuery`, {
    method: 'POST',
    body: { list } // 视频数据查询，暂不作扩展
  });
}

export async function queryInstanceData({ dataSource, resType, filters }) {
  return request(`${config.domainIndustryApplication}/objectdata/query`, {
    method: 'POST',
    body: { dataSource, type: resType, filters } // 实例数据查询，暂不作扩展
  });
}

// 查询DataTable的数据
export async function getHistoryDatatable({ dataSource, type, filters }) {
  return request(`${config.domainObject}/objectdata/query`,
    {
      method: 'POST',
      body: {
        dataSource,
        type,
        filters
      }
    });
}

export async function queryByNetworkStaffTemplate({ pageNum, pageSize, searchKey }) {
  const url = `${config.domain2}/api/metadata/object_templates?name=NetworkStaffTemplate?page=${pageNum}&per_page=${pageSize}`;
  return request(searchKey ? `${url}&name=${searchKey}` : url);
}

export async function queryPropsByStaff({ instanceName }) {
  return request(`${config.domain2}/api/metadata/objects/${instanceName}/properties?type=own`);
}

export async function queryObjects({ templateName, page, pageSize, resultType }) {
  return request(`${config.domain2}/api/metadata/objects?templateName=${templateName}&page=${page}&per_page=${pageSize}&resultType=${resultType}`);
}

/** ***************对象实例相关接口-标签/实例/属性/服务/事件**************** */


export async function getObjectInstanceList({ pageNum, pageSize, searchKey }) {
  const url = `${config.domain2}/api/metadata/objects?page=${pageNum}&per_page=${pageSize}`;
  return request(searchKey ? `${url}&name=${searchKey}` : url);
}

export async function getObjectInstanceByLabel({ labels, pageNum, pageSize, searchKey }) {
  const url = `${config.domain2}/api/metadata/objects?page=${pageNum}&per_page=${pageSize}&labels=${labels}`;
  return request(searchKey ? `${url}&name=${searchKey}` : url);
}

export async function getPropsByObjectInstace({ name, type = 'all' }) {
  return request(`${config.domain2}/api/metadata/objects/${name}/properties?type=${type}`);
}

export async function getServicesByObjectInstace({ name, type = 'all' }) {
  return request(`${config.domain2}/api/metadata/objects/${name}/services?type=${type}`);
}

export async function getEventsByObjectInstace({ name, type = 'all' }) {
  return request(`${config.domain2}/api/metadata/objects/${name}/events?type=${type}`);
}

/** ***************对象模板相关接口**************** */

export async function getObjectTemplateList({ pageNum, pageSize, searchKey }) {
  const url = `${config.domain2}/api/metadata/object_templates?page=${pageNum}&per_page=${pageSize}`;
  return request(searchKey ? `${url}&name=${searchKey}` : url);
}

export async function getObjectTemplateByLabel({ labels, pageNum, pageSize, searchKey }) {
  const url = `${config.domain2}/api/metadata/object_templates?page=${pageNum}&per_page=${pageSize}&labels=${labels}`;
  return request(searchKey ? `${url}&name=${searchKey}` : url);
}

export async function getPropsByObjectTemplate({ name, type = 'all' }) {
  return request(`${config.domain2}/api/metadata/object_templates/${name}/properties?type=${type}`);
}

export async function getServicesByObjectTemplate({ name, type = 'all' }) {
  return request(`${config.domain2}/api/metadata/object_templates/${name}/services?type=${type}`);
}

export async function getEventsByObjectTemplate({ name, type = 'all' }) {
  return request(`${config.domain2}/api/metadata/object_templates/${name}/events?type=${type}`);
}

/** ***************功能集合相关接口**************** */

export async function getFeatureSetList({ pageNum, pageSize, searchKey }) {
  const url = `${config.domain2}/api/metadata/features?page=${pageNum}&per_page=${pageSize}`;
  return request(searchKey ? `${url}&name=${searchKey}` : url);
}

export async function getFeatureSetByLabel({ labels, pageNum, pageSize, searchKey }) {
  const url = `${config.domain2}/api/metadata/features?page=${pageNum}&per_page=${pageSize}&labels=${labels}`;
  return request(searchKey ? `${url}&name=${searchKey}` : url);
}

export async function getPropsByFeatureSet({ name }) {
  return request(`${config.domain2}/api/metadata/features/${name}/properties`);
}

export async function getServicesByFeatureSet({ name }) {
  return request(`${config.domain2}/api/metadata/features/${name}/services`);
}

export async function getEventsByFeatureSet({ name }) {
  return request(`${config.domain2}/api/metadata/features/${name}/events`);
}

/** ***************网络相关接口**************** */
export async function queryAllNetwork({ pageNum, pageSize, searchKey }) {
  const url = `${config.domainObject}/networks?page=${pageNum}&per_page=${pageSize}`;
  return request(searchKey ? `${url}&name=${searchKey}` : url);
}

/** ***************实例, 模板, 功能集合相关接口**************** */

export async function getModelLabelList({ pageNum, pageSize, searchKey, modelLabelsType }) {
  const url = `${config.domain2}/api/metadata/modelLabels?page=${pageNum}&per_page=${pageSize}`;
  const modelLabelsTypeUrl = modelLabelsType ? `&modelLabelsType=${encodeURIComponent(modelLabelsType)}` : '';
  const searchKeyUrl = searchKey ? `&keyword=${searchKey}` : '';
  return request(`${url}${searchKeyUrl}${modelLabelsTypeUrl}`);
}

export async function getInstancesByLabel({ requestType, labels, pageNum, pageSize, searchKey, remote = false, templateName }) {
  let url = `${config.domainObject}/objectselector/instances/type/${requestType}?remote=${remote}&page=${pageNum}&per_page=${pageSize}`;
  url = labels ? `${url}&labels=${labels}` : url;
  url = searchKey ? `${url}&keyWord=${searchKey}` : url;
  url = templateName ? `${url}&templateName=${templateName}` : url;
  return request(url);
}

export async function getVideoObjectQuery({ collectorName, pageNum, pageSize }) {
  const url = `${config.domain2}/api/metadata/objects/${collectorName}/videoObjectQuery?page=${pageNum}&per_page=${pageSize}&collectorName=${collectorName}`;
  return request(url);
}

export async function getProps({ requestType = 'instance', name, queryAll, pageNum = 1, pageSize = 20, searchKey }) {
  let url = `${config.domainObject}/objectselector/instance/${name}/type/${requestType}?page=${pageNum}&per_page=${pageSize}`;
  url = queryAll === false ? `${url}&queryAll=false` : url;
  url = searchKey ? `${url}&propName=${searchKey}` : url;
  return request(url);
}

// 更具角色查询对应所有人员的信息
export async function queryUsersByRoleId(params) {
  return request(`${config.domain2}/users/queryUsersByName/${params.name}`);
}

export async function editHistoryData(params) {
  return request(`${config.domainObject}/history/data`, {
    method: 'PUT',
    body: {
      input: params
    }
  });
}

export async function getProperties({ pageNum = 1, pageSize = 20, keyword, remote = false, useTemplate = false }) {
  let url = `${config.domainObject}/search/${useTemplate ? 'templateProperties' : 'properties'}?remote=${remote}&page=${pageNum}&per_page=${pageSize}`;
  url = keyword ? `${url}&keyword=${keyword}` : url;
  return request(url);
}


// 查询单个服务详细信息
export async function getServiceInfo({ instance, service }) {
  return request(`${config.domain2}/api/metadata/objects/${instance}/services/${service} `);
}

// 查询实时统计任务列表
export async function getStatisticsMng({ pageSize = 5, pageNum = 1, taskName, enableExactMatch = false }) {
  let url = `/api/dispatchor/statistics/config/task?pageSize=${pageSize}&pageNo=${pageNum}&enableExactMatch=${enableExactMatch}`;
  url = taskName ? `${url}&taskName=${encodeURI(taskName)}` : url;
  return request(url);
}

// 获取实时统计值
export async function queryStatistics(param) {
  return request('/api/dispatchor/statistics/result/query', {
    method: 'POST',
    body: param
  });
}
