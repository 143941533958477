import request from '../utils/request';
import config from '../config';

/**
 * 非 APP编辑 页面中需要的API不要放在这里!
 */

// 获取 模板库组件 列表
export async function fetchTemplates(id) {
  return request(`${config.domain2}/api/compose/manage/templates?parentId=${id || ''}`,
    {
      method: 'GET'
    }
  );
}

// 新增 模板库组件
export async function addNewTemplates(obj) {
  return request(`${config.domain2}/api/compose/manage/templates`,
    {
      method: 'POST',
      body: { ...obj }
    }
  );
}

// 删除 模板库组件
export async function deleteTemplates(id) {
  return request(`${config.domain2}/api/compose/manage/templates/${id}`, {
    method: 'DELETE'
  });
}

// 编辑 模板库
export async function editTemplates(obj) {
  const { id, ...body } = obj;
  return request(`${config.domain2}/api/compose/manage/templates/${id}`, {
    method: 'PUT',
    body: { ...body }
  });
}

// 新增模板库目录
export async function addFolder(obj) {
  return request(`${config.domain2}/api/compose/manage/templateFolders`, {
    method: 'POST',
    body: { ...obj }
  });
}


// 导入
export const importZip = `${config.domain2}/api/compose/manage/pages/import`;

// 导出
export async function exportPages(data) {
  return request(`${config.domain2}/api/compose/manage/pages/package`, {
    method: 'POST',
    body: { ...data }
  });
}
