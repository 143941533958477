import { getNetWorkList, removeNetWork, addNetWork, putNetWork } from '../services/api';

export default {
  namespace: 'netWork',
  state: {
    NetWorkList: { pagination: {}, list: [] }
  },
  effects: {
    * getNetWorkList({ payload }, { call, put }) {
      const response = yield call(getNetWorkList, payload);
      yield put({
        type: 'setNetWorkList',
        payload: response
      });
    },
    * removeNetWork({ payload, callback }, { call }) {
      const response = yield call(removeNetWork, payload);
      if (callback) callback(response);
    },
    * addNetWork({ payload, callback }, { call }) {
      const response = yield call(addNetWork, payload);
      if (callback) callback(response);
    },
    * putNetWork({ payload, callback }, { call }) {
      const response = yield call(putNetWork, payload);
      if (callback) callback(response);
    }
  },

  reducers: {
    setNetWorkList(state, action) {
      return {
        ...state,
        NetWorkList: action.payload
      };
    }
  }
};
