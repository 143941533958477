import {
  addDevice,
  queryDeviceList,
  confirmDevice,
  manageDeviceList,
  Idticket,
  DeviceDetails,
  Drivers,
  Devicel,
  DevicelDetailByName,
  deleteDevice,
  putDevice,
  deleteDeviceRelation
} from '../services/api';


export default {
  namespace: 'deviceManage',

  state: {
    data: {
      list: []
    },
    List: [],
    deviceInfo: {
      deviceRegInfo: {}
    },
    drivers: []
  },

  effects: {
    *drivers({ payload, callback }, { call, put }) {
      const response = yield call(Drivers, payload);
      let result = [];
      if (!response.error) {
        const arr = Object.keys(response.result).map((item) => {
          return {
            name: item
          };
        });
        result = arr.map((item) => {
          const obj = response.result[item.name];
          item.online = obj.online.value;
          item.description = obj.description.value;
          item.time = obj.time.value;
          item.type = obj.type.value;
          return item;
        });
      }
      yield put({
        type: 'driversData',
        payload: result
      });
      if (callback) callback(response);
    },
    *deleteDeviceRelation({ payload, callback }, { call }) {
      const response = yield call(deleteDeviceRelation, payload);
      if (callback) callback(response);
    },
    *deleteDevice({ payload, callback }, { call }) {
      const response = yield call(deleteDevice, payload);
      if (callback) callback(response);
    },
    *putDevice({ payload, callback }, { call }) {
      const response = yield call(putDevice, payload);
      if (callback) callback(response);
    },
    // 获取设备当前列表值
    *getDeviceList({ payload, callback }, { call, put }) {
      const response = yield call(queryDeviceList, payload);
      yield put({
        type: 'deviceList',
        payload: response
      });
      if (callback) callback(response);
    },

    // 增加设备注册信息List
    *add({ payload, callback }, { call }) {
      const response = yield call(addDevice, payload);
      if (callback) callback(response.ticket);
    },

    // 确认设备注册信息
    *confirm({ payload, callback }, { call }) {
      const response = yield call(confirmDevice, payload);
      if (callback) callback(response);
    },
    // 采集站管理列表
    *manageList({ payload, callback }, { call, put }) {
      const response = yield call(manageDeviceList, payload);
      // const newList = [];
      // for (let item of list) {
      //   if (item.ticket) {
      //     const name = yield call(Idticket, item.ticket);
      //     if (name.list && name.list.length > 0) {
      //       const Details = yield call(DeviceDetails, name.list[0].name);
      //       const Driver = yield call(Drivers, name.list[0].name);
      //       if (Details.result) {
      //         if (Details.result.value || Details.result.timestamp) {
      //           Details.online = Details.result.value ? Details.result.value : '';
      //           Details.timestamp = Details.result.timestamp ? Details.result.timestamp : '';
      //         }
      //       }
      //       Driver.result1 = Driver.result;
      //       item = Object.assign({}, item, Driver);
      //       item = Object.assign({}, item, Details);
      //     }
      //   }
      //   newList.push(item);
      // }
      const { list } = response;
      const newList = [];
      for (const item of list) {
        if (item.status === 4) {
          const name = yield call(Idticket, item.deviceUuid);
          if (name.list && name.list.length > 0) {
            const Details = yield call(DeviceDetails, name.list[0].name);
            item.status = Details.result.value ? 6 : 4;
          }
        }
        newList.push(item);
      }

      yield put({
        type: 'managelist',
        payload: { ...response, list: newList }
        // payload: response
      });
      if (callback) callback(response);
    },
    *Device({ payload, callback }, { call }) {
      const response = yield call(Devicel, payload);
      if (callback) callback(response);
    },
    *DeviceDetailByName({ payload, callback }, { call, put }) {
      const response = yield call(DevicelDetailByName, payload);

      if (response) {
        yield put({
          type: 'deviceInfoSet',
          response
        });
      }

      if (callback) callback(response);
    }
  },
  reducers: {
    driversData(state, action) {
      return {
        ...state,
        drivers: action.payload
      };
    },
    deviceList(state, action) {
      return {
        ...state,
        data: action.payload
      };
    },
    deviceInfoSet(state, action) {
      return {
        ...state,
        deviceInfo: action.response
      };
    },
    managelist(state, action) {
      return {
        ...state,
        List: action.payload
      };
    },
    infoslist(state, action) {
      return {
        ...state,
        infos: action.payload
      };
    }
  }
};
