import { routerRedux } from 'dva/router';
import { fakeAccountLogin, fakeAccountSmsLogin, queryDefaultPage, loginout, checkThirdPart } from '../services/api';
import { setAuthority, loginActions, isAdmin, erpLogout } from '../utils/authority';

export default {
  namespace: 'login',
  state: {
    visible: false,
    status: undefined
  },

  effects: {
    *login({ payload, callback }, { call, put }) {
      // 登出erp
      const { source } = yield call(checkThirdPart);
      if (source === 'erp') {
        erpLogout();
      }

      const response = yield call(fakeAccountLogin, payload);
      if (callback) callback(response);
      if (response && !response.phone && (response.status === 'ok' || (response.status === 'firstLogin' && response.code !== '401.3'))) {
        yield put({
          type: 'loginJump',
          payload,
          response
        });

        // 设置pims的cookie
        yield put({
          type: 'setCookie'
        });
      }
    },
    *smsLogin({ payload, callback }, { call, put }) {
      // 登出erp
      const { source } = yield call(checkThirdPart);
      if (source === 'erp') {
        erpLogout();
      }

      const response = yield call(fakeAccountSmsLogin, payload);
      if (callback) callback(response);
      if (response && (response.status === 'ok' || (response.status === 'firstLogin' && response.code !== '401.3'))) {
        yield put({
          type: 'loginJump',
          payload,
          response
        });

        // 设置pims的cookie
        yield put({
          type: 'setCookie'
        });
      }
    },
    *setCookie(_, { call }) {
      const { source } = yield call(checkThirdPart);
      if (source === 'pims') {
        document.cookie = 'supcon.pims.data.username=admin;path=/';
      }
    },
    *loginJump({ payload, response }, { call, put }) {
      loginActions(payload, response);
      yield put({
        type: 'changeLoginStatus',
        payload: {
          status: true,
          currentAuthority: isAdmin(response)
        }
      });


      const defaultPage = yield call(queryDefaultPage);
      if (defaultPage && defaultPage.url) {
        localStorage.setItem('defaultPage', `/${defaultPage.url}`);
        yield put(routerRedux.push(`/${defaultPage.url}`));
      } else {
        localStorage.setItem('defaultPage', '/hellow');
        yield put(routerRedux.push('/hellow'));
      }
    },
    *logout(_, { call, put }) {
      yield call(loginout);
      try {
        yield put(routerRedux.push('/user/login'));
      } finally {
        yield put({
          type: 'changeLoginStatus',
          payload: {
            status: false,
            currentAuthority: 'guest'
          }
        });
      }
    },
    *modelLogin({ payload, callback }, { call, put }) {
      // 登出erp
      const { source } = yield call(checkThirdPart);
      if (source === 'erp') {
        erpLogout();
      }

      const response = yield call(fakeAccountLogin, payload);
      if (response && !response.phoone && (response.status === 'ok' || response.status === 'firstLogin')) {
        loginActions(payload, response);

        yield put({
          type: 'changeLoginStatus',
          payload: {
            status: true,
            visible: false,
            currentAuthority: isAdmin(response)
          }
        });

        // 设置pims的cookie
        yield put({
          type: 'setCookie'
        });
      }
      if (callback) callback(response);
    },
    *smsModelLogin({ payload, callback }, { call, put }) {
      // 登出erp
      const { source } = yield call(checkThirdPart);
      if (source === 'erp') {
        erpLogout();
      }

      const response = yield call(fakeAccountSmsLogin, payload);
      if (response && (response.status === 'ok' || response.status === 'firstLogin')) {
        loginActions(payload, response);

        yield put({
          type: 'changeLoginStatus',
          payload: {
            status: true,
            visible: false,
            currentAuthority: isAdmin(response)
          }
        });

        // 设置pims的cookie
        yield put({
          type: 'setCookie'
        });
      }
      if (callback) callback(response);
    },
    *modelLogout(_, { put }) {
      yield put({
        type: 'loginMomal',
        payload: {
          status: false,
          visible: true,
          currentAuthority: 'guest'
        }
      });
    }
  },
  reducers: {
    toggleCodeModal(state, { payload }) {
      return {
        ...state,
        showCodeModal: payload.showCodeModal
      };
    },
    changeLoginStatus(state, { payload }) {
      setAuthority(payload.currentAuthority);

      return {
        ...state,
        status: payload.status,
        visible: payload.visible || false,
        type: payload.type
      };
    },
    loginMomal(state, { payload }) {
      setAuthority(payload.currentAuthority);
      return {
        ...state,
        ...payload
      };
    }
  }
};
