import {
  //  标签管理
  getModelLabelList,
  addModelLabel,
  getDetailModelLabel,
  putModelLabel,
  delModelLabel,
  delModelLabelList,
  // 标签类型管理
  getModelLabelTypeList,
  addModelLabelType,
  putModelLabelType,
  delModelLabelType,
  delModelLabelTypeList
} from '../services/api';


export default {
  namespace: 'modelLabelManager',
  state: {
    modelLabels: { list: [] },
    modelLabelType: {
      list: []
    },
    detailModelLabel: {}
  },
  effects: {
    * getModelLabelList({ payload, callback }, { call, put }) {
      const response = yield call(getModelLabelList, payload);
      yield put({
        type: 'setModelLabels',
        payload: response
      });
      if (callback) callback(response);
    },
    *initModelLabelsList({ payload }, { put }) {
      yield put({
        type: 'setModelLabels',
        payload
      });
    },
    * addModelLabel({ payload, callback }, { call }) {
      const response = yield call(addModelLabel, payload);
      if (callback) callback(response);
    },
    * getDetailModelLabel({ payload, callback }, { call, put }) {
      const response = yield call(getDetailModelLabel, payload);
      yield put({
        type: 'setDetailModelLabel',
        payload: response
      });
      if (callback) callback(response);
    },
    * putModelLabel({ payload, callback }, { call }) {
      const response = yield call(putModelLabel, payload);
      if (callback) callback(response);
    },
    * delModelLabel({ payload, callback }, { call }) {
      const response = yield call(delModelLabel, payload);
      if (callback) callback(response);
    },
    * delModelLabelList({ payload, callback }, { call }) {
      const response = yield call(delModelLabelList, payload);
      if (callback) callback(response);
    },
    * getModelLabelTypeList({ payload, callback }, { call, put }) {
      const response = yield call(getModelLabelTypeList, payload);
      yield put({
        type: 'setModelLabelTypes',
        payload: response
      });
      if (callback) callback(response);
    },
    * addModelLabelType({ payload, callback }, { call }) {
      const response = yield call(addModelLabelType, payload);
      if (callback) callback(response);
    },
    * putModelLabelType({ payload, callback }, { call }) {
      const response = yield call(putModelLabelType, payload);
      if (callback) callback(response);
    },
    * delModelLabelType({ payload, callback }, { call }) {
      const response = yield call(delModelLabelType, payload);
      if (callback) callback(response);
    },
    * delModelLabelTypeList({ payload, callback }, { call }) {
      const response = yield call(delModelLabelTypeList, payload);
      if (callback) callback(response);
    }
  },

  reducers: {
    setModelLabels(state, action) {
      return {
        ...state,
        modelLabels: action.payload
      };
    },
    setDetailModelLabel(state, action) {
      return {
        ...state,
        detailModelLabel: action.payload
      };
    },
    setModelLabelTypes(state, action) {
      return {
        ...state,
        modelLabelType: action.payload
      };
    }
  }
};

