
import { message } from 'antd';
import messages from 'root/routes/IndustryApplicationMgr/messages';
import { fetchAppList, addNewApp, deleteApp, editApp } from '../services/appManagementApi';

export default {
  namespace: 'industryApplication',
  state: {
    list: []
  },

  effects: {
    *fetch(_, { call, put }) {
      const response = yield call(fetchAppList, null);
      yield put({
        type: 'save',
        payload: response
      });
    },
    *add({ payload, intl, callback }, { call, put }) {
      const res = yield call(addNewApp, payload);
      if (res && res.code === '201') {
        message.success(res.message || intl.formatMessage(messages.createSuccess));
        if (callback) callback(res.location);
        yield put({
          type: 'fetch'
        });
      } else {
        message.error((res && res.message) || intl.formatMessage(messages.createFail));
      }
    },
    *update({ payload, intl }, { call, put }) {
      const res = yield call(editApp, payload);
      if (res && res.code === '201') {
        message.success(res.message || intl.formatMessage(messages.editSuccess));
        yield put({
          type: 'fetch'
        });
      } else {
        message.error((res && res.message) || intl.formatMessage(messages.editFail));
      }
    },
    *remove({ payload, intl, callback }, { call, put }) {
      const res = yield call(deleteApp, payload);
      if (res && res.code === '204') {
        message.success(res.message || intl.formatMessage(messages.delSuccess));
        if (callback) callback();
        yield put({
          type: 'fetch'
        });
      } else {
        message.error((res && res.message) || intl.formatMessage(messages.delFail));
      }
    }
  },

  reducers: {
    save(state, action) {
      return {
        ...state,
        list: action.payload.list
      };
    },
    saveUpdated(state, action) {
      const { list } = state;
      const { appId, upgradeStatus } = action.payload;
      return {
        ...state,
        list: list.map((i) => {
          if (i.appId === appId) {
            i.upgradeStatus = upgradeStatus;
          }
          return i;
        })
      };
    }
  }
};
