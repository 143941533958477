import {
  getObejctSource, getRunTimeSource
} from '../services/api'; // 对象属性绑定源的值

import {
  queryAllTemplates, queryAllInstances, queryInstancesByTemplate, queryPropsByInstace, getHistorySingleData, getHistoryData,
  getObjectInstanceList, getObjectInstanceByLabel, getPropsByObjectInstace, getServicesByObjectInstace, getEventsByObjectInstace,
  getObjectTemplateList, getObjectTemplateByLabel, getPropsByObjectTemplate, getServicesByObjectTemplate, getEventsByObjectTemplate,
  getFeatureSetList, getFeatureSetByLabel, getPropsByFeatureSet, getServicesByFeatureSet, getEventsByFeatureSet, queryByNetworkStaffTemplate, queryInstanceData, queryAllNetwork, queryPropsByStaff,
  getModelLabelList, getInstancesByLabel, getProps
} from '../services/objectApi';

const resetData = {
  // 对象实例/模板/功能集合
  modelLabels: {},
  objectInstances: {},
  objectInstancesProps: {},
  objectInstancesServices: {},
  objectInstancesEvents: {}
};
const requestMap = {
  object: [getObjectInstanceList, getObjectInstanceByLabel, getPropsByObjectInstace, getServicesByObjectInstace, getEventsByObjectInstace],
  template: [getObjectTemplateList, getObjectTemplateByLabel, getPropsByObjectTemplate, getServicesByObjectTemplate, getEventsByObjectTemplate],
  featureSet: [getFeatureSetList, getFeatureSetByLabel, getPropsByFeatureSet, getServicesByFeatureSet, getEventsByFeatureSet]
};
function setFlagValue(select) {
  select((state) => {
    if (state.newIndustryWidgets) {
      state.newIndustryWidgets.isNeedLoad = false;
    }
  });
}
export default {
  namespace: 'objectSource',
  state: {
    dataSource: {},
    templates: {},
    instances: {},
    properties: {},
    history: {},
    networks: {},
    runTimeSource: {},
    instanceData: {},
    instanceProps: {}
  },

  effects: {
    *getObejctSource({ payload, callback }, { call, put }) {
      const response = yield call(getObejctSource, payload);
      yield put({ type: 'getRemoteSource', payload: response });
      if (callback) { callback(response); }
    },
    *queryAllTemplates({ pageNum, pageSize, searchKey, requestType }, { call, put }) {
      const response = yield call(queryAllTemplates, { pageNum, pageSize, searchKey });
      yield put({ type: 'getAllTemplates', payload: response, requestType });
    },
    *queryAllInstances({ pageNum, pageSize, searchKey, requestType }, { call, put }) {
      const response = yield call(queryAllInstances, { pageNum, pageSize, searchKey });
      yield put({ type: 'getAllInstances', payload: response, requestType });
    },
    * getRunTimeSource({ payload, callback }, { call, put }) {
      // const response = Math.random().toFixed(2) * 100; try {
      const response = yield call(getRunTimeSource, payload);
      // } catch (e) {   console.log(e); } finally {   response =
      // Math.random().toFixed(2) * 100; }
      yield put({
        type: 'setRunTimeSource',
        payload: {
          objName: payload.objName,
          propName: payload.propName,
          response
        }
      });
      if (callback) { callback(response); }
    },
    *queryInstancesByTemplate({ templateName, pageNum, pageSize, searchKey, requestType }, { call, put }) {
      const response = yield call(queryInstancesByTemplate, { templateName, pageNum, pageSize, searchKey });
      yield put({ type: 'getInstancesByTemplate', payload: response, requestType });
    },
    *queryPropsByInstace({ instanceName, requestType, pageNum, pageSize, searchKey }, { call, put }) {
      const response = yield call(queryPropsByInstace, { instanceName, pageNum, pageSize, searchKey });
      yield put({ type: 'getAllProps', payload: response, requestType });
    },
    *getHistoryData({ object, filters, key }, { call, put }) {
      if (Array.isArray(object)) {
        const parameters = object.map((item) => {
          return {
            dataSource: `${item.selectedInstance.instanceName}.${item.selectedProp.propertyDesc}`,
            type: 'Property',
            filters: item.filters ? item.filters : filters
          };
        });
        const response = yield call(getHistoryData, parameters);
        yield put({ type: 'getAllHistoryData', payload: Object.assign({}, response, { dataSource: object }), key });
      } else {
        const parameters = {
          dataSource: object,
          type: 'Property',
          filters
        };
        const response = yield call(getHistorySingleData, parameters);
        yield put({ type: 'getAllHistoryData', payload: Object.assign({}, response, { dataSource: object }), key });
      }
    },

    *getHistoryNetwork({ object, filters, key }, { call, put }) {
      const param = {
        dataSource: `${object.selectedInstance.instanceName}`,
        type: 'network',
        filters
      };
      const response = yield call(getHistorySingleData, param);
      // const response = yield call(getHistoryDatatable, param);
      yield put({ type: 'getAllHistoryData', payload: response, key });
    },
    *queryInstanceData({ dataSource, resType, filters }, { call, put }) {
      const param = {
        dataSource, resType, filters
      };
      const response = yield call(queryInstanceData, param);
      yield put({
        type: 'setInstanceData',
        payload: response
      });
    }, // 查询对象实例列表
    *queryInstanceProps({ dataSource, resType, filters }, { call, put }) {
      const param = {
        dataSource, resType, filters
      };
      const response = yield call(queryInstanceData, param);
      yield put({
        type: 'setInstanceProps',
        payload: response
      });
    }, // 查询实例属性({属性名：属性值})

    // *******  数据源信息获取 Network 类型

    *getHistoryDatatable({ object, filters, key }, { call, put }) {
      const param = {
        dataSource: `${object.selectedInstance.name}.${object.selectedProp.propertyName}`,
        type: 'property',
        // primitiveType:"DataTable"
        filters
      };
      // }
      const response = yield call(getHistorySingleData, param);
      // const response = yield call(getHistoryDatatable, param);
      yield put({ type: 'getAllHistoryData', payload: response, key });
    },

    // *******  数据源信息获取 dataTable 类型 后期可以和上面的方法进行整合

    *getSimpleDatatable({ object, filters, key }, { call, put }) {
      const param = {
        dataSource: object,
        type: 'property.getData',
        filters
      };
      // }
      const response = yield call(getHistorySingleData, param);
      yield put({ type: 'getAllHistoryData', payload: response, key });
    },

    // 对象实例

    *getObjectInstanceList({ requestType = 'object', pageNum, pageSize, searchKey }, { call, put }) {
      const response = yield call(requestMap[requestType][0], { pageNum, pageSize, searchKey });
      yield put({ type: 'setObjectInstanceList', payload: response, requestType });
    },
    *getObjectInstanceByLabel({ requestType = 'object', labels, pageNum, pageSize, searchKey }, { call, put }) {
      const response = yield call(requestMap[requestType][1], { labels, pageNum, pageSize, searchKey });
      yield put({ type: 'setObjectInstanceByLabel', payload: response, requestType });
    },
    *getPropsByObjectInstace({ requestType = 'object', name }, { call, put }) {
      const response = yield call(requestMap[requestType][2], { name });
      yield put({ type: 'setPropsByObjectInstace', payload: response, requestType });
    },
    *getServicesByObjectInstace({ requestType = 'object', name }, { call, put }) {
      const response = yield call(requestMap[requestType][3], { name });
      yield put({ type: 'setServicesByObjectInstace', payload: response, requestType });
    },
    *getEventsByObjectInstace({ requestType = 'object', name }, { call, put }) {
      const response = yield call(requestMap[requestType][4], { name });
      yield put({ type: 'setEventsByObjectInstace', payload: response, requestType });
    },

    *queryAllNetwork({ pageNum, pageSize, searchKey }, { call, put }) {
      const response = yield call(queryAllNetwork, { pageNum, pageSize, searchKey });
      yield put({ type: 'setNetworkList', payload: response });
    },
    *queryTemplateByName({ pageNum, pageSize, searchKey, requestType = 'object' }, { call, put }) {
      const response = yield call(queryByNetworkStaffTemplate, { pageNum, pageSize, searchKey, requestType });
      yield put({ type: 'getTemplateByName', payload: response, requestType });
    },
    *getPropsByInstace({ instanceName, requestType, callback }, { call, put }) {
      const response = yield call(queryPropsByStaff, { instanceName });
      yield put({ type: 'getPropsByStaff', payload: response, requestType });
      if (callback) callback(response);
    },
    // 获取标签，实例，详情
    *getModelLabelList({ pageNum, pageSize, searchKey, requestType = 'instance' }, { call, put, select }) {
      const response = yield call(getModelLabelList, { pageNum, pageSize, searchKey });
      yield setFlagValue(select);
      yield put({ type: 'setModelLabelList', payload: response, requestType });
    },
    *getInstancesByLabel({ labels, pageNum, pageSize, searchKey, requestType = 'instance' }, { call, put, select }) {
      const response = yield call(getInstancesByLabel, { requestType, labels, pageNum, pageSize, searchKey });
      yield setFlagValue(select);
      yield put({ type: 'setInstances', payload: response, requestType });
    },
    *getProps({ name, requestType = 'instance', queryAll, pageNum, pageSize, searchKey }, { call, put, select }) {
      const response = yield call(getProps, { requestType, name, queryAll, pageNum, pageSize, searchKey });
      yield setFlagValue(select);
      yield put({ type: 'setProps', payload: response, requestType });
    }
  },

  reducers: {
    getRemoteSource(state, action) {
      const { dataSource } = state;
      // const properties = JSON.parse(action.payload.properties);
      const { properties } = action.payload;
      dataSource[action.payload.name] = properties;
      return {
        ...state,
        dataSource
      };
    },
    getAllTemplates(state, action) {
      return {
        ...state,
        templates: action.payload,
        requestType: action.requestType,
        instances: {},
        properties: {}
      };
    },
    getAllInstances(state, action) {
      return {
        ...state,
        instances: action.payload,
        requestType: action.requestType,
        properties: {}
      };
    },
    setRunTimeSource(state, action) {
      const { runTimeSource = {} } = state;
      const { objName, propName, response } = action.payload;
      // const { properties } = action.payload; 根据绑定的源不同，返回的结构不同；
      runTimeSource[objName + propName] = response
        ? response.result
        : {};
      return {
        ...state,
        runTimeSource
      };
    },
    getInstancesByTemplate(state, action) {
      return {
        ...state,
        instances: action.payload,
        requestType: action.requestType,
        properties: {}
      };
    },
    getAllProps(state, action) {
      return {
        ...state,
        properties: action.payload,
        requestType: action.requestType
      };
    },
    getPropsByStaff(state, action) {
      return {
        ...state,
        properties: action.payload,
        requestType: action.requestType
      };
    },
    getAllHistoryData(state, action) {
      const history = Object.assign({}, state.history, { [action.key]: action.payload });
      return {
        ...state,
        history
      };
    },
    // 清空history
    initHistory(state) {
      return {
        ...state,
        history: []
      };
    },
    setInstanceData(state, action) {
      return {
        ...state,
        instanceData: action.payload
      };
    },
    // 清空视频源属性
    initInstanceProps(state) {
      return {
        ...state,
        instanceProps: {}
      };
    },
    setInstanceProps(state, action) {
      return {
        ...state,
        instanceProps: action.payload
      };
    },
    // 对象实例/属性/服务
    setObjectInstanceList(state, action) {
      return {
        ...resetData,
        objectInstances: action.payload,
        requestType: action.requestType,
        objectInstancesProps: {}
      };
    },
    setObjectInstanceByLabel(state, action) {
      return {
        ...resetData,
        objectInstances: action.payload,
        requestType: action.requestType,
        objectInstancesProps: {}
      };
    },
    setPropsByObjectInstace(state, action) {
      return {
        ...resetData,
        objectInstancesProps: action.payload,
        requestType: action.requestType
      };
    },
    setServicesByObjectInstace(state, action) {
      return {
        ...resetData,
        objectInstancesServices: action.payload,
        requestType: action.requestType
      };
    },
    setEventsByObjectInstace(state, action) {
      return {
        ...resetData,
        objectInstancesEvents: action.payload,
        requestType: action.requestType
      };
    },

    setNetworkList(state, action) {
      return {
        ...state,
        networks: action.payload
      };
    },
    getTemplateByName(state, action) {
      return {
        ...state,
        templates: action.payload,
        instances: {},
        properties: {}
      };
    },
    // 设置标签，实例，详情
    setModelLabelList(state, action) {
      return {
        ...resetData,
        templates: action.payload,
        instances: {},
        properties: {},
        requestType: action.requestType
      };
    },
    setInstances(state, action) {
      return {
        ...state,
        instances: action.payload,
        properties: {},
        requestType: action.requestType
      };
    },
    setProps(state, action) {
      return {
        ...state,
        properties: action.payload,
        requestType: action.requestType
      };
    }
  }
};

