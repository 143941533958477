import {
  query,
  queryDetail,
  tempSaveTask,
  doneTask,
  resetTask,
  recallTask,
  setDelegation,
  queryDelegation,
  cancelDelegation,
  queryLog
} from '../services/task';

export default {
  namespace: 'task',

  state: {
    list: [],
    pagination: {
      page: 0,
      size: 20,
      total: 0
    }
  },

  effects: {
    *getList({ payload, callback }, { call, put }) {
      const response = yield call(query, payload);
      yield put({
        type: 'list',
        payload: response
      });
      if (callback) callback(response);
    },
    *detail({ payload, callback }, { call, put }) {
      const response = yield call(queryDetail, payload);
      yield put({
        type: 'taskDetail',
        payload: response
      });
      // 我的请求接口进来的详情页只读
      yield put({
        type: 'industryApplicationLayout/updateFormData',
        payload: {
          formData: response.temp ? response.temp.data : response.data,
          defaultFormData: response.data,
          readOnly: response.readonly,
          isDone: payload.isDone
        }
      });
      if (callback) callback(response);
    },
    *tempSave({ payload, callback }, { call, put }) {
      const response = yield call(tempSaveTask, payload);
      yield put({
        type: 'taskDone',
        payload: response
      });
      if (callback) callback(response);
    },
    *reset({ payload, callback }, { call, put }) {
      const response = yield call(resetTask, payload);
      yield put({
        type: 'industryApplicationLayout/updateFormData',
        payload: {
          formData: payload.defaultFormData
        }
      });
      if (callback) callback(response);
    },
    *recall({ payload, callback }, { call, put }) {
      const response = yield call(recallTask, payload);
      yield put({
        type: 'taskRecall',
        payload: response
      });
      if (callback) callback(response);
    },
    *done({ payload, callback }, { call, put }) {
      const response = yield call(doneTask, payload);
      yield put({
        type: 'taskDone',
        payload: response
      });
      if (callback) callback(response);
    },
    *log({ payload, callback }, { call, put }) {
      const response = yield call(queryLog, payload);
      yield put({
        type: 'taskLog',
        payload: response
      });
      if (callback) callback(response);
    },
    *setDelegate({ payload, callback }, { call, put }) {
      const response = yield call(setDelegation, payload);
      yield put({
        type: 'updateState',
        payload: response
      });
      if (callback) callback(response);
    },
    *getDelegateList({ payload, callback }, { call, put }) {
      const response = yield call(queryDelegation, payload);
      yield put({
        type: 'list',
        payload: response
      });
      if (callback) callback(response);
    },
    *cancelDelegate({ payload, callback }, { call, put }) {
      const response = yield call(cancelDelegation, payload);
      yield put({
        type: 'updateState',
        payload: response
      });
      if (callback) callback(response);
    }
  },

  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload
      };
    },
    list(state, { payload }) {
      return {
        ...state,
        pagination: payload.pagination || { page: 0, size: 0, total: 0 },
        list: payload.list || []
      };
    },
    taskDetail(state, { payload }) {
      return {
        ...state,
        ...payload
      };
    },
    taskDone(state, { payload }) {
      return {
        ...state,
        ...payload
      };
    },
    taskReset(state) {
      return {
        ...state,
        tempFormData: ''
      };
    },
    taskRecall(state, { payload }) {
      return {
        ...state,
        ...payload
      };
    },
    taskLog(state, { payload }) {
      return {
        ...state,
        ...payload
      };
    },
    clearData(state) {
      return {
        ...state,
        list: []
      };
    }
  }
};
