import React from 'react';
import _ from 'lodash';
import pako from 'pako';
import { Tooltip } from 'antd';

/**
 * 设置接口get请求参数
 * @param {object} params 参数 {a:1,b:2,c:[1,2,3]}
 * @return {string} ie. ?a=1&b=2&c=1&c=2&c=3
 */
export function getUrlParams(params) {
  let param = '';
  for (const i in params) {
    if (params[i] !== undefined && params[i].length !== 0) {
      if (Array.isArray(params[i])) {
        for (const j in params[i]) {
          if (params[i][j]) {
            param += !param ? `?${i}=${params[i][j]}` : `&${i}=${params[i][j]}`;
          }
        }
      } else {
        param += !param ? `?${i}=${params[i]}` : `&${i}=${params[i]}`;
      }
    }
  }
  return param;
}

function getRelation(str1, str2) {
  if (str1 === str2) {
    console.warn('Two path are equal!'); // eslint-disable-line
  }
  const arr1 = str1.split('/');
  const arr2 = str2.split('/');
  if (arr2.every((item, index) => item === arr1[index])) {
    return 1;
  } else if (arr1.every((item, index) => item === arr2[index])) {
    return 2;
  }
  return 3;
}

export function getRoutes(path, routerData) {
  let routes = Object.keys(routerData).filter(routePath =>
    routePath.indexOf(path) === 0 && routePath !== path);
  routes = routes.map(item => item.replace(path, ''));
  let renderArr = [];
  renderArr.push(routes[0]);
  for (let i = 1; i < routes.length; i += 1) {
    let isAdd = false;
    isAdd = renderArr.every(item => getRelation(item, routes[i]) === 3);
    renderArr = renderArr.filter(item => getRelation(item, routes[i]) !== 1);
    if (isAdd) {
      renderArr.push(routes[i]);
    }
  }
  const renderRoutes = renderArr.map((item) => {
    const exact = !routes.some(route => route !== item && getRelation(route, item) === 1);
    return {
      ...routerData[`${path}${item}`],
      key: `${path}${item}`,
      path: `${path}${item}`,
      exact
    };
  });
  return renderRoutes;
}

/* eslint no-useless-escape:0 */
const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/g;

export function isUrl(path) {
  return reg.test(path);
}

/**
 * Trim字符串
 * @param str
 * @returns {*}
 */
export function trim(str) {
  return str.replace(/(^\s*)|(\s*$)/g, '');
}

/**
 * 获取url地址--NEW ,如果该方法获取不到会重新用上面的方法获取
 * ? 问号后的一段字符
 * @param location
 * @param param
 * @returns {string}
 */
export function queryLocationParam(location, param) {
  const paramString = location.href.substring(location.href.indexOf('?') + 1, location.href.length);
  const paramArray = paramString.split('&');
  let result = '';
  paramArray.forEach((value) => {
    const pairKeyValueArray = value.split('=');
    if (pairKeyValueArray[0] === param) {
      // let [arrayResult] = [];
      // [arrayResult] = [pairKeyValueArray];
      // result = [arrayResult];
      [, result] = pairKeyValueArray;
    }
  });
  return result;
}

/**
 * 把数组转化成对象
 * @param array
 * @returns {{list: *}}
 */
export function convertArrayToObject(array) {
  return {
    list: array
  };
}

/**
 * 增加虚拟DOM的值
 * @param returnObj
 * @param filterObj
 * @returns {*}
 */
export function addVdomKey(returnObj) {
  // const array = filterObj.array;
  // const key = filterObj.key;
  // return returnObj.forEach((item, index) => {
  //   item["key"] = item.key;
  // });
  return returnObj;
}

/**
 * 接口返回数据 判断是否失败
 * @param {*} res
 */
export function isRequestFail(res) {
  const codeList = ['200', 200, '201', 201, '204', 204, '202', 202];
  if (!res) return true;
  if (res && codeList.indexOf(res.code) < 0) return true;
  return false;
}

/**
* @description 绑定dom元素监听事件
* @param {dom} target 绑定监听事件的目标dom
* @param {string} type  监听函数类型，如click,mouseover
* @param {function} func 监听事件
*/
export function addEventHandler(target, type, func, params = false) {
  if (target.addEventListener) {
    // 监听IE9，谷歌和火狐
    target.addEventListener(type, func, params);
  } else if (target.attachEvent) {
    target.attachEvent(`on${type}`, func);
  } else {
    target[`on${type}`] = func;
  }
}

/**
* @description 移除Dom元素的事件
* @param 参数同 addEventHandler
*/
export function removeEventHandler(target, type, func) {
  if (target.removeEventListener) {
    // 监听IE9，谷歌和火狐
    target.removeEventListener(type, func, false);
  } else if (target.detachEvent) {
    target.detachEvent(`on${type}`, func);
  } else {
    delete target[`on${type}`];
  }
}

/**
* @description 比较两个数组的差异值
* @param {array} oldArr
* @param {array} newArr
* @returns {object} addArr 新增数组  rmArr 删除数组
*/
export function diffArr(oldArr, newArr) {
  const rmArr = [];
  for (let i = 0; i < oldArr.length; i += 1) {
    if (newArr.indexOf(oldArr[i]) === -1) {
      rmArr.push(oldArr[i]);
    }
  }
  const addArr = [];
  for (let j = 0; j < newArr.length; j += 1) {
    if (oldArr.indexOf(newArr[j]) === -1) {
      addArr.push(newArr[j]);
    }
  }
  return { addArr, rmArr };
}

/**
 * tips 提示
 * @param {*} val 内容
 */
export function getTips(val) {
  return (
    <Tooltip
      overlayStyle={{ width: '250px', wordBreak: 'break-all' }}
      placement="bottomLeft"
      title={val}
      trigger="hover"
    >
      <span
        style={{ cursor: 'pointer' }}
      >
        {val}
      </span>
    </Tooltip>
  );
}

/**
 * 对象模型 的滚动
 */
export function scrollTopSet() {
  const scrollDiv = document.getElementsByClassName('ant-layout-content')[0];
  if (!scrollDiv) {
    return;
  }
  const { scrollHeight, offsetHeight, scrollTop } = scrollDiv;
  if (this.scrollTop !== undefined
    && scrollDiv.scrollTop > this.scrollTop
    && offsetHeight + scrollTop + 10 < scrollHeight) {
    scrollDiv.scrollTop = this.scrollTop;
  } else {
    this.scrollTop = scrollDiv.scrollTop;
  }
}

/**
 * @description 格式化菜单表
 * @param {json} menus
 * @returns {obj} 扁平化后的菜单列表
 */
export function getFlatMenuData(menus) {
  let keys = {};
  menus.forEach((item) => {
    if (item.children) {
      keys[item.path] = {
        ...item
      };
      keys = {
        ...keys,
        ...getFlatMenuData(item.children)
      };
    } else {
      keys[item.path] = {
        ...item
      };
    }
  });
  return keys;
}


/**
 * @description 格式化菜单路径
 * @param {*} data
 * @param {string} [parentPath='']
 * @param {*} parentAuthority
 * @returns 格式后的菜单路径
 */
export function formatter(data, parentPath = '', parentAuthority) {
  return data.map((item) => {
    let { path } = item;
    if (!isUrl(path)) {
      path = parentPath + item.path;
    }
    const result = {
      ...item,
      path,
      authority: item.authority || parentAuthority
    };
    if (item.children) {
      result.children = formatter(item.children, `${parentPath}${item.path}/`, item.authority);
    }
    return result;
  });
}

/**
 * 树结构铺平成数组
 * @param {*} tree 当前树的数据源
 * @param {*} key 父节点key值
 */
export function treeTransArray(tree, key) {
  function iterater(con, item) {
    con.push(Object.assign({}, item, { children: [] }));
    if (item[key] && item[key].length > 0) {
      item[key].reduce(iterater, con);
    }
    return con;
  }
  return tree.reduce(iterater, []);
}
/**
 * 数组生成树结构
 * @param {*} list 数组
 * @param {*} id 根节点id
 * @param {*} key 父节点id
 * 例子
 * const tree = {"parent":"root__006","leader":["1"],"sequenceNumber":0.0,"showName":"根01","code":"根01",
 * "children":[{"parent":"root__006","leader":["1"],"sequenceNumber":1.0,"showName":"子002","code":"子002","children":[],"root":"root__006","name":"node__028","modelLabels":[],"description":""}],
 * "root":"root__006","name":"root__006","modelLabels":["Administration"],"description":"001"}
 * treeTransArray(tree,'children')
 * arrayTransTree(arr,'name','parent')
 */
// export function arrayTransTree(list, id, key) {
//   function iterater(parent) {
//     const branchArr = list.filter((child) => {
//       return parent[id] === child[key];
//     });
//     if (branchArr.length > 0) {
//       parent.nodes = branchArr;
//     }
//     return parent[key] === parent.name;
//   }
//   const tree = list.filter(iterater);
//   return tree;
// }
export function arrayTransTree(list, id, pid) {
  // 创建一个对象命名为map
  const map = {};
  // 通过遍历把list中的元素放到map对象中
  list.forEach((item) => {
    if (!map[item[id]]) {
      // 核心步骤1：map中的'item.id'属性指向list数组中的对象元素
      map[item[id]] = item;
    }
  });
  // 再次遍历为了对map属性所指的对象进行处理
  list.forEach((item) => {
    // 过滤父级id不是null的元素
    if (item[pid] !== item[id]) {
      // map[item.pid]为该元素的父级元素
      if (map[item[pid]].children) {
        map[item[pid]].children.push(item);
      } else {
        map[item[pid]].children = [item];
      }
    }
  });
  // 过滤后仅剩下根节点
  return list.filter((item) => {
    if (item[pid] === item[id]) {
      return item;
    }
    return null;
  });
}

/**
 * 根据节点，找出所有的父级节点
 * @param {} treeArr 数节点
 * @param {*} currentNode 当前节点
 */
export function getTreeRelation(treeArr, currentNode) {
  const parentNodes = [];
  if (Array.isArray(treeArr)) {
    const iterater = (pid) => {
      treeArr.forEach((node) => {
        // 找到父节点，退出循环
        if (node.name === pid) {
          parentNodes.push(node.name);
          // 找到根节点，退出循环
          if (node.name === node.root) {
            return false;
          }
          iterater(node.parent);
          return false;
        }
      });
    };
    iterater(currentNode.parent);
  }
  return parentNodes;
}

/**
 * Fire an event handler to the specified node. Event handlers can detect that the event was fired programatically
 * by testing for a 'synthetic=true' property on the event object
 * @description 手动触发dom 节点的事件  兼容ie
 * @param {HTMLNode} node The node to fire the event handler on.
 * @param {String} eventName The name of the event without the "on" (e.g., "focus")
 */
export function fireEvent(node, eventName) {
  // Make sure we use the ownerDocument from the provided node to avoid cross-window problems
  let doc;
  if (node.ownerDocument) {
    doc = node.ownerDocument;
  } else if (node.nodeType === 9) {
    // the node may be the document itself, nodeType 9 = DOCUMENT_NODE
    doc = node;
  } else {
    throw new Error(`Invalid node passed to fireEvent: ${node.id}`);
  }

  if (node.dispatchEvent) {
    // Gecko-style approach (now the standard) takes more work
    let eventClass = '';

    // Different events have different event classes.
    // If this switch statement can't map an eventName to an eventClass,
    // the event firing is going to fail.
    switch (eventName) {
      case 'click': // Dispatching of 'click' appears to not work correctly in Safari. Use 'mousedown' or 'mouseup' instead.
      case 'mousedown':
      case 'mouseup':
        eventClass = 'MouseEvents';
        break;

      case 'focus':
      case 'change':
      case 'blur':
      case 'dragstart':
      case 'select':
        eventClass = 'HTMLEvents';
        break;

      default:
        break;
    }
    const event = doc.createEvent(eventClass);

    const bubbles = eventName !== 'change';
    event.initEvent(eventName, bubbles, true); // All events created as bubbling and cancelable.

    event.synthetic = true; // allow detection of synthetic events
    node.dispatchEvent(event, true);
  } else if (node.fireEvent) {
    // IE-old school style
    const event = doc.createEventObject();
    event.synthetic = true; // allow detection of synthetic events
    node.fireEvent(`on${eventName}`, event);
  }
}

/**
* @description 文本转图片
* @param {type} params
* text 需要转成图片的文字 width/height canvas宽高大小 默认100
* fontszie 文字大小 默认20px fontcolor 默认黑色
* @returns {type} params
*/
export function textToImg(params) {
  const {
    text = '',
    width = 100,
    height = 100,
    fontsize = 20,
    fontcolor = 'rgb(0,0,0)' } = params;
  let canvas = document.createElement('canvas');
  canvas.wdith = width;
  canvas.height = height;
  const context = canvas.getContext('2d');
  context.clearRect(0, 0, canvas.width, canvas.height);
  context.font = `${fontsize}px Arial`;
  context.textBaseline = 'middle';
  context.fillStyle = fontcolor;
  context.textBaseline = 'middle';
  context.fillText(text, 0, (height - fontsize) / 2);
  const dataUrl = canvas.toDataURL('image/png');
  canvas = null;
  return dataUrl;
}

/**
 * 根据节点，返回节点类型
 * @param {} data 节点
 */
export function getNodeType(data) {
  if (!data) return undefined;
  let elemType;
  if (data.getStyleMap && data.getStyleMap()) {
    if (data.getStyleMap().shape) {
      elemType = data.getStyleMap().shape;
      if (elemType === 'pentagon' || elemType === 'diamond' ||
        elemType === 'rightTriangle' || elemType === 'parallelogram' || elemType === 'trapezoid') {
        elemType = 'hexagon'; // 不规则图形统一处理
      }
    } else if (data.getStyleMap().type) {
      elemType = data.getStyleMap().type;
    }
  }
  if (!elemType && data.a('shapeName')) {
    elemType = data.a('shapeName');
  }
  if (!elemType && data.getPoints && data.getPoints().size() > 1) {
    elemType = 'unRuleShape';
  }
  if (!elemType && data.getClassName() === 'ht.Edge') {
    elemType = 'edge';
  }
  if (!elemType && data._childMap && Object.keys(data._childMap).length) {
    if (data.s('editor.folder')) {
      elemType = 'folder';
    } else if (data.a('symbolId')) {
      elemType = 'customSymbol';
    } else {
      elemType = 'group';
    }
    // elemType = data.a('symbolId') ? 'customSymbol' : 'group';
  }
  if (!elemType && data.getImage && data.getImage() === 'htDiv') {
    elemType = data.a('widgetName') || 'htDiv';
  }
  if (!elemType && data.a && data.a('widgetName') === 'image') {
    elemType = 'image';
  }
  return elemType;
}

/**
 * 返回相应位数质量码打状态
 */
export function getBitValid(s, n) {
  if (s === '-1') return undefined;
  if (!s) return true;
  const s1 = s.slice(-8);
  const s2 = s.slice(0, -8);
  if (n >= 32) {
    return (parseInt(s2, 16) & (1 << (n - 32))) === 0;
  }
  return (parseInt(s1, 16) & (1 << n)) === 0;
}

/**
* @description 根据对象属性过滤最大值 最小值类别
* @param {array} list 对象属性信息
* @returns {object} gt 大于  ge 大于等于   lt 小于  le 小于等于  greather 大于  less 小于
*/
export function filterGreAndLess(list) {
  let gt = [];
  let ge = [];
  let lt = [];
  let le = [];
  _.map(list, (item) => {
    const limitValue = Number(item.limitValue);
    if (!isNaN(limitValue)) {
      const { alertType, showName } = item;
      const unitObj = { limitValue, enabled: item.enabled, alertType, showName, name: item.name };
      switch (alertType) {
        case 'gt':
          if (!_.includes(gt, limitValue)) {
            unitObj.key = 'gt';
            gt.push(unitObj);
          }
          break;
        case 'ge':
          if (!_.includes(ge, limitValue)) {
            unitObj.key = 'ge';
            ge.push(unitObj);
          }
          break;
        case 'lt':
          if (!_.includes(lt, limitValue)) {
            unitObj.key = 'lt';
            lt.push(unitObj);
          }
          break;
        case 'le':
          if (!_.includes(le, { limitValue })) {
            unitObj.key = 'le';
            le.push(unitObj);
          }
          break;
        default:
          break;
      }
    }
  });
  let greather = _.concat(gt, ge);
  let less = _.concat(lt, le);
  gt = _.sortBy(gt, o => o.limitValue);
  ge = _.sortBy(ge, o => o.limitValue);
  lt = _.sortBy(lt, o => o.limitValue);
  le = _.sortBy(le, o => o.limitValue);
  greather = _.sortBy(greather, o => o.limitValue);
  less = _.sortBy(less, o => o.limitValue);
  return { gt, ge, lt, le, greather, less };
}

/**
* @description 对象实例报警值过滤方法
* @param {json}  sourceArr 根据对象属性名查询出来的 属性值
* @returns {type} params
*/
export function filterSourceAlarm(sourceArr) {
  const sourceInfo = {};
  _.forEach(sourceArr, (value) => {
    const key = value.name;
    const greAndLess = filterGreAndLess(value.list);
    sourceInfo[key] = _.merge({}, greAndLess);
    sourceInfo[key].maxValue = value.maxValue;
    sourceInfo[key].minValue = value.minValue;
  });
  return sourceInfo;
}

/**
* 根据datalink配置信息，返回节点文字内容
* @param {} dataLinkModel datalink配置信息
*/
export function getDataLinkText(dataLinkModel) {
  const { name, showName, showUnit, decimal, object: { selectedProp: { unit } } } = dataLinkModel;
  let text = '';
  if (showName && name) text += name;
  text += '????';
  if (decimal) text += '.';
  for (let i = 0; i < decimal; i += 1) {
    text += '?';
  }
  if (showUnit && unit) text += unit;
  return text;
}

export function chartWrapper(H, widgetIndex) {
  H.wrap(H.Pointer.prototype, 'normalize', function (...args) {
    const e = args[1];
    let chartPosition = args[2];

    const ePos = e.touches ?
      (e.touches.length ? e.touches.item(0) : e.changedTouches[0]) : e;
    if (!chartPosition) {
      chartPosition = H.offset(this.chart.container);
      this.chartPosition = chartPosition;
    }

    const { transform } = document.getElementById(`${widgetIndex}`).style;
    let transformArr = transform.split('(');
    transformArr = transformArr[1].split(')');
    const sizeArr = transformArr[0].split(',');
    return H.extend(e, {
      chartX: Math.round((ePos.pageX / sizeArr[0]) - chartPosition.left),
      chartY: Math.round((ePos.pageY / sizeArr[0]) - chartPosition.top)
    });
  });
}

/**
* @description 判断是否属于知之浏览器
* @returns {boolean} true false
*/
export function isZhizhiBrowser() {
  return !!navigator.userAgent.match(/Zhizhi/i);
}

export function isMobile() {
  return !!(navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i));
}

/**
 * 判断string类型的正则
 * @param {*} input 正则
 */
const testRegExp = /^\/((?:\\\/|[^/])+)\/([migyu]{0,5})?$/;
export function isRegExpString(input) {
  const parts = testRegExp.exec(input);
  if (parts) {
    try {
      const regex = new RegExp(parts[1], parts[2]);
      return regex instanceof RegExp;
    } catch (err) {
      console.error(`${input} => ${err.message}`);
    }
  }
  return false;
}

/**
* @description 将页面路径存储到app-creator-interface 的sessionStorage
* 为了刷新也买那保留当前页面
* @param {string} url 页面切换的路径
*/
export function storedUrlToAppCreator(url) {
  if (window !== window.top) {
    window.parent.postMessage({ 'app-creator-interface': url || '' }, '*');
  }
}

/**
* @description 数字类型校验  判断是不是数字  不是的话返回一个默认值
* @param {any} value 需要转换的值
* @param {any} defaultValue 不满足条件默认转换的值  默认为 0
*/
export function valueToDigital(value, defaultValue = 0) {
  value -= 0;
  return isNaN(value) ? defaultValue : value;
}

const lunarInfo = [
  0x4bd8, 0x4ae0, 0xa570, 0x54d5, 0xd260, 0xd950, 0x5554, 0x56af, 0x9ad0, 0x55d2,
  0x4ae0, 0xa5b6, 0xa4d0, 0xd250, 0xd255, 0xb54f, 0xd6a0, 0xada2, 0x95b0, 0x4977,
  0x497f, 0xa4b0, 0xb4b5, 0x6a50, 0x6d40, 0xab54, 0x2b6f, 0x9570, 0x52f2, 0x4970,
  0x6566, 0xd4a0, 0xea50, 0x6a95, 0x5adf, 0x2b60, 0x86e3, 0x92ef, 0xc8d7, 0xc95f,
  0xd4a0, 0xd8a6, 0xb55f, 0x56a0, 0xa5b4, 0x25df, 0x92d0, 0xd2b2, 0xa950, 0xb557,
  0x6ca0, 0xb550, 0x5355, 0x4daf, 0xa5b0, 0x4573, 0x52bf, 0xa9a8, 0xe950, 0x6aa0,
  0xaea6, 0xab50, 0x4b60, 0xaae4, 0xa570, 0x5260, 0xf263, 0xd950, 0x5b57, 0x56a0,
  0x96d0, 0x4dd5, 0x4ad0, 0xa4d0, 0xd4d4, 0xd250, 0xd558, 0xb540, 0xb6a0, 0x95a6,
  0x95bf, 0x49b0, 0xa974, 0xa4b0, 0xb27a, 0x6a50, 0x6d40, 0xaf46, 0xab60, 0x9570,
  0x4af5, 0x4970, 0x64b0, 0x74a3, 0xea50, 0x6b58, 0x5ac0, 0xab60, 0x96d5, 0x92e0,
  0xc960, 0xd954, 0xd4a0, 0xda50, 0x7552, 0x56a0, 0xabb7, 0x25d0, 0x92d0, 0xcab5,
  0xa950, 0xb4a0, 0xbaa4, 0xad50, 0x55d9, 0x4ba0, 0xa5b0, 0x5176, 0x52bf, 0xa930,
  0x7954, 0x6aa0, 0xad50, 0x5b52, 0x4b60, 0xa6e6, 0xa4e0, 0xd260, 0xea65, 0xd530,
  0x5aa0, 0x76a3, 0x96d0, 0x4afb, 0x4ad0, 0xa4d0, 0xd0b6, 0xd25f, 0xd520, 0xdd45,
  0xb5a0, 0x56d0, 0x55b2, 0x49b0, 0xa577, 0xa4b0, 0xaa50, 0xb255, 0x6d2f, 0xada0,
  0x4b63, 0x937f, 0x49f8, 0x4970, 0x64b0, 0x68a6, 0xea5f, 0x6b20, 0xa6c4, 0xaaef,
  0x92e0, 0xd2e3, 0xc960, 0xd557, 0xd4a0, 0xda50, 0x5d55, 0x56a0, 0xa6d0, 0x55d4,
  0x52d0, 0xa9b8, 0xa950, 0xb4a0, 0xb6a6, 0xad50, 0x55a0, 0xaba4, 0xa5b0, 0x52b0,
  0xb273, 0x6930, 0x7337, 0x6aa0, 0xad50, 0x4b55, 0x4b6f, 0xa570, 0x54e4, 0xd260,
  0xe968, 0xd520, 0xdaa0, 0x6aa6, 0x56df, 0x4ae0, 0xa9d4, 0xa4d0, 0xd150, 0xf252,
  0xd520
];
const monString = '正二三四五六七八九十冬腊';
const numString = '一二三四五六七八九十';
function leapMonth(y) {
  const lm = lunarInfo[y - 1900] & 0xf;
  return (lm === 0xf ? 0 : lm);
}

function monthDays(y, m) {
  return ((lunarInfo[y - 1900] & (0x10000 >> m)) ? 30 : 29);
}

function leapDays(y) {
  if (leapMonth(y)) {
    return ((lunarInfo[y - 1899] & 0xf) === 0xf ? 30 : 29);
  } else {
    return (0);
  }
}

function lYearDays(y) {
  let i;
  let sum = 348;

  for (i = 0x8000; i > 0x8; i >>= 1) {
    sum += (lunarInfo[y - 1900] & i) ? 1 : 0;
  }

  return (sum + leapDays(y));
}
/**
* @description 日期转农历
* @param {date} date 需要转换的日期
* @return {object} 输出农历 {date:'2019-03-21',cn:'2019年-三月-廿一'}
*/
export function getLunarDay(date) {
  const objDate = new Date(date);
  let i;
  let leap = 0;
  let temp = 0;
  let year = '';
  let month = '';
  let day = '';
  let isLeap = false;
  let offset = (Date.UTC(objDate.getFullYear(), objDate.getMonth(), objDate.getDate()) - Date.UTC(1900, 0, 31)) / 86400000;

  for (i = 1900; i < 2100 && offset > 0; i += 1) {
    temp = lYearDays(i);
    offset -= temp;
  }

  if (offset < 0) {
    offset += temp;
    i -= 1;
  }

  year = i;
  leap = leapMonth(i); // 闰哪个月
  isLeap = false;

  for (i = 1; i < 13 && offset > 0; i += 1) {
    // 闰月
    if (leap > 0 && i === (leap + 1) && isLeap === false) {
      i -= 1;
      isLeap = true;
      temp = leapDays(year);
    } else {
      temp = monthDays(year, i);
    }

    // 解除闰月
    if (isLeap === true && i === (leap + 1)) isLeap = false;

    offset -= temp;
  }

  if (offset === 0 && leap > 0 && i === leap + 1) {
    if (isLeap) {
      isLeap = false;
    } else {
      isLeap = true;
      i -= 1;
    }
  }

  if (offset < 0) {
    offset += temp;
    i -= 1;
  }

  month = i;
  day = offset + 1;

  const cnYear = `${year}年`;
  let cnMonth = '';
  let cnDay = '';

  if (isLeap) {
    cnMonth += '(闰)';
    cnMonth += monString.charAt(-month - 1);
  } else {
    cnMonth += monString.charAt(month - 1);
  }
  cnMonth += '月';

  cnDay = day < 11 ? '初' : ((day < 20) ? '十' : ((day < 30) ? '廿' : '三十'));
  if (day % 10 !== 0 || day === 10) {
    cnDay += numString.charAt((day - 1) % 10);
  }

  month = month < 10 ? `0${month}` : month;
  day = day < 10 ? `0${day}` : day;

  return {
    date: `${year}-${month}-${day}`,
    cn: `${cnYear}-${cnMonth}-${cnDay}`
  };
}

/**
* @description 判断节点是否在自定义图元中
* @returns {boolean} true false
*/
export function isInCustomGraph(node) {
  return getIsInCustomGraph(node);
}
function getIsInCustomGraph(node) {
  let parent;
  if (node && node.getParent) {
    parent = node.getParent();
  }
  if (!parent) {
    return false;
  } else if (parent.a('symbolId')) {
    return true;
  } else {
    return getIsInCustomGraph(parent);
  }
}

/**
* @description 获取嵌有 iframe 页面的最顶层 supOS 的页面
* @param {object} win window || window.parent
* @returns {object} win 最顶层 supOS 的窗口
*/
export function getWindowWrapper(win = window) {
  try {
    if (win.parent.sign !== 'supOS' || win === win.parent) {
      return win;
    } else {
      return getWindowWrapper(win.parent);
    }
  } catch (error) {
    return win;
  }
}

/**
* @description 获取全屏dom元素
* @returns {boolean}
*/
export function getFullScreenElement() {
  const win = getWindowWrapper();
  const { fullscreenElement, msFullscreenElement, mozFullScreenElement, webkitFullscreenElement } = win.document;
  return fullscreenElement || msFullscreenElement || mozFullScreenElement || webkitFullscreenElement || false;
}

export function isSameDomain() {
  try {
    const topDomain = _.get(window, 'top.document.domain', 'localhost');
    const currentDomain = _.get(window, 'document.domain', 'localhost');

    return topDomain === currentDomain && self.frameElement && self.frameElement.tagName === 'IFRAME';
  } catch (e) {
    return false;
  }
}

/**
 * @description json压缩
 * @param {*} json
 */
export function zip(json) {
  try {
    const str = JSON.stringify(json);
    const deflate = new pako.Deflate({ level: 6, to: 'string' });
    deflate.push(str, true);
    return deflate.result;
  } catch (e) {
    console.log(e);
    return json;
  }
}

/**
 * @description json解压
 * @param {*} binaryString
 */
export function unzip(binaryString) {
  try {
    const str = pako.inflate(binaryString, { to: 'string' });
    const json = JSON.parse(str);
    return json;
  } catch (e) {
    return binaryString;
  }
}
