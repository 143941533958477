import { defineMessages } from 'react-intl';

export default defineMessages({
  layoutSet: '布局配置',
  cancel: '取消',
  ok: '确定',
  type: '类型',
  WaterfallLayout: '流式布局',
  freeLayout: '自由布局',
  WidthHeightSet: '宽高设置',
  WidthSet: '宽度设置',
  HeightSet: '高度设置',
  Opacity: '透明度',
  importSuccess: '{name} 导入成功',
  importFail: '{name} 导入失败',
  createSuccess: '创建成功',
  createFail: '创建失败',
  editSuccess: '编辑成功',
  editFail: '编辑失败',
  delSuccess: '删除成功',
  delFail: '删除失败',
  tip: '提示',
  maxWidthRule: '设置宽度超过最大可配置宽度，自适应为最大宽度',
  layoutReset: '如果重新布置区块的布局类型，设置后区块内部配置将清空，是否继续？',
  import: '导入',
  export: '导出',
  update: '升级',
  preview: '预览',
  setting: '设置',
  fullScreen: '全屏',
  edit: '编辑',
  stop: '停止',
  start: '运行',
  del: '删除',
  back: '后退',
  copypage: '复制页面',
  newfolder: '新建文件夹',
  newpage: '新建页面',
  moveout: '移出',
  movein: '移入',
  moveup: '上移',
  movedown: '下移',
  more: '更多',
  updating: '正在升级…',
  backgroundImage: '背景图片',
  uploadImage: '上传图片',
  backgroundImageType: '背景类型',
  chooseBackgroundTips: '请选择背景排版方式',
  normWidth: '横向自适应',
  normHeight: '纵向自适应',
  bespread: '拉伸',
  mobileLayoutWidthRatio: '宽度比例',
  mobileLayoutHeightRatio: '高度比例',
  mobielLayoutSetting: '布局配置',
  opacity: '透明度',
  fileTypeError: '文件类型不匹配'
});
