import {
  getMenuSetting,
  changeMenuOrder,
  modifyMenuName
} from '../services/user';

export default {
  namespace: 'appMenuSetting',
  state: {
    treeData: [],
    changeData: null,
    nameData: null
  },
  effects: {
    *queryMenuSetting({ callback }, { call, put }) {
      const menuData = yield call(getMenuSetting);
      yield put({
        type: 'getMenu',
        payload: {
          menuData
        }
      });
      if (callback) callback(menuData.resources);
    },
    *changeOrder({ payload, callback }, { call, put }) {
      const change = yield call(changeMenuOrder, payload);
      yield put({
        type: 'changeMenu',
        payload: {
          change
        }
      });
      if (callback) callback(change);
    },
    *changeName({ payload, callback }, { call, put }) {
      const name = yield call(modifyMenuName, payload);
      yield put({
        type: 'modifyName',
        payload: {
          name
        }
      });
      if (callback) callback(name);
    }
  },
  reducers: {
    getMenu(state, { payload }) {
      return {
        ...state,
        treeData: payload.menuData.resources
      };
    },
    changeMenu(state, { payload }) {
      return {
        ...state,
        changeData: payload.change
      };
    },
    modifyName(state, { payload }) {
      return {
        ...state,
        nameData: payload.name
      };
    }
  }
};
