import request from '../utils/request';
import config from '../config';

export async function query() {
  return request(`${config.domain3}/api/users`);
}

// 获取当前用户
export async function queryCurrent(params) {
  return request(`${config.domain3}/api/auth/users/userSessionInfo?ticket=${params.ticket}`);
}


// 用户密码的修改
export async function editUserPwd(params, isHiddenNote = true) {
  // return request('/api/auth/user/editUser', {
  return request(`${config.domain10}/api/auth/users/updatePwd/${params.username}`, {
    method: 'PUT',
    body: {
      ...params
    }
  }, isHiddenNote);
}

// 获取用户信息
export async function queryUserByUserName(params) {
  return request(`${config.domain3}/api/auth/users/${params.username}`);
}

// App菜单配置
export async function getMenuSetting() {
  return request('/api/auth/menuTreeModify', {
    method: 'GET'
  });
}

export async function changeMenuOrder(params) {
  return request('/api/auth/menuTreeModify', {
    method: 'PUT',
    body: params
  });
}

export async function modifyMenuName(params) {
  return request('/api/auth/menuTreeModify/name', {
    method: 'PUT',
    body: params
  });
}
