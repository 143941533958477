import { get } from 'lodash';
import moment from 'moment';
import qs from 'qs';

export function getInstanceById(id) {
  if (typeof id !== 'string') {
    throw new Error(`Expect string but got ${typeof id}`);
  }
  const element = document.getElementById(id);
  const instance = get(
    element,
    '_reactRootContainer._internalRoot.current.child.child.child.child.stateNode'
  );

  return instance || null;
}

export function getAsyncInstance(id) {
  if (typeof id !== 'string') {
    throw new Error(`Expect string but got ${typeof id}`);
  }
  const element = document.getElementById(id);
  const instance = get(
    element,
    '_reactRootContainer._internalRoot.current.child.alternate.alternate.child.child.child.stateNode.targetComp'
  );

  return instance || null;
}

export function debugMode(info) {
  const index = window.location.hash.lastIndexOf('?') + 1;
  const urlString = window.location.hash.substr(index);
  const { isDebug } = qs.parse(urlString);
  if (isDebug) {
    console.log(moment().format('MMMM Do YYYY, h:mm:ss:SSS a'), info);
  }
}
