import zhCN from '../language/zh-cn';
import enUS from '../language/en-us';

const lans = [
  {
    locale: 'zh-cn',
    messages: zhCN
  },
  {
    locale: 'en-us',
    messages: enUS
  }
];
const messagesObj = lans.find(x => x.locale === localStorage.getItem('language'));
localStorage.setItem('language_message', messagesObj ? JSON.stringify(messagesObj.messages) : JSON.stringify(enUS));
if (!localStorage.getItem('language')) {
  localStorage.setItem('language', 'en-us');
  localStorage.setItem('languageName', 'English');
}
export default {
  namespace: 'language',

  state: {
    list: [],
    id: localStorage.getItem('language') || 'en-us',
    locale: localStorage.getItem('language') || 'en-us',
    defaultLocale: localStorage.getItem('language') || 'en-us',
    messages: messagesObj ? messagesObj.messages : enUS,
    lans
  },

  effects: {
    * update({ payload, callback }, { put }) {
      const messages = lans.find(x => x.locale === payload.id);
      yield put({
        type: 'callback',
        payload: {
          id: payload.id,
          locale: payload.id,
          messages: messages.messages || enUS
        }
      });
      localStorage.setItem('language_message', JSON.stringify(messages.messages) || enUS);
      if (callback) callback();
    }
  },

  reducers: {
    save(state, action) {
      return {
        ...state,
        ...action.payload
      };
    },
    callback(state, { payload }) {
      return {
        ...state,
        locale: payload.locale,
        id: payload.id,
        messages: payload.messages
      };
    }
  }
};
