import { message } from 'antd';
import commonMessage from 'root/common/messages';
import { updateFolderTree } from '../services/api';

import {
  fetchFoldersTree, addNewSymbol, delSymbol,
  editSymbol, copySymbol,
  addNewCategory, delCategory, editCategory
} from '../services/customGraphApi';

export default {
  namespace: 'customGraph',
  state: {
    tree: [],
    pageId: '' // 当前选择的页面
  },

  effects: {
    *getTree({ payload, callback }, { call, put }) {
      // console.log(payload);
      const response = yield call(fetchFoldersTree, payload);
      if (response.code.toString() === '200') {
        if (payload.isFrist) {
          let defaultId = '';
          const loopForPage = (treeData) => {
            for (let i = 0; i < treeData.length; i += 1) {
              if (treeData[i].templateName === 'Symbol') {
                defaultId = treeData[i].id;
                break;
              } else if (treeData[i].children && treeData[i].children.length) {
                loopForPage(treeData[i].children);
              }
            }
          };
          loopForPage(response.children || []);
          yield put({
            type: 'fristQueryFolderTree',
            payload: {
              tree: response.children || [],
              pageId: defaultId
            }
          });
        } else {
          yield put({
            type: 'queryFolderTree',
            payload: {
              tree: response.children || []
            }
          });
        }
        if (callback) callback();
      }
    },

    *addNew({ payload, intl }, { call, put }) {
      const res = yield call(addNewSymbol, payload);
      if (res && res.code.toString() === '201') {
        yield put({
          type: 'getTree',
          payload: { id: payload.appId }
        });
        message.success(intl.formatMessage(commonMessage.addSuccess));
      }
    },

    *updateSymbol({ payload, intl }, { call, put }) {
      const res = yield call(editSymbol, payload);
      if (res && res.code.toString() === '201') {
        if (payload.appId) {
          yield put({
            type: 'getTree',
            payload: { id: payload.appId }
          });
          message.success(intl.formatMessage(commonMessage.editSuccess));
        } else {
          message.success(intl.formatMessage(commonMessage.saveSuccess));
        }
      }
    },

    *updateTree({ payload }, { call, put }) {
      const { appId, ...params } = payload;
      yield call(updateFolderTree, params);
      // console.log(res);
      yield put({
        type: 'getTree',
        payload: { id: appId }
      });
    },

    *deleteSymbol({ payload, intl, callback }, { call, put }) {
      const res = yield call(delSymbol, payload.id);
      if (res.code.toString() === '204') {
        message.success(intl.formatMessage(commonMessage.delSuccess));
        yield put({
          type: 'updatePageId',
          payload: ''
        });
        if (callback) callback();
      }
      yield put({
        type: 'getTree',
        payload: { id: payload.appId }
      });
    },

    *copySymbol({ payload, intl }, { call, put }) {
      const res = yield call(copySymbol, payload.id);
      yield put({
        type: 'getTree',
        payload: { id: payload.appId }
      });
      if (res && res.code.toString() === '201') {
        message.success(intl.formatMessage(commonMessage.copySuccess));
        yield put({
          type: 'getTree',
          payload: { id: payload.appId }
        });
      }
    },

    *addCategory({ payload, intl }, { call, put }) {
      const res = yield call(addNewCategory, payload);
      if (res && res.code.toString() === '201') {
        message.success(intl.formatMessage(commonMessage.addSuccess));
        yield put({
          type: 'getTree',
          payload: { id: payload.appId }
        });
      }
    },

    *editCategory({ payload, intl }, { call, put }) {
      const { appId, ...params } = payload;
      const response = yield call(editCategory, params);
      if (response.code.toString() === '201') {
        message.success(intl.formatMessage(commonMessage.editSuccess));
        yield put({
          type: 'getTree',
          payload: { id: appId }
        });
      }
    },

    *deleteCategory({ payload, intl, callback }, { call, put }) {
      const res = yield call(delCategory, payload.id);
      if (res.code.toString() === '204') {
        message.success(intl.formatMessage(commonMessage.delSuccess));
        yield put({
          type: 'getTree',
          payload: { id: payload.appId }
        });
        if (callback) callback();
      }
    },

    *initData(_, { put }) {
      yield put({
        type: 'initState'
      });
    }
  },

  reducers: {
    initState() {
      return {
        tree: [],
        pageId: ''
      };
    },
    updatePageId(state, action) {
      // console.log(action);
      return {
        ...state,
        pageId: action.payload
      };
    },
    fristQueryFolderTree(state, action) {
      return {
        ...state,
        tree: action.payload.tree,
        pageId: action.payload.pageId
      };
    },
    queryFolderTree(state, action) {
      return {
        ...state,
        tree: action.payload.tree
      };
    }
  }
};
