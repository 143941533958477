
// import { message } from 'antd';
// import { fetchAppList, addNewApp, deleteApp, editApp } from '../services/applicationFlieMgrApi';

import { fetchFirst } from '../services/applicationFlieMgrApi';

export default {
  namespace: 'applicationFlieMgr',
  state: {
    folderTree: [],
    fileList: []
  },

  effects: {
    *fetchFirst({ payload }, { call, put }) {
      const res = yield call(fetchFirst, payload);
      if (res) {
        yield put({
          type: 'save',
          payload: res
        });
      }
    }
  },

  reducers: {
    save(state, action) {
      return {
        ...state,
        fileList: [],
        folderTree: action.payload.list
      };
    }
  }
};
