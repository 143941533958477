import 'root/utils/supQuery';
import '@babel/polyfill';
import 'url-polyfill';
import dva from 'dva';

import createHistory from 'history/createHashHistory';
// user BrowserHistory
// import createHistory from 'history/createBrowserHistory';
import createLoading from 'dva-loading';
import $ from 'jquery';
import 'moment/locale/zh-cn';
import FastClick from 'fastclick';
import { getConfigInfo } from 'root/services/api';
import SuposEvents from './utils/events';
import scriptUtil from './utils/scriptUtil';
import './components/Antd/fullscreenListener';
import './rollbar';

import './index.less';


export default (router) => {
  window.scriptUtil = scriptUtil;
  window.SUPOS = {};
  window.SuposEvents = SuposEvents;
  window.ISAPPCREATOR = location.href.includes('/app/');
  // window.SUPOS注册全局方法
  window.SUPOS.registerSuposFn = async (type) => {
    if (window.SUPOS[type]) {
      return window.SUPOS[type];
    }
    const response = await getConfigInfo('global_setting_frentendFn');
    try {
      const configValue = response && response.configValue ? JSON.parse(response.configValue) : {};
      const configRes = new Function(configValue.deviceClickHandle)() || {};
      if (configRes[type]) {
        window.SUPOS[type] = configRes[type];
        return window.SUPOS[type];
      }
    } catch (e) {
      console.log(e);
    }
    // console.log(configRes);
    return null;
  };
  window.setFontSize = () => {
    const $el = $('body');
    const $label = $("[data-name='labelCtrl']");
    const elw = $el.width();
    const settings = {
      maximum: 1920,
      minimum: 900,
      fontRatio: 80,
      maxFont: 24,
      minFont: 12
    };
    const width = elw > settings.maximum
      ? settings.maximum
      : elw < settings.minimum
        ? settings.minimum
        : elw;
    const fontBase = width / settings.fontRatio;
    const fontSize = fontBase > settings.maxFont ? settings.maxFont : fontBase < settings.minFont ? settings.minFont : fontBase;
    // console.log('elw width fontBase fontSize', elw, width, fontBase, fontSize);
    if (fontSize) $label.css('font-size', `${fontSize}px`);
  };
  window.onresize = window.setFontSize;
  // 1. Initialize
  const app = dva({
    history: createHistory()
  });
  // 2. Plugins
  app.use(createLoading());
  // 3. Register global model
  app.model(require('./models/global').default);
  app.model(require('./models/language').default);
  // 4. Router
  app.router(router);
  // 5. Start
  app.start('#root');

  FastClick.attach(document.body);

  window.APPSTORE = app._store;  // eslint-disable-line
};
