import React from 'react';
import { Route } from 'react-router-dom';
import Exception from '../Exception/index';

class AuthorizedTab extends React.Component {
  hasBasicMenuPermission = () => {
    // check Permissions logic
    const basicFlatMenu = JSON.parse(localStorage.getItem('basicFlatMenu')) || [];
    for (const menu of basicFlatMenu) {
      if (this.props.location.pathname.includes(menu)) {
        return true;
      }
    }

    return false;
  }

  hasDesignMenuPermission = () => {
    const designFlatMenu = JSON.parse(localStorage.getItem('designFlatMenu')) || [];
    for (const menu of designFlatMenu) {
      if (this.props.location.pathname.includes(menu)) {
        return true;
      }
    }

    return false;
  }

  isDesignSeries = () => {
    const designFlatMenu = JSON.parse(localStorage.getItem('designFlatMenu')) || [];
    if (designFlatMenu.includes('design/industry-application-mgr/application-mgr') && this.hasPaintPermission()) {
      return true;
    } else {
      return false;
    }
  }

  hasPaintPermission = () => {
    return (
      this.props.location.pathname.includes('/design/industry-application-mgr/application-editor')
    || this.props.location.pathname.includes('/design/workflow/')
    || this.props.location.pathname.includes('/design/industryDrawing/')

    || this.props.location.pathname.includes('/design/about')
    );
  }

  hasPreviewPermission = () => {
    return (
      this.props.location.pathname.includes('/layout/')
    );
  }

  isDefaultPage = () => {
    const defaultPage = localStorage.getItem('defaultPage');
    return this.props.location.pathname.includes(defaultPage);
  }

  isWelcomPage = () => {
    return this.props.location.pathname.includes('design/hellow');
  }

  hasFullScreenPermission = () => {
    return (
      this.props.location.pathname.includes('/runtime-fullscreen/')
    );
  }

  permissionCheck = () => {
    return (
      this.hasBasicMenuPermission()
      || this.hasDesignMenuPermission()
      || this.isDesignSeries()
      || this.isDefaultPage()
      || this.isWelcomPage()
      || this.hasPreviewPermission()
      || this.hasFullScreenPermission()
    );
  }

  render() {
    const { component: Component, render, authority, collapsed, name, appId,
      redirectPath, ...rest } = this.props;
    if (this.permissionCheck()) {
      return (
        <Route
          {...rest}
          render={props => (Component ? <Component {...props} name={name} appId={appId} collapsed={collapsed} /> : render(props))}
        />
      );
    } else {
      return (
        <Exception type="403" style={{ minHeight: 500, height: '80%' }} />
      );
    }
  }
}

export default AuthorizedTab;
