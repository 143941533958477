import { getSubscription, getFeatureSubscription, getTemplateSubscription, postSubscription } from '../services/api';

const requestMap = {
  0: getSubscription,
  1: getTemplateSubscription,
  2: getFeatureSubscription
};
export default {
  namespace: 'instanceSubscription',
  state: {
    subscription: {}
  },
  effects: {
    * getSubscription({ payload }, { call, put }) {
      const response = yield call(requestMap[payload.ownerType], payload);
      yield put({
        type: 'setSubscription',
        payload: response
      });
    },
    * postSubscription({ payload }, { call, put }) {
      const response = yield call(postSubscription, payload);
      yield put({
        type: 'setSubscription',
        payload: response
      });
    }
  },

  reducers: {
    setSubscription(state, action) {
      return {
        ...state,
        subscription: action.payload
      };
    }
  }
};
