import React from 'react';
import { routerRedux, Switch } from 'dva/router';
import { Spin } from 'antd';
import dynamic from 'dva/dynamic';
import ErrorBoundary from 'root/components/ErrorBoundary/ErrorBoundary';
import LanguageProvider from 'root/layouts/LanguageProvider';
import { getRouterData } from './common/router';
import Authorized from './utils/Authorized';
import styles from './index.less';

const { ConnectedRouter } = routerRedux;
const { AuthorizedRoute } = Authorized;
dynamic.setDefaultLoadingComponent(() => {
  return <Spin size="large" className={styles.globalSpin} />;
});

function RouterConfig(params) {
  const { history, app } = params;
  const routerData = getRouterData(app);
  const UserLayout = routerData['/user'].component;
  const BasicLayout = routerData['/'].component;
  const BlankLayout = routerData['/layout'].component;
  const RuntimeLayout = routerData['/runtime-fullscreen'].component;
  const DesignLayout = routerData['/design'].component;
  /*
  *  在前置的权限路由中匹配  默认匹配到basicLayout basicLayout路由 建议放在最后
  */
  return (
    <LanguageProvider>
      <ConnectedRouter history={history}>
        <ErrorBoundary>
          <Switch>
            <AuthorizedRoute
              path="/layout"
              render={props => <BlankLayout {...props} />}
            />
            <AuthorizedRoute
              path="/user"
              render={props => <UserLayout {...props} />}
              redirectPath="/user/login"
            />
            <AuthorizedRoute
              path="/runtime-fullscreen"
              render={props => <RuntimeLayout {...props} />}
              authority={['user', 'admin']}
              redirectPath="/user/login"
            />
            <AuthorizedRoute
              path="/design"
              render={props => <DesignLayout {...props} />}
              authority={['user', 'admin']}
              redirectPath="/user/login"
            />
            <AuthorizedRoute
              path="/"
              render={props => <BasicLayout {...props} />}
              authority={['user', 'admin']}
              redirectPath="/user/login"
            />
          </Switch>
        </ErrorBoundary>
      </ConnectedRouter>
    </LanguageProvider>
  );
}

export default RouterConfig;
