import {
  queryParsingState,
  queryDefaultPage,
  projectBackup,
  setDefaultPage,
  queryServiceList,
  saveAlarmColor,
  getAlarmColor
} from '../services/api';

export default {
  namespace: 'systemInfoMgr',
  state: {
    // homePageList: [],
    defaultPageInfo: {},
    serviceList: { list: [] },
    statusData: {},
    colorData: {},
    saveColorReturn: {}
  },
  effects: {
    // * queryHomePage({ payload, callback }, { call, put }) {  Query parsing progress
    //   const response = yield call(queryHomePage, payload);
    //   yield put({
    //     type: 'setHomePageList',
    //     payload: response
    //   });
    //   if (callback) callback(response);
    // },
    * queryDefaultPage({ payload, callback }, { call, put }) {
      const response = yield call(queryDefaultPage, payload);
      yield put({
        type: 'setDefaultPageInfo',
        payload: response
      });
      if (callback) callback(response);
    },
    * projectBackup({ payload, callback }, { call }) {
      const response = yield call(projectBackup, payload);
      if (callback) callback(response);
    },
    * setDefaultPage({ payload, callback }, { call }) {
      const response = yield call(setDefaultPage, payload);
      if (callback) callback(response);
    },
    * queryParsingState({ payload, callback }, { call, put }) {
      const response = yield call(queryParsingState, payload);
      yield put({
        type: 'setStatus',
        payload: response
      });
      if (callback) callback(response);
    },
    * queryServiceList({ payload, callback }, { call, put }) {
      const response = yield call(queryServiceList, payload);
      yield put({
        type: 'setServiceList',
        payload: response
      });
      if (callback) callback(response);
    },
    * saveAlarmColor({ payload, callback }, { call, put }) {
      const response = yield call(saveAlarmColor, payload);
      yield put({
        type: 'saveColor',
        payload: response
      });
      if (callback) callback(response);
    },
    * getAlarmColor({ payload, callback }, { call, put }) {
      const response = yield call(getAlarmColor, payload);
      yield put({
        type: 'gitAlarmColorList',
        payload: response
      });
      if (callback) callback(response);
    }
  },

  reducers: {
    // setHomePageList(state, action) {
    //   return {
    //     ...state,
    //     homePageList: action.payload
    //   };
    // },
    setDefaultPageInfo(state, action) {
      return {
        ...state,
        defaultPageInfo: action.payload || {}
      };
    },
    setServiceList(state, action) {
      return {
        ...state,
        serviceList: action.payload || {}
      };
    },
    setStatus(state, action) {
      return {
        ...state,
        statusData: action.payload || {}
      };
    },
    gitAlarmColorList(state, action) {
      return {
        ...state,
        colorData: action.payload || {}
      };
    },
    saveColor(state, action) {
      return {
        ...state,
        saveColorReturn: action.payload || {}
      };
    }
  }
};
