import error403 from '../../assets/http-error/403.svg';
import error404 from '../../assets/http-error/404.svg';
import error500 from '../../assets/http-error/500.svg';

const config = {
  403: {
    img: error403,
    title: '403',
    desc: getServiceMessage(403)
  },
  404: {
    img: error404,
    title: '404',
    desc: getServiceMessage(404)
  },
  500: {
    img: error500,
    title: '500',
    desc: getServiceMessage(500)
  }
};

function getServiceMessage(code) {
  return JSON.parse(localStorage.getItem('language_message')) ? JSON.parse(localStorage.getItem('language_message'))[`common.page${code}`] : '';
}

export default config;
