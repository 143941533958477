import request from '../utils/request';
import config from '../config';

// 获取首个用户任务
export async function getWorkprocessUrl(params) {
  return request(`${config.domain11}/api/flow/diagram/${params.id}/entry`);
}

// 发布流程
export async function publishWorkprocess(params) {
  return request(`${config.domain11}/api/flow/diagram/${params.id}`, {
    method: 'POST',
    body: {
      isNew: params.isNew,
      id: params.id
    }
  });
}

// 启动流程
export async function startWorkprocess(params) {
  return request(`${config.domain11}/api/flow/diagram/${params.id}/start`, {
    method: 'POST',
    body: {
      user: params.user,
      data: params.data,
      audit: params.audit,
      branch: params.branch
    }
  });
}

// 获取列表
export async function getWorkprocessList(params) {
  return request(`${config.domain11}/api/flow/diagram?appId=${params.appId}&page=${params.page}&size=${params.size}${params.type ? `&type=${params.type}` : ''}`);
}

// 删除流程
export async function deleteWorkprocess(params) {
  return request(`${config.domain11}/api/flow/diagram/${params.id}`, {
    method: 'DELETE'
  });
}

// 新增流程
export async function addWorkprocessList(params) {
  return request(`${config.domain11}/api/flow/diagram`, {
    method: 'POST',
    body: params
  });
}

// 修改流程
export async function updateWorkprocessList(params) {
  return request(`${config.domain11}/api/flow/diagram/${params.id}`, {
    method: 'PUT',
    body: params
  });
}

// 获取流程详情
export async function detailWorkprocess(params) {
  return request(`${config.domain11}/api/flow/diagram/${params.id}`);
}

// 查询我的请求
export async function queryMyRequest(params) {
  return request(`${config.domain11}/api/flow/process?done=${params.done}&page=${params.page}&size=${params.size}`);
}

// 获取我的请求中的流程图信息
export async function queryBpmnData(params) {
  return request(`${config.domain11}/api/flow/diagram/generateDiagram/${params.id}?done=${params.done}`);
}

// 获取版本列表
export async function queryVersion(params) {
  return request(`${config.domain11}/api/flow/version/diagram?appId=${params.appId}&diagramName=${params.diagramName}&currentVersion=${params.currentVersion}&page=${params.page}&size=${params.size}`);
}

// 升版流程
export async function upgradeVersion(params) {
  return request(`${config.domain11}/api/flow/version/diagram/upgrade`, {
    method: 'POST',
    body: params
  });
}

// 还原流程
export async function resetVersion(params) {
  return request(`${config.domain11}/api/flow/version/diagram/reset`, {
    method: 'POST',
    body: params
  });
}

// 设置当前版本流程
export async function setVersion(params) {
  return request(`${config.domain11}/api/flow/version/diagram/setDefault`, {
    method: 'POST',
    body: params
  });
}

// 删除版本流程
export async function deleteVersion(params) {
  return request(`${config.domain11}/api/flow/version/diagram/delete`, {
    method: 'POST',
    body: params
  });
}

// 查询版本流程详情
export async function detailVersion(params) {
  return request(`${config.domain11}/api/flow/version/diagram/get?id=${params.id}`);
}

// 获取模板列表
export async function queryTemplate(params) {
  return request(`${config.domain11}/api/flow/template/query?name=${params.name}&page=${params.page}&size=${params.size}&appId=${params.appId}`);
}

// 新增模板
export async function createTemplate(params) {
  return request(`${config.domain11}/api/flow/template/add`, {
    method: 'POST',
    body: {
      ...params,
      type: 1
    }
  });
}

// 修改模板
export async function updateTemplate(params) {
  return request(`${config.domain11}/api/flow/template/update`, {
    method: 'POST',
    body: params
  });
}

// 删除模板
export async function deleteTemplate(params) {
  return request(`${config.domain11}/api/flow/template/delete`, {
    method: 'POST',
    body: params
  });
}
