import {
  queryMyRequest,
  publishWorkprocess,
  updateWorkprocessList,
  addWorkprocessList,
  getWorkprocessList,
  detailWorkprocess,
  getWorkprocessUrl,
  startWorkprocess,
  deleteWorkprocess,
  queryBpmnData,
  queryVersion,
  setVersion,
  upgradeVersion,
  deleteVersion,
  detailVersion,
  queryTemplate
} from '../services/process';

export default {
  namespace: 'process',

  state: {
    list: [],
    templateList: [],
    bpmnData: {
      xml: '',
      idsAttr: []
    },
    pagination: {
      page: 0,
      size: 20,
      total: 0
    },
    exportList: [],
    version: {
      list: [],
      pagination: {
        page: 0,
        size: 15,
        total: 0
      }
    }
  },

  effects: {
    *getList({ payload, callback }, { call, put }) {
      const response = yield call(getWorkprocessList, payload);
      yield put({
        type: 'list',
        payload: response
      });
      if (callback) callback(response);
    },
    *add({ payload, callback }, { call, put }) {
      const response = yield call(addWorkprocessList, payload);
      yield put({
        type: 'addProcess',
        payload: response
      });
      if (callback) callback(response);
    },
    *update({ payload, callback }, { call, put }) {
      const response = yield call(updateWorkprocessList, payload);
      yield put({
        type: 'updateProcess',
        payload: response
      });
      if (callback) callback(response);
    },
    *entry({ payload, callback }, { call, put }) {
      const response = yield call(getWorkprocessUrl, payload);
      yield put({
        type: 'updateResponse',
        payload: response
      });
      if (callback) callback(response);
    },
    *publish({ payload, callback }, { call, put }) {
      const response = yield call(publishWorkprocess, payload);
      yield put({
        type: 'publishProcess',
        payload: response
      });
      if (callback) callback(response);
    },
    *start({ payload, callback }, { call, put }) {
      const response = yield call(startWorkprocess, payload);
      yield put({
        type: 'save',
        payload: response
      });
      if (callback) callback(response);
    },
    *detail({ payload, callback }, { call, put }) {
      const response = yield call(detailWorkprocess, payload);
      yield put({
        type: 'detailBpmn',
        payload: response
      });
      if (callback) callback(response);
    },
    *delete({ payload, callback }, { call, put }) {
      const response = yield call(deleteWorkprocess, payload);
      // 刷新右侧版本管理列表
      yield put({
        type: 'getVersion',
        payload: {
          appId: payload.appId,
          diagramName: '',
          page: 0,
          size: 1000
        }
      });
      yield put({
        type: 'del',
        payload: response
      });
      if (callback) callback(response);
    },
    *myRequest({ payload, callback }, { call, put }) {
      const response = yield call(queryMyRequest, payload);
      yield put({
        type: 'request',
        payload: response
      });
      if (callback) callback(response);
    },
    *bpmnData({ payload }, { call, put }) {
      const response = yield call(queryBpmnData, payload);
      yield put({
        type: 'getBpmnDataDetail',
        payload: response
      });
    },
    *upgradeVersion({ payload, callback }, { call, put }) {
      const response = yield call(upgradeVersion, payload);
      yield put({
        type: 'updateResponse',
        payload: response
      });
      if (callback) callback(response);
    },
    *getVersion({ payload }, { call, put }) {
      const { type } = payload;
      const response = yield call(queryVersion, payload);

      if (type === 'export') {
        yield put({
          type: 'exportList',
          payload: response
        });
      } else {
        yield put({
          type: 'getVersionList',
          payload: response
        });
      }
    },
    *setVersion({ payload, callback }, { call, put }) {
      const response = yield call(setVersion, payload);
      yield put({
        type: 'updateResponse',
        payload: response
      });
      if (callback) callback(response);
    },
    *deleteVersion({ payload, callback }, { call, put }) {
      const response = yield call(deleteVersion, payload);
      yield put({
        type: 'updateResponse',
        payload: response
      });
      if (callback) callback(response);
    },
    *detailVersion({ payload, callback }, { call, put }) {
      const response = yield call(detailVersion, payload);
      yield put({
        type: 'getVersionDetail',
        payload: response
      });
      if (callback) callback(response);
    },
    *queryTemplate({ payload }, { call, put }) {
      const response = yield call(queryTemplate, payload);
      yield put({
        type: 'getTemplateList',
        payload: response
      });
    }
  },

  reducers: {
    updateResponse(state, { payload }) {
      return {
        ...state,
        ...payload
      };
    },
    list(state, { payload }) {
      return {
        ...state,
        ...payload
      };
    },
    addProcess(state, { payload }) {
      return {
        ...state,
        ...payload
      };
    },
    updateProcess(state, { payload }) {
      return {
        ...state,
        ...payload
      };
    },
    detailBpmn(state, { payload }) {
      return {
        ...state,
        detailBpmn: payload
      };
    },
    publishProcess(state, { payload }) {
      return {
        ...state,
        ...payload
      };
    },
    save(state, { payload }) {
      return {
        ...state,
        ...payload
      };
    },
    del(state, { payload }) {
      return {
        ...state,
        ...payload
      };
    },
    request(state, { payload }) {
      return {
        ...state,
        pagination: payload.pagination || {},
        list: payload.list || []
      };
    },
    clearData(state) {
      return {
        ...state,
        list: []
      };
    },
    getBpmnDataDetail(state, { payload }) {
      return {
        ...state,
        bpmnData: {
          idsAttr: payload.activeKeys,
          xml: payload.xml
        }
      };
    },
    cleanBpmnData(state) {
      return {
        ...state,
        bpmnData: {
          idsAttr: [],
          xml: ''
        }
      };
    },
    getVersionList(state, { payload }) {
      return {
        ...state,
        version: {
          list: payload.list,
          pagination: payload.pagination
        }
      };
    },
    exportList(state, { payload }) {
      const { list = [] } = payload;
      return {
        ...state,
        exportList: list
      };
    },
    getVersionDetail(state, { payload }) {
      return {
        ...state,
        versionDetail: payload
      };
    },
    getTemplateList(state, { payload }) {
      return {
        ...state,
        templateList: payload.list || []
      };
    }
  }
};
